<script>
import { Tooltip } from 'bootstrap';
import Numeral from "numeral";
import IndividuallyTradingCard from "@/views/licenses/IndividuallyTradingCard.vue";
import { mapGetters } from 'vuex'

export default {
    components: {
        IndividuallyTradingCard
    },
    data() {
        return {
            myResults: null,
            analytics: null,
            loadingPage: true,
            loadingPageMyResults: false,
            isLoadingBuy: false,
            referenceLink: null,
            buyLicenceValue: 0,

            licenseIdSelected: null,

            messageBuyLicenceRemove: '',
            messageBuyLicenceRemoveError: false,

            limitStake: 0,
            licenseBuyList: [],
            intervalMyResults: null,
        };
    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    created() {
        this.dashboardMyResults();
        this.intervalMyResults = setInterval(() => {
            this.dashboardMyResults();
        }, 30000);

        this.dashboardAnalytics();
        let elm = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        //console.log(elm);
        for (let i = 0; i < elm.length; i++) {
            //new Tooltip(elm[i]);
        }
        this.referenceLink = process.env.VUE_APP_FRONT_UR + "/ref/" + this.returnUser?.username;
    },
    mounted() {
        let elm = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        for (let i = 0; i < elm.length; i++) {
            new Tooltip(elm[i]);
        }
        if (this.returnUser?.licenseConfig?.licenseBuy) {
        }
        // Convertendo o valueLicense para números
        this.returnUser?.licenseConfig?.licenseBuy.forEach(objeto => {
            objeto.valueLicense = parseInt(objeto.valueLicense);
        });

        // Ordenando a lista com base no valueLicense
        this.returnUser?.licenseConfig?.licenseBuy.sort((a, b) => a.valueLicense - b.valueLicense);

    },
    methods: {
        formatDateToBrazilian(dateString) {
            const date = new Date(dateString);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, "0");
            const day = String(date.getUTCDate()).padStart(2, "0");
            const hour = String(date.getUTCHours()).padStart(2, "0");
            const minute = String(date.getUTCMinutes()).padStart(2, "0");
            const second = String(date.getUTCSeconds()).padStart(2, "0");

            const formattedDate = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
            return formattedDate;
        },
        async dashboardMyResults() {
            setTimeout(() => {
                if (!this.loadingPageMyResults) {
                    this.loadingPageMyResults = true;
                    this.$store.dispatch("dashboardMyResults")
                        .then(async (response) => {
                            if (response?.myResults) {
                                response.myResults.acquiredRobots = response?.myResults?.arrayRobots.length;
                                response.myResults.runningRobots = response?.myResults?.arrayRobots.length;
                            }
                            this.myResults = response.myResults;
                            this.loadingPageMyResults = false;
                        }).catch((error) => {
                            this.loadingPageMyResults = false;
                        });
                }
            }, 200);

        },
        async dashboardAnalytics() {
            this.$store.dispatch("dashboardAnalytics").then(async (response) => {
                response.dataOrders.ordersList = {
                    currentPage: 1,
                    perPage: 10,
                    referenceList: response.dataOrders.ordersList.items.map(resp => {
                        resp.exchangeBuySellPrice = `${this.moneyFormat(resp.exchangeBuyPrice)} | ${this.moneyFormat(resp.exchangeSellPrice)}`;
                        resp.exchangeBuySell = `${resp.exchangeBuy} | ${resp.exchangeSell}`;
                        resp.date = this.formatDateToBrazilian(resp.date);
                        resp.value = this.moneyFormat(resp.result - resp.gain);
                        resp.profit = this.moneyFormat(resp.gain);
                        return resp;
                    }),
                    total: response.dataOrders.ordersList.items.length
                };
                this.analytics = response?.dataOrders;
                this.loadingPage = false;
            }).catch((error) => {
                this.loadingPage = false;
            });
        },
        async handleBuyLicence() {
            this.isLoadingBuy = true;
            this.$store
                .dispatch("buyNewLicense", this.licenseIdSelected)
                .then((resp) => {
                    this.messageBuyLicenceRemoveError = false;
                    this.messageBuyLicenceRemove = this.$i18n.t("Nova licença adquirida com sucesso!");
                    //this.$store.dispatch("changeBalance", -this.buyLicenceValue);
                    this.$store.dispatch("recoverUserData");
                    this.isLoadingBuy = false;
                    setTimeout(async () => {
                        this.messageBuyLicenceRemove = '';
                        const closeButton = document.getElementById('close-modal-licence');
                        if (closeButton) {
                            closeButton.click();
                        }
                    }, 2000);
                    this.dashboardMyResults();
                    this.licenseIdSelected = null;
                }).catch((error) => {
                    this.messageBuyLicenceRemove = error.message;
                    this.messageBuyLicenceRemoveError = true;
                    this.isLoadingBuy = false;
                    setTimeout(() => {
                        this.messageBuyLicenceRemove = '';
                    }, 5000);
                });
        },

    },
    unmounted() {
        clearTimeout(this.intervalMyResults);
    },

};
</script>
<template>
    <!-- BEGIN row -->
    <div>
        <div class="row">
            <div v-if="loadingPage" class="text-center mt-5">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div v-if="!loadingPage" class="col-12 col-md-12 col-lg-7">

                <!-- BEGIN card -->
                <div class="py-2 px-2 px-md-0 mt-4 mt-md-0">

                    <div class="card crypto-main-card h-100">
                        <div class="card-body">
                            <div class="row">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="text-center font-size-17 p-2">
                                        {{ $t("Bem-vindo à nova era DeFi! Com nosso sistema, você otimiza pools de") }}
                                        {{ $t("liquidez com eficiência e segurança. Maximize retornos e minimize riscos ")
                                        }}
                                        {{ $t("com nossa solução inovadora e intuitiva. Sua jornada em DeFi começa aqui!")
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END card -->
            </div>
            <div v-if="!loadingPage && myResults" class="col-12 col-md-12 col-lg-5">
                <div class="p-2">

                    <!-- BEGIN card -->
                    <div class="card course-box widget-course h-100">
                        <div class="card-body">
                            <div class="course-widget">
                                <div class="row">
                                    <div class="col-12">
                                        <div v-if="returnUser && returnUser.licenseConfig && returnUser.licenseConfig.licenseBuyStatus"
                                            class="text-center mb-0 ">
                                            <button type="button"
                                                class="purchase-btn btn btn-primary btn-hover-effect f-w-500"
                                                data-bs-toggle="modal" :data-bs-target="`#buyLicenseModal`">
                                                {{ $t("Comprar licença") }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="text-center mt-4 text-secondary">
                                            <h6> {{ $t("Total de licenças adquiridas") }}: {{ myResults ?
                                                myResults.botsRunning : 0 }}
                                            </h6>
                                            <h6> {{ $t("Valor total em liquidez") }}: {{ moneyFormat(myResults ?
                                                myResults.initialValue
                                                : 0)
                                            }}
                                            </h6>
                                            <h6> {{ $t("Valor total em Taxas") }}: {{ moneyFormat(myResults ?
                                                myResults.totalFees
                                                : 0)
                                            }}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="square-group">
                            <li class="square-1 warning"></li>
                            <li class="square-1 primary"></li>
                            <li class="square-2 warning1"></li>
                            <li class="square-3 danger"></li>
                            <li class="square-4 light"></li>
                            <li class="square-5 warning"></li>
                            <li class="square-6 success"></li>
                            <li class="square-7 success"></li>
                        </ul>
                    </div>

                </div>
            </div>
            <hr v-if="!loadingPage">


            <span v-if="loadingPageMyResults && !loadingPage" class="text-center">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </span>
            <span v-if="!loadingPageMyResults" style="margin-bottom: 25px;"></span>



            <div v-if="!loadingPageMyResults && myResults && myResults.arrayRobots.length == 0">

                <div class="text-warning text-center">
                    <span class="h6 w-50">
                        {{ $t("Licenças não encontradas! Adquira a sua agora para desfrutar de todos os benefícios que") }}
                        {{ $t(" oferecemos.") }}
                    </span>

                </div>
            </div>
            <div v-if="myResults" v-for="(  robotInfo, index  ) in   myResults.arrayRobots  " :key="index"
                class="col-12 col-md-6">
                <IndividuallyTradingCard :robotInfo="robotInfo" @onDashboardMyResults="() => dashboardMyResults()" />
            </div>
        </div>

        <!-- comprar licença -->
        <div v-if="!loadingPage" class="modal fade" id="buyLicenseModal">
            <div class="modal-dialog modal-xl mt-5">
                <div class="modal-content">
                    <div class="modal-header">

                        <h5 class="modal-title">{{ $t('Purchase new license') }}</h5>
                        <button type="button" class="btn-close" id="close-modal-licence" @click="() => buyLicenceValue = 0"
                            data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="text-center mb-2">
                                {{ $t(`Quanto do seu capital você quer investir na licença?`) }}
                            </div>

                            <div class="row">

                                <div v-for="(licenseBuy, index) in returnUser.licenseConfig.licenseBuy" :key="index"
                                    class="p-1 col-6 col-md-4 col-lg-3"
                                    @click="() => { licenseIdSelected = licenseBuy.id; limitStake = licenseBuy.maximumLicenseContribution }">

                                    <button type="button" v-if="licenseBuy.valueLicense > 0"
                                        class="mt-1 w-100 btn font-primary btn-outline-theme2 btn-block pricingtable p-1"
                                        :class="{ 'btn-outline-theme2-grad': licenseIdSelected === licenseBuy.id }">
                                        <div class="mb-2 mt-0 price-value">
                                            <span class="font-size-11 currency">$</span>
                                            <span class="fs-5 amount">{{ licenseBuy.valueLicense }}</span>
                                            <span class="font-size-11 duration">/mo</span>
                                        </div>
                                        <ul class="pricing-content">
                                            <li>
                                                {{ $t('Minimum Stake') }} {{
                                                    moneyFormat(licenseBuy.minimumLicenseContribution)
                                                }}
                                            </li>
                                            <li>
                                                {{ $t('Maximum Stake') }} {{
                                                    moneyFormat(licenseBuy.maximumLicenseContribution)
                                                }}
                                            </li>
                                            <li>{{ $t('Average per month') }}
                                                {{ licenseBuy.averagePerMonthPercent }} %
                                            </li>
                                            <li>{{ $t('Licence valid') }}
                                                {{ licenseBuy.licenseValidityDays }}
                                                {{ $t('dias') }}</li>
                                        </ul>
                                    </button>
                                    <button type="button" v-if="licenseBuy.valueLicense == 0"
                                        class="mt-1 w-100 btn font-primary btn-outline-theme2 btn-block pricingtable p-1"
                                        :class="{ 'btn-outline-theme2-grad': licenseIdSelected === licenseBuy.id }">
                                        <div class="mb-2 mt-0 price-value">
                                            <span class="font-size-11 currency">$</span>
                                            <span class="fs-5 amount">{{ $t('Free') }}</span>
                                            <span class="font-size-11 duration">/mo</span>
                                        </div>
                                        <ul class="pricing-content">
                                            <li> {{ licenseBuy.description }}</li>
                                        </ul>
                                    </button>
                                </div>

                                <div class="p-1 col-6 col-md-4 col-lg-3">
                                    <div
                                        class="mt-0 p-3 card crypto-main-card h-100 d-flex align-items-center justify-content-center">
                                        <p class="text-center mb-0">
                                        <h6 class="mb-1">{{ $t('5 AMAZING WAYS TO WIN') }}</h6>
                                        </p>
                                        <ul class="mt-1 w-100 pricing-content">
                                            <li>1- {{ $t('STAKING') }}</li>
                                            <li>2- {{ $t('REFERENCIA DIRECTA') }}</li>
                                            <li>3- {{ $t('REFERENCIA INDIRECTA') }}</li>
                                            <li>4- {{ $t('RECOMPENSAS DIVIDIDAS DE LOS RESULTADOS') }}</li>
                                            <li>5- {{ $t('BENEFICIOS ANUAL DE EMPRESA') }}</li>
                                        </ul>
                                        <!--   <p class="font-white mt-2 text-center">
                                            {{ $t('Maximum stake') }} {{ $t('health for liquidity') }}
                                            {{
                                                moneyFormat(limitStake)
                                            }}
                                        </p> -->
                                    </div>
                                </div>
                            </div>

                            <br>

                        </div>
                        <footer v-if="returnUser && returnUser.licenseConfig && returnUser.licenseConfig.licenseBuyStatus"
                            class="modal-footer">
                            <button @click="handleBuyLicence()" :disabled="isLoadingBuy" type="button"
                                class="purchase-btn btn btn-primary btn-hover-effect f-w-500">
                                <span v-if="isLoadingBuy" class="d-flex align-items-center">
                                    {{ $t("Comprando...") }}
                                    <div class="spinner-grow spinner-grow-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                                <span v-if="!isLoadingBuy">
                                    {{ $t('Buy license') }}
                                </span>

                            </button>
                        </footer>

                        <!-- valor disponível alerta -->
                        <div v-if="messageBuyLicenceRemove" class="alert mt-3 text-center text-white" :class="{
                            'alert-danger': messageBuyLicenceRemoveError,
                            'alert-success': !messageBuyLicenceRemoveError
                        }
                            " role="alert">
                            {{ messageBuyLicenceRemove }}
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<style>
.pricingtable .price-value {
    width: 90px !important;
    height: 90px !important;
    padding: 26px 0 0 !important;
}

.pricingtable .pricing-content li {
    margin: 0 0 0px !important;
}



.lds-ellipsis {
    display: inline-block;
    position: relative;
    margin-top: -55px;
    width: 80px;
    height: 50px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
</style>