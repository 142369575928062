<template>
    <div>
        <form class="p-4" @submit.prevent="saveChange">
            <div class="row">
                <div class="col-sm-2">
                    <div class="d-flex justify-content-center">
                        <div class="media">
                            <div class="media-aside align-self-start"
                                style="display: flex; flex-direction: column; align-items: center;">
                                <span class="b-avatar bg-light rounded mb-2" style="width: 140px; height: 140px;">
                                    <span v-if="!urlImage" class="b-avatar-text" style="font-size: calc(60px);">
                                        <span>IMG</span>
                                    </span>
                                    <span v-if="urlImage" class="b-avatar-img">
                                        <img :src="urlImage" style="width: 140px; height: 140px;" alt="avatar">
                                    </span>
                                </span>
                                <div class="media-body mt-75 ml-75"
                                    style="display: flex; flex-direction: column; align-items: center;">
                                    <button type="button" @click="handleClick"
                                        class="btn mb-75 mr-75 mt-auto btn-outline-primary">
                                        {{ $t("Alterar foto") }} </button>
                                    <input hidden="hidden" ref="refInputEl" type="file" accept=".jpg, .png, .gif"
                                        class="form-control-file" @change="onFileChange">
                                    <p class="card-text text-center text-light"
                                        style="max-width: 14rem; font-size: smaller;">
                                        {{ $t("JPG, GIF ou PNG permitidos. Tamanho máximo de 4MB") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-10">
                    <div class="row">
                        <div class="col-sm-6">
                            <div role="group" class="form-group"><label for="account-username" class="d-block text-light">{{
                                $t("Usuário") }}</label>
                                <div>
                                    <span>
                                        <input name="username" v-model="username" type="text" placeholder="Nome do usuário"
                                            class="form-control" :disabled="isLoading">
                                        <small v-if="errors.username" class="text-danger">
                                            {{ errors.username }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div role="group" class="form-group"><label for="account-name" class="d-block text-light">
                                    {{ $t("Nome completo") }}
                                </label>
                                <div>
                                    <span>
                                        <input name="name" v-model="fullname" type="text" placeholder="Nome completo"
                                            class="form-control" :disabled="isLoading">
                                        <small v-if="errors.fullname" class="text-danger">
                                            {{ errors.fullname }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div role="group" class="form-group">
                                <label for="account-e-mail" class="d-block text-light">
                                    E-mail
                                </label>
                                <div>
                                    <input v-model="email" name="email" type="text" placeholder="E-mail"
                                        class="form-control" disabled>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div role="group" class="form-group"><label for="account-phone-number"
                                    class="d-block text-light">
                                    {{ $t("Número do celular") }}
                                </label>
                                <div>
                                    <input v-model="mobileNumber" name="mobileNumber" type="text"
                                        placeholder="Digite seu número para contatos" class="form-control"
                                        :disabled="isLoading">
                                    <small v-if="errors.mobileNumber" class="text-danger">
                                        {{ errors.mobileNumber }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="mt-75 col-sm-6">
                            <div show="true" class="card text-white mt-2" style="background-color: #00ACC1c0;">
                                <div class="card-body">
                                    {{ $t("Para sua segurança, os dados da sua conta só poderão") }}
                                    {{ $t("ser modificados mediante a confirmação de um código enviado para seu e-mail.")
                                    }}
                                    <div class="d-flex justify-content-end mt-1">
                                        <button type="button"
                                            class="purchase-btn btn btn-primary btn-hover-effect f-w-500 btn-sm"
                                            @click="sendCode">
                                            {{ $t("Enviar o código por e-mail") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-spacing-0 mb-1"></div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-flex mb-2 mb-sm-0 my-sm-2">
                                <div class="custom-control-primary custom-control custom-switch">
                                    <input type="checkbox" v-model="authentication2fa" name="check-button"
                                        class="custom-control-input" value="true" :disabled="isLoading">
                                    <small v-if="errors.authentication2fa" class="text-danger">
                                        {{ errors.authentication2fa }}
                                    </small>
                                </div>
                                <p class="card-text" :class="authentication2fa ? 'text-success' : 'text-danger'">
                                    {{ authentication2fa ? `${$t('Autenticação de dois fatores habilitada')}` :
                                        `${$t('Autenticação de dois fatores desabilitada')}` }}
                                </p>
                            </div>
                            <fieldset class="form-group">
                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0 text-light">
                                    {{ $t("Código de segurança") }}
                                </legend>
                                <div>
                                    <span>
                                        <input v-model="securityCode" name="code" class="form-control "
                                            :disabled="isLoading" :placeholder="`${$t('Digite o código de segurança')}`"
                                            type="text">
                                        <small v-if="errors.securityCode" class="text-danger">
                                            {{ errors.securityCode }}
                                        </small>
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-12">
                            <div class="d-flex justify-content-end">
                                <button type="reset" class="btn mt-2 mr-1 text-white btn-none" @click.prevent="resetForm">
                                    {{ $t("Redefinir") }}
                                </button>
                                <button type="submit"
                                    class="mt-2 purchase-btn btn btn-primary btn-hover-effect f-w-500 btn-sm">
                                    <div v-if="!loading">
                                        {{ $t("Salvar alterações") }}
                                    </div>
                                    <div v-else class="d-flex align-items-center">
                                        {{ $t("Carregando...") }}
                                        <span class="spinner-grow spinner-grow-sm" role="status">
                                            <span class="sr-only"></span>
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showAlert" :class="`alert alert-${colorAlert} mt-2`" role="alert">
                    <p>{{ textAlert }}</p>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as yup from 'yup';
import { debounce } from "debounce";

export default {
    name: 'generalProfile',
    components: {
    },
    props: {
    },
    data() {
        return {
            avatar: null,
            username: null,
            fullname: null,
            email: null,
            mobileNumber: null,
            loading: false,
            urlImage: null,
            profileFile: null,
            authentication2fa: false,

            labelUsername: this.$i18n.t("Usuário"),
            placeholderUsername: this.$i18n.t("Nome do usuário"),
            labelfullname: this.$i18n.t("Nome completo"),
            labelEmail: "E-mail",
            labelmobileNumber: this.$i18n.t("Número do celular"),
            placeholdermobileNumber: this.$i18n.t("Digite seu número para contatos"),

            colorAlert: "warning",
            showAlert: false,
            textAlert: "",
            securityCode: "",
            isLoading: false,

            errors: {
                fullname: '',
                username: '',
                mobileNumber: '',
                profileFile: '',
                securityCode: '',
                authentication2fa: '',
            },
        };
    },
    computed: {
        ...mapGetters(["returnUser",]),
        changedForm() {
            return (
                ((this.username != this.returnUser.username && this.username != null) ||
                    (this.fullname != this.returnUser.fullname &&
                        this.fullname != null) ||
                    (this.mobileNumber != this.returnUser.mobileNumber &&
                        this.mobileNumber != null) ||
                    this.profileFile != null) &&
                this.securityCode.length == 6
            );
        },
    },
    mounted() {
        this.loadingDataProfile();
    },
    watch: {
        username: debounce(function (username) {
            const usernameMinusc = username.toLowerCase();

            if (this.username !== usernameMinusc) {
                this.username = usernameMinusc;
            }
            if (username) {
                this.errors.username = '';
            }
        }, 500),
        returnUser: function (oldVal, newVal) {
            this.loadingDataProfile();
        },
        securityCode(newValue) {
            if (newValue) {
                this.errors.securityCode = '';
            }
        },

        fullname(newValue) {
            if (newValue) {
                this.errors.fullname = '';
            }
        },
        mobileNumber(newValue) {
            if (newValue) {
                this.errors.mobileNumber = '';
            }
        },
        profileFile(newValue) {
            if (newValue) {
                this.errors.profileFile = '';
            }
        },
    },
    methods: {
        ...mapActions(["userEditProfile", "recoverUserData", "confirmEmail"]),
        onFileChange(e) {
            const file = e.target.files[0];
            this.urlImage = URL.createObjectURL(file);
            this.profileFile = file;
        },
        sendCode() {
            const data = {
                clientEmail: this.returnUser.email,
                clientName: this.returnUser.fullname,
            };
            this.$store.dispatch("sendSecurityCode", data).then((resp) => {
                this.colorAlert = "success";
                this.showAlert = true;
                this.textAlert = resp.message;
                setTimeout(() => {
                    this.showAlert = false;
                }, 5000);
            });
        },
        loadingDataProfile() {
            this.username = this.returnUser.username;
            this.fullname = this.returnUser.fullname;
            this.email = this.returnUser.email;
            this.mobileNumber = this.returnUser.mobileNumber;

            this.urlImage = this.returnUser?.avatar
                ? `${process.env.VUE_APP_API_URL}/avatar/${this.returnUser.avatar}`
                : null;
            this.authentication2fa = this.returnUser.authentication2fa;
        },
        resetForm() {
            this.username = this.returnUser.username;
            this.fullname = this.returnUser.fullname;
            this.email = this.returnUser.email;
            this.mobileNumber = this.returnUser.mobileNumber;
        },
        resetImage() {
            this.profileFile = null;
        },
        saveChange() {
            const schema = yup.object().shape({
                username: yup
                    .string()
                    .required(this.$i18n.t('O usuário é obrigatório'))
                    .min(4, this.$i18n.t("O usuário deve ter pelo menos 4 caracteres"))
                    .max(50, this.$i18n.t("O usuário não pode ter mais de 50 caracteres")),
                fullname: yup
                    .string()
                    .required(this.$i18n.t('O nome completo é obrigatório'))
                    .min(6, this.$i18n.t("O nome completo deve ter pelo menos 6 caracteres"))
                    .max(100, this.$i18n.t("O nome completo não pode ter mais de 100 caracteres")),
                securityCode: yup
                    .string()
                    .min(6, this.$i18n.t("O código de segurança deve ter pelo menos 6 caracteres"))
                    .max(6, this.$i18n.t("O código de segurança não pode ter mais de 6 caracteres")),
            });

            schema
                .validate({
                    username: this.username,
                    fullname: this.fullname,
                    securityCode: this.securityCode
                }, { abortEarly: false })
                .then(() => {
                    this.isLoading = true;

                    this.loading = true;
                    const data = {
                        fullname: this.fullname,
                        username: this.username,
                        mobileNumber: this.mobileNumber,
                        avatar: this.profileFile,
                        securityCode: this.securityCode,
                        authentication2fa: this.authentication2fa,
                    };

                    if (this.mobileNumber == this.returnUser.mobileNumber) {
                        delete data.mobileNumber;
                    }

                    if (this.username == this.returnUser.username) {
                        delete data.username;
                    }

                    if (this.fullname == this.returnUser.fullname) {
                        delete data.fullname;
                    }

                    if (this.profileFile == null) {
                        delete data.avatar;
                    }

                    this.userEditProfile(data)
                        .then((response) => {
                            this.recoverUserData();
                            this.resetImage();
                            this.loading = false;
                            this.colorAlert = "success";
                            this.showAlert = true;
                            this.textAlert = this.$i18n.t("As informações do seu perfil foram atualizadas.");
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 5000);
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.isLoading = false;
                            const { errors } = error || {};
                            this.errors.fullname = errors?.fullname?.[0] || '';
                            this.errors.username = errors?.username?.[0] || '';
                            this.errors.mobileNumber = errors?.mobileNumber?.[0] || '';
                            this.errors.profileFile = errors?.profileFile?.[0] || '';
                            this.errors.securityCode = errors?.securityCode?.[0] || '';
                            this.errors.authentication2fa = errors?.authentication2fa?.[0] || '';
                            console.log(error);
                            this.colorAlert = "warning";
                            this.showAlert = true;
                            this.textAlert = error.message;
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 5000);
                        });
                }).catch((error) => {
                    this.isLoading = false;
                    this.errors.fullname = '';
                    this.errors.username = '';
                    this.errors.mobileNumber = '';
                    this.errors.profileFile = '';
                    this.errors.securityCode = '';
                    this.errors.authentication2fa = '';

                    if (error.inner) {
                        error.inner.forEach((e) => {
                            this.errors[e.path] = e.message;
                        });
                    }
                });

        },
        sendConfirmationEmail() {
            this.confirmEmail()
                .then((response) => {
                    this.emailSent = true;
                })
                .catch((error) => { });
        },
        handleClick() {
            this.$refs.refInputEl.click();
        },
    },
};
</script>

<style>
.b-avatar .b-avatar-custom,
.b-avatar .b-avatar-img,
.b-avatar .b-avatar-text {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    mask-image: radial-gradient(#fff, #000)
}
</style>