<template>
<div class="user-profile">
    <div class="image">
        <div class="avatar text-center">
            <img alt="" src="../../../assets/images/user/2.png" />
        </div>
        <div class="icon-wrapper">
            <i class="icofont icofont-pencil-alt-5"></i>
        </div>
    </div>
    <div class="user-content text-center">
        <h5 class="text-uppercase">mark jenco</h5>
        <div class="social-media">
            <ul class="list-inline">
                <li class="list-inline-item"><a href="https://www.facebook.com/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                <li class="list-inline-item"><a href="https://accounts.google.com/" target="_blank"><i class="fa fa-google-plus"></i></a></li>
                <li class="list-inline-item"><a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter"></i></a></li>
                <li class="list-inline-item"><a href="https://www.instagram.com/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                <li class="list-inline-item"><a href="https://rss.app/" target="_blank"><i class="fa fa-rss"></i></a></li>
            </ul>
        </div>
        <hr>
        <div class="follow text-center">
            <div class="row">
                <div class="col border-right"><span>Following</span>
                    <div class="follow-num">236k</div>
                </div>
                <div class="col"><span>Follower</span>
                    <div class="follow-num">3691k</div>
                </div>
            </div>
        </div>
        <hr>
        <div class="text-center">
            <p class="mb-0">Mark.jecno23@gmail.com</p>
            <p class="mb-0">+91 365 - 658 - 1236</p>
            <p class="mb-0">Fax: 123-4560</p>
        </div>
    </div>
</div>
</template>
