<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <span v-if="avatarSrc">
        <img class="b-r-10" :src="avatarSrc" alt="Profile" height="35" width="35">
      </span>
      <span v-else>
        <img class="b-r-10" src="@/assets/images/dashboard/profile.png" alt="" />
      </span>
      <div class="media-body ">
        <span v-if="returnUser && returnUser.fullname" style="margin-right: 10px;">{{ limitString(returnUser.fullname) }}</span>
        <p class="mb-0 font-roboto">
          <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="{ name: 'accountSetting' }"><vue-feather type="user"></vue-feather><span>{{ $t('Account') }}
          </span></router-link>
      </li>
      <li>
        <router-link :to="{ name: 'supportTickets' }"><vue-feather type="user"></vue-feather><span>{{ $t('Support') }}
          </span></router-link>
      </li>
      <li>
        <a @click="logout"><vue-feather type="log-in"></vue-feather><span>{{ $t('Log out') }}</span></a>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: 'Profile',
  data() {
    return {
      avatarSrc: null,
    };
  },
  computed: {
    ...mapGetters(["returnUser"]),
  },
  watch: {
    returnUser: function (oldVal, newVal) {

    },
  },
  mounted() {
    this.imgChange();
  },
  watch: {
    returnUser(newValue) {
      this.imgChange();
    }
  },
  methods: {
    ...mapActions(["userLogout"]),
    logout: function () {
      localStorage.removeItem('lifemoneyToken');
      this.userLogout().then(() => {
        this.$router.replace("/login");
      }).catch(error => {
        this.$router.replace("/login");
      });
    },
    imgChange() {

      if (this.returnUser?.avatar) {
        this.avatarSrc = this.returnUser?.avatar
          ? `${process.env.VUE_APP_API_URL}/avatar/${this.returnUser?.avatar}`
          : null;
      }
    },
    limitString(nome) {
      const palavras = nome.trim().split(/\s+/); // Dividir em palavras, removendo espaços extras
      const nomeAbreviado = palavras.slice(0, 1).join(' '); // Combina as primeiras duas palavras
      return nomeAbreviado;
      if (nomeAbreviado.length <= 12) {
        return nomeAbreviado;
      } else {
        return nomeAbreviado.substring(0, 12);
      }
    }
  },
};
</script>


