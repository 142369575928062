import api from "@/libs/axios";

const trading = {
  state: {},
  getters: {},
  actions: {
    buyNewLicense: ({ commit }, idLicense) => {
      const data = {
        idLicense: idLicense,
      };
      return new Promise(async (resolve, reject) => {
        await api
          .post("/arbitration/buy-licence", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    contributionValueTrading: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/arbitration/value-allocation", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    upgradeLicence: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/arbitration/buy-licence", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    renewalLicence: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/arbitration/renew-license", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    withdrawValueTrading: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/trader/robot-withdraw", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    changeConfigRobot: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/arbitration/config-robot-arbitration", config)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    searchConfigRobot: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/arbitration/search-config-robot-arbitration")
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    searchConfigDepositsWithdrawls: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/search-config-deposits-withdrawls")
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    changeConfigDepositsWithdrawls: ({ commit }, config) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch("/admin/config-deposits-withdrawls", config)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
  },
  mutations: {},
};

export default trading;
