<template>
  <div>
    <Breadcrumbs :title="{
      name: 'View Support',

    }" :main="{
  name: 'Support tickets',
  url: '/support-tickets'
}" />
    <div v-if="loadingPage" class="text-center mt-5">
      <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="!loadingPage">

      <div class="card mb-0 p-2 text-light">
        <div class="card-body" v-if="ticketData">
          <div class="row">
            <div class="col-8">
              <h5 class="card-title text-light">{{ $i18n.t('Ticket ID: #') + ticketData._id }}</h5>
            </div>
            <div class="col-4 text-light">
              <div class="d-flex justify-content-end posAdjust mt-0">
                <router-link :to="{ name: 'supportTickets' }" style="margin-right: 5px;">
                  <button class="btn mt-2 btn-warning">
                    {{ $t("Voltar") }}
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="text-light">
            <h6>{{ $t("Status") }}:</h6>
            <div>{{ $t(ticketData.status) }}</div>
          </div>
          <div>
            <h6>{{ $t("Assunto") }}:</h6>
            <div>{{ ticketData.subject }}</div>
          </div>
          <div>
            <h6>{{ $t("Descrição do problema") }}:</h6>
            <div>{{ ticketData.description }}</div>
          </div>
          <div v-if="ticketData.attachments.length > 0">
            <h6>{{ $t("Categoria") }}:</h6>
            <div>{{ $t(ticketData.category) }}</div>
          </div>
          <div>
            <div>{{ $t("Imagem anexada") }}:</div>
            <div class="text-center">
              <img :src="renderImgAvatar(ticketData.attachments[0])" class="mt-1" width="500">
            </div>
          </div>
        </div>
      </div>
      <div v-if="ticketData && ticketData.conversation.length == 0" class="card text-warning mt-2 text-center">
        <div class="card-body">
          <span role="status" class="spinner-grow spinner-grow-sm">
            <span class="sr-only">Small Spinner</span>
          </span>
          {{ $t("Aguardando resposta da equipe de suporte") }}
        </div>
      </div>
      <div v-if="ticketData && ticketData.status == 'Closed'" class="card text-danger mt-2 mb-0 text-center">
        <div class="p-2 h5">
          {{ $t('Ticket closed') }}
        </div>
      </div>
      <div class="card mt-2 text-light" v-if="ticketData && ticketData.conversation.length > 0">

        <div class="card-body">
          <h4 class="card-title">{{ $t('Histórico da conversa') }}</h4>
          <div v-for="(conversation, index) in ticketData.conversation" :key="index">
            <div class="card" :class="{
              'bg-light-info': conversation.user.attendentId == returnUser._id,
              'bg-light-warning': conversation.user.attendentId != returnUser._id
            }">
              <div class="card-body">
                <div class="d-flex flex-grow-1 w-100">
                  <span class="mt-2">
                    <span class="b-avatar-img">
                      <img v-if="conversation.user.avatar" :src="renderImgAvatar(conversation.user.avatar)"
                        style="width: 32px; height: 32px; border-radius: 50%;" alt="avatar">
                    </span>
                  </span>
                  <strong class="text-dark p-2">
                    {{ conversation.user.username }}
                    <p class="text-dark fw-light" style="font-size: 10px;">
                      {{
                        format(
                          new Date(conversation.user.createdAt),
                          "dd/MM/yyyy HH:mm:ss"
                        )
                      }}
                    </p>
                  </strong>
                </div>

                <div class="mt-1 mx-1 text-dark">
                  {{ conversation.response }}
                </div>
              </div>

            </div>
          </div>
          <div v-if="ticketData && ticketData.status == 'Open'">
            <hr>
            <div class="d-flex">
              <div class="d-flex flex-column w-100">
                <textarea id="problem-description" v-model="response" placeholder="Por favor, digite a sua resposta"
                  rows="3" wrap="soft" class="form-control">
              </textarea>
                <small v-if="errors.response" class="text-danger">
                  {{ errors.response }}
                </small>
                <small v-if="!errors.response" class="text-warning">É necessário preencher esse campo para responder ou
                  fechar o
                  ticket</small>

              </div>
            </div>
            <div class="d-flex justify-content-start mt-1">
              <button type="button" class="btn mr-1 btn-outline-success" @click="() => responseTicket('Open')"
                :disabled="response.length == 0 || btnLoadingResponse">

                <div v-if="!btnLoadingResponse">
                  {{
                    $t("Responder")
                  }}
                </div>
                <div v-else>
                  <div class="spinner-grow mr-1" role="status">
                    <span class="sr-only"></span>
                  </div>
                  {{
                    $t("Respondendo")
                  }}
                </div>
              </button>

              <button type="button" class="btn btn-outline-info" @click="() => responseTicket('Closed')"
                :disabled="response.length == 0 || btnLoadingClose" style="margin-left: 5px;">

                <div v-if="!btnLoadingClose">
                  {{
                    $t("Fechar ticket")
                  }}
                </div>
                <div v-else>
                  <div class="spinner-grow mr-1" role="status">
                    <span class="sr-only"></span>
                  </div>
                  {{
                    $t("Fechando...")
                  }}
                </div>
              </button>
              <div v-if="showAlert" :class="`alert alert-${colorAlert} mt-2`" role="alert">
                <p>{{ textAlert }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { format } from "date-fns";
import * as yup from 'yup';
export default {
  components: {
  },
  data() {
    return {
      loadingPage: true,
      ticketData: null,
      response: "",
      isLoading: false,
      btnLoadingResponse: false,
      btnLoadingClose: false,

      errors: {
        response: '',
      },

      textAlert: "",
      colorAlert: "warning",
      showAlert: false,
      format: format
    };
  },
  watch: {

  },
  computed: {
    ...mapGetters(["returnUser"]),
  },
  mounted() {
    this.getTicketData();
  },
  methods: {
    getTicketData() {
      this.isLoading = true;
      this.$store
        .dispatch("getTicketUser", this.$route.params?.idTicket)
        .then((resp) => {
          this.loadingPage = false;
          this.isLoading = false;
          this.ticketData = resp;
        }).catch(error => {
          this.loadingPage = false;
          this.isLoading = false;
        });
    },
    renderImgAvatar(img) {

      if (img)
        return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
    },
    responseTicket(status) {
      let loadType = 'btnLoadingResponse';
      if (status == 'Open') {
        loadType = 'btnLoadingResponse';
      }
      if (status == 'Closed') {
        loadType = 'btnLoadingClose';

      }
      const schema = yup.object().shape({
        response: yup
          .string()
          .required("Resposta é obrigatório")
          .min(2, "O resposta deve ter no mínimo 4 caracteres")
          .max(300, "O resposta não pode ter mais de 300 caracteres"),
      });

      schema
        .validate({
          response: this.response,
        }, { abortEarly: false })
        .then(() => {

          this[loadType] = true;
          const data = {
            ticketId: this.$route.params?.idTicket,
            response: this.response,
            status: status,
          };
          this.$store.dispatch("responseTicket", data).then((resp) => {
            this[loadType] = false;
            this.getTicketData();
            this.response = "";
          }).catch(error => {
            this[loadType] = false;
            this.textAlert = err.data.message;
            this.colorAlert = "danger";
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 5000);
          });
        }).catch((error) => {
          this.isLoading = false;
          this.errors.response = '';

          if (error.inner) {
            error.inner.forEach((e) => {
              this.errors[e.path] = e.message;
            });
          }
        });
    },
  },

};
</script>

  
