
<template>
    <div>

        <Breadcrumbs :title="{
            name: 'Deposits List',
        }" />
        <div v-if="loadingPage" class="text-center mt-5">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="!loadingPage">
            <div class="card mb-0 p-2">
                <div class="m-2 mt-3">
                    <MegaTable :tableColumns="tableColumns" :loading="loading" :tableRows="tableRows"
                        @fetch-tablerows="(params) => fetchListGeneral(params)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MegaTable from "@/components/MegaTable.vue";
import Numeral from "numeral";

export default {
    components: {
        MegaTable,
    },
    data() {
        return {
            tableColumns: [
                { label: "VALOR USDC", field: "valueUSD" },
                { label: "CRYPTO", field: "crypto" },
                { label: "VALOR CRYPTO", field: "valueCrypto" },
                { label: "ENDEREÇO CRYPTO", field: "depositAddress" },
                { label: "TXNID", field: "depositTxnId" },
                { label: "STATUS", field: "status" },
                { label: "DATA", field: "createdAt" },
            ],
            tableRows: null,
            loading: false,
            loadingPage: true,
        };
    },
    methods: {

        fetchListGeneral(providedParams) {
            this.loading = true;
            const params = {
                page: providedParams ? (providedParams.page || 1) : 1,
                limit: providedParams ? (providedParams.limit || 10) : 10,
                search: providedParams ? (providedParams.search || "") : "",
            };

            // Assuming deposits is a Vuex store
            this.$store
                .dispatch("userDepositsList", params)
                .then((resp) => {
                    resp.depositsList.map((item) => {
                        item.valueUSD = this.moneyFormat(item.valueUSD);
                        return item;
                    });
                    this.tableRows = {
                        currentPage: resp.currentPage,
                        total: resp.total,
                        referenceList: resp.depositsList,
                        perPage: resp.perPage,
                    };
                    this.loadingPage = false;
                    //$emit("de depositsList para referenceList");
                })
                .catch((error) => {
                    // Trate os erros aqui
                    this.loadingPage = false;
                });

            this.loading = false;
        },
    },
    created() {
        this.fetchListGeneral();
    },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
  
<style lang="scss">
.limitString {
    /* Sem restrições específicas para desktop */
}

/* Estilo para dispositivos móveis */
@media (max-width: 768px) {
    .limitString {
        white-space: nowrap;
        max-width: 16rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>