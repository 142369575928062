<template>
  <div>
    <Breadcrumbs :title="{
      name: 'New Support',

    }" :main="{
  name: 'Support tickets',
  url: '/support-tickets'
}" />
    <div class="card mb-0 p-2 text-light">
      <h4 class="card-title ">{{ $t("Novo Ticket") }}</h4><span>
        <form class="p-4" @submit.prevent="createNewTitcket">
          <div>
            <h6>{{ $t("Assunto") }}</h6>
            <fieldset class="form-group">
              <div>
                <span>
                  <input id="subject" type="text" v-model="subject" :placeholder="`${$t('Digite o assunto')}`"
                    class="form-control">
                  <small v-if="errors.subject" class="text-danger">
                    {{ errors.subject }}
                  </small>
                </span>
              </div>
            </fieldset>
          </div>
          <div class="mt-2">
            <h6>{{ $t("Descrição do problema") }}</h6>
            <fieldset class="form-group">
              <div>
                <span>
                  <textarea id="problem-description" v-model="problemDescription"
                    :placeholder="`${$t('Descreva em detalhes qual é o problema que você deseja tratar com a equipe de suporte')}`"
                    rows="4" wrap="soft" class="form-control">
                  </textarea>
                  <small v-if="errors.problemDescription" class="text-danger">
                    {{ errors.problemDescription }}
                  </small>
                </span>
              </div>
            </fieldset>
          </div>
          <div class="mt-2">
            <h6> {{ $t("Gostaria de anexar algum print para nos ajudar a compreender melhor o problema?") }} </h6>
            <div class="media d-flex justify-content-center">
              <div class="media-aside align-self-start"
                style="display: flex; flex-direction: column; align-items: center;">
                <span class="b-avatar bg-light rounded mb-2" style="width: 140px; height: 140px;">
                  <span v-if="!urlImage" class="b-avatar-text" style="font-size: calc(60px);">
                    <span>IMG</span>
                  </span>
                  <span v-if="urlImage" class="b-avatar-img">
                    <img :src="urlImage" style="width: 140px; height: 140px;" alt="avatar">
                  </span>
                </span>
                <div class="media-body mt-75 ml-75" style="display: flex; flex-direction: column; align-items: center;">
                  <button type="button" @click="handleClick" class="btn mb-75 mr-75 mt-auto btn-outline-primary">
                    {{ $t("Alterar foto") }} </button>
                  <input hidden="hidden" ref="refInputEl" type="file" accept=".jpg, .png, .gif" class="form-control-file"
                    @change="onFileChange">
                  <p class="card-text text-center" style="max-width: 14rem; font-size: smaller;">
                    {{ $t("JPG, GIF ou PNG permitidos. Tamanho máximo de 4MB") }}
                  </p>
                </div>
              </div>
              <small v-if="errors.printFile" class="text-danger">
                {{ errors.printFile }}
              </small>
            </div>
          </div>
          <div class="mt-2">
            <h6> {{ $t("Selecione uma categoria, assim, conseguimos direcionar") }}
              {{ $t("o seu problema imediatamente para a equipe responsável.") }} </h6>
            <div dir="auto" class="v-select vs--single vs--searchable">

              <div class="mb-2 me-2 mb-0 animated-modal-md-mb w-auto">
                <select class="form-select" id="entrance" v-model="categorySelected">
                  <option v-for="(anim, index) in categoryOptions" :value="anim.value" :key="index">
                    {{ anim.label }}</option>
                </select>
              </div>
            </div>
            <small v-if="errors.categorySelected" class="text-danger">
              {{ errors.categorySelected }}
            </small>
            <hr>
            <div class="demo-spacing-0 mb-1"></div>
            <div class="d-flex justify-content-end">

              <router-link :to="{ name: 'supportTickets' }" style="margin-right: 5px;">
                <button class="btn mt-2 btn-warning">
                  {{ $t("Voltar") }}
                </button>
              </router-link>
              <button type="submit" class="btn mt-2 btn-primary d-flex align-items-center" :disabled="isLoading">
                <div v-if="!isLoading">
                  {{ $t("Enviar ticket") }}
                </div>
                <div v-else class="d-flex align-items-center">
                  {{ $t("Carregando...") }}
                  <span class="spinner-grow spinner-grow-sm" role="status">
                    <span class="sr-only"></span>
                  </span>
                </div>
              </button>
            </div>
            <div v-if="showAlert" :class="`alert alert-${colorAlert} mt-2`" role="alert">
              <p>{{ textAlert }}</p>
            </div>
          </div>
        </form>
      </span>
    </div>
  </div>
</template>
<script>
import * as yup from 'yup';
export default {
  components: {
  },
  data() {
    return {

      isLoading: false,
      subject: "",
      problemDescription: "",
      printFile: null,
      urlImage: null,
      categorySelected: null,
      categoryOptions: [
        {
          label: this.$i18n.t("Selecione a categoria"),
          value: null,
        },
        { label: this.$i18n.t("Dados da conta"), value: "account_data" },
        { label: this.$i18n.t("Financeiro"), value: "financial" },
        { label: this.$i18n.t("Outro"), value: "other" },
        {
          label: this.$i18n.t("Rede de afiliados"),
          value: "affiliate_network",
        },
        { label: this.$i18n.t("Licença"), value: "robot_trading" },
      ],
      textAlert: "",
      colorAlert: "warning",
      showAlert: false,

      errors: {
        subject: '',
        problemDescription: '',
        categorySelected: '',
        printFile: '',

      },
    };
  },
  watch: {
    subject(newValue) {
      if (newValue) {
        this.errors.subject = '';
      }
    },
    problemDescription(newValue) {
      if (newValue) {
        this.errors.problemDescription = '';
      }
    },
    categorySelected(newValue) {
      if (newValue) {
        this.errors.categorySelected = '';
      }
    },
    printFile(newValue) {
      if (newValue) {
        this.errors.printFile = '';
      }
    },
  },
  methods: {
    createNewTitcket() {

      const schema = yup.object().shape({
        subject: yup
          .string()
          .required(this.$t('Assunto é obrigatório'))
          .min(4, this.$t("O assunto deve ter no mínimo 4 caracteres"))
          .max(30, this.$t("O assunto não pode ter mais de 30 caracteres")),
        problemDescription: yup
          .string()
          .required(this.$t('Descrição do problema é obrigatório'))
          .min(6, this.$t("O descrição do problema deve ter no mínimo 6 caracteres"))
          .max(100, this.$t("O descrição do problema não pode ter mais de 100 caracteres")),
        categorySelected: yup
          .string()
          .required(this.$t('Categoria é obrigatório'))
          .min(2, this.$t("O categoria deve ter no mínimo 2 caracteres"))
          .max(50, this.$t("O categoria não pode ter mais de 50 caracteres")),
      });

      schema
        .validate({
          subject: this.subject,
          problemDescription: this.problemDescription,
          categorySelected: this.categorySelected
        }, { abortEarly: false })
        .then(() => {
          this.isLoading = true;
          const data = {
            dataTicket: {
              subject: this.subject,
              description: this.problemDescription,
              category: this.categorySelected,
              attachments: [],
            },
            attachments: [],
          };
          if (this.printFile) {
            data.attachments = [this.printFile];
          }
          this.$store
            .dispatch("createTicket", data)
            .then((resp) => {
              this.textAlert = resp.message;
              this.colorAlert = "success";
              this.showAlert = true;
              this.isLoading = false;

            })
            .catch((err) => {
              this.textAlert = err.data.message;
              this.colorAlert = "danger";
              this.showAlert = true;
              this.isLoading = false;
            });

        }).catch((error) => {
          this.isLoading = false;
          this.errors.subject = '';
          this.errors.problemDescription = '';
          this.errors.categorySelected = '';
          this.errors.printFile = '';

          if (error.inner) {
            error.inner.forEach((e) => {
              this.errors[e.path] = e.message;
            });
          }
        });

    },
    handleClick() {
      this.$refs.refInputEl.click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.urlImage = URL.createObjectURL(file);
      this.printFile = file;
    },
  },
};
</script>

  
