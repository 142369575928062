<template>
    <div>

        <Breadcrumbs :title="{
            name: 'Support List',

        }" :main="{
    name: 'Home',
    url: '/'
}" />
        <div>
            <div class="card mb-0 p-2 ">
                <div class="m-2 mt-3">
                    <!-- Table Top -->
                    <div class="row">
                        <!-- Per Page -->
                        <div
                            class="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start">
                            <router-link class="btn btn-primary d-flex align-items-center"
                                :to="{ name: 'supportTicketNew' }">
                                <i class="fa fa-plus fs-5"></i>
                                <span class="px-2">{{ $t("Criar novo ticket") }}</span>
                            </router-link>
                        </div>

                        <!-- Pagination -->
                        <div class="col-12 col-md-4 d-flex align-items-center justify-content-center">

                        </div>

                        <!-- Search -->
                        <div class="col-12 col-md-4">
                            <div
                                class="d-flex align-items-center justify-content-center justify-content-md-end mt-1 mt-md-0">
                                <div class="demo-vertical-spacing">
                                    <div class="input-group">
                                        <input v-model="searchQuery" class="form-control" type="text"
                                            :placeholder="`${$t('Faça aqui sua busca')}`">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive bg-dark">

                    <table class="table align-middle bg-dark">
                        <thead>
                            <tr>
                                <th scope="col" class="bg-dark">{{ $t("TICKET") }}</th>
                                <th scope="col" class="bg-dark">{{ $t("ASSUNTO") }}</th>
                                <th scope="col" class="bg-dark">{{ $t("CATEGORIA") }}</th>
                                <th scope="col" class="bg-dark">{{ $t("STATUS") }}</th>
                                <th scope="col" class="bg-dark">{{ $t("ABERTURA") }}</th>
                                <th scope="col" class="bg-dark">{{ $t("ATUALIZAÇÃO") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="items.length > 0" v-for="( item, index ) in  items " :key="index">
                                <td class="text-primary pointer bg-dark">

                                    <router-link :to="{ name: 'supportTicketView', params: { idTicket: item._id } }"
                                        style="margin-right: 5px;">
                                        {{ item._id
                                        }}
                                    </router-link>
                                </td>
                                <td class="bg-dark">
                                    <div>
                                        <span class="align-text-top">{{ item.subject }}</span>
                                    </div>
                                </td>
                                <td class="bg-dark">
                                    <div class="text-nowrap">
                                        <span class="align-text-top">{{ $t(item.category) }}</span>
                                    </div>
                                </td>
                                <td class="bg-dark">
                                    <span :class="'badge bg-' + resolveUserStatusVariant(item.status)">
                                        {{ $t(item.status) }}</span>
                                </td>
                                <td class="bg-dark">
                                    <div class="text-nowrap">
                                        <span class="align-text-top">{{ item.createdAt ? format(new Date(item.createdAt),
                                            'dd/MM/yyyy HH:mm') : ''
                                        }}</span>
                                    </div>
                                </td>
                                <td class="bg-dark">
                                    <div class="text-nowrap">
                                        <span class="align-text-top">{{ item.updatedAt ? format(new Date(item.updatedAt),
                                            'dd/MM/yyyy HH:mm') : ''
                                        }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="items.length == 0 && !loadinPage"
                        class="d-flex align-items-center justify-content-center w-100 fs-6 p-2">
                        {{ $t('Nenhum registro correspondente encontrado') }}
                    </div>
                    <div v-if="loadinPage" class="d-flex align-items-center justify-content-center w-100 fs-6 p-2">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="m-2 mt-3">
                    <!-- Table Top -->
                    <div class="row">
                        <!-- Per Page -->
                        <div
                            class="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start">
                            <h6 class="text-light">{{ $t("Mostrar na tela") }}</h6>
                            <select v-model="perPage" class="form-select per-page-selector d-inline-block mx-1"
                                style="margin-bottom: 0.5rem;">
                                <option v-for=" option  in  pageOptions " :value="option" :key="option" class="text-dark">{{
                                    option }}</option>
                            </select>
                            <h6 class="text-light">{{ $t("registros") }}</h6>
                        </div>

                        <!-- Pagination -->
                        <div class="col-12 col-md-4 d-flex align-items-center justify-content-center">
                            <nav aria-label="Pagination">
                                <ul class="pagination mb-0 mt-1 mt-sm-0">
                                    <li class="page-item">
                                        <a class="page-link" href="#" @click.prevent.stop="currentPage--">
                                            <i class="fa fa-arrow-left"></i>
                                        </a>
                                    </li>
                                    <li role="presentation" class="page-item active">
                                        <button role="menuitemradio" type="button" aria-label="Go to page 1"
                                            aria-checked="true" aria-posinset="1" aria-setsize="1" tabindex="0"
                                            class="page-link">{{ currentPage }}</button>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" @click.prevent.stop="currentPage++">
                                            <i class="fa fa-arrow-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref } from "vue";
import Numeral from "numeral"
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";
import { debounce } from "debounce";
//import NewTicket from "./NewTicket.vue";
//import TicketResponse from "./TicketResponse.vue";

export default {
    components: {
        //NewTicket,
        //TicketResponse,
    },
    directives: {
    },
    data() {
        return {
            supportModule: null,
            perPage: 10,
            pageOptions: [10, 25, 50],
            totalRows: 0,
            currentPage: 1,
            entriesTo: 0,
            entriesFrom: 0,
            searchQuery: "",
            API_URL: process.env.VUE_APP_API_URL,
            usernameToDisable: "",
            reasonDisable: "",
            ticketStage: 'list',
            idTicket: '',
            loadinPage: false,


            fields: [
                { key: "_id", label: null },
                { key: "subject", label: null },
                { key: "category", label: null },
                { key: "status", label: null },
                { key: "createdAt", label: null },
                { key: "updatedAt", label: null },
            ],
            items: [],
            params: {
                page: this.currentPage,
                limit: this.perPage,
                search: "",
                sortby: "createdAt",
                sortdirection: "desc",
            },
            sortByFilter: null,
            sortByOptions: [],
            sortDirectionFilter: null,
            sortDirectionOptions: [],

            openModalDeposit: false,
            returnTicketsList: null,
        };
    },
    computed: {
    },
    async mounted() {

        this.renderTicketsList();

        this.fields[0].label = this.$i18n.t("Ticket");
        this.fields[1].label = this.$i18n.t("Assunto");
        this.fields[2].label = this.$i18n.t("Categoria");
        this.fields[3].label = this.$i18n.t("Status");
        this.fields[4].label = this.$i18n.t("Abertura");
        this.fields[5].label = this.$i18n.t("Encerramento");

        this.sortByOptions = [
            { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
            { label: this.$i18n.t("E-mail"), value: "email" },
            { label: this.$i18n.t("Nome completo"), value: "fullname" },
            { label: this.$i18n.t("Saldo"), value: "balance" },
            { label: this.$i18n.t("Status"), value: "status" },
            { label: this.$i18n.t("Usuário"), value: "username" },
        ];

        this.sortDirectionOptions = [
            { label: this.$i18n.t("Crescente"), value: "asc" },
            { label: this.$i18n.t("Decrescente"), value: "desc" },
        ];
    },
    watch: {
        returnLocale: function (oldVal, newVal) {
            this.fields[0].label = this.$i18n.t("ID do chamado");
            this.fields[1].label = this.$i18n.t("Assunto");
            this.fields[2].label = this.$i18n.t("Categoria");
            this.fields[3].label = this.$i18n.t("Status");
            this.fields[4].label = this.$i18n.t("Data de abertura");
            this.fields[5].label = this.$i18n.t("Data de encerramento");

            this.sortByOptions = [
                { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
                { label: this.$i18n.t("E-mail"), value: "email" },
                { label: this.$i18n.t("Nome completo"), value: "fullname" },
                { label: this.$i18n.t("Saldo"), value: "balance" },
                { label: this.$i18n.t("Status"), value: "status" },
                { label: this.$i18n.t("Usuário"), value: "username" },
            ];

            this.sortDirectionOptions = [
                { label: this.$i18n.t("Crescente"), value: "asc" },
                { label: this.$i18n.t("Decrescente"), value: "desc" },
            ];
        },
        returnTicketsList: function (newVal) {
            if (newVal) {
                this.totalRows = newVal.total;
                this.entriesTo =
                    newVal.perPage * newVal.currentPage > newVal.total
                        ? newVal.total
                        : newVal.perPage * newVal.currentPage;
                this.entriesFrom =
                    newVal.perPage * newVal.currentPage + 1 - newVal.perPage;
                this.items = newVal.ticketList;
            }

        },
        perPage: function (newVal) {
            this.params.limit = newVal;
            this.renderTicketsList();
        },
        currentPage: function (newVal) {
            this.params.page = newVal;
            this.renderTicketsList();
        },
        searchQuery: debounce(function (newVal) {
            this.params.search = newVal;
            this.renderTicketsList();
        }, 500),
        statusFilter: function (newVal) {
            this.params.status = newVal;
            this.renderTicketsList();
        },
        sortByFilter: function (newVal) {
            this.params.sortby = newVal;
            this.renderTicketsList();
        },
        sortDirectionFilter: function (newVal) {
            this.params.sortdirection = newVal;
            this.renderTicketsList();
        },
    },
    methods: {
        ...mapActions(["usersList", "sendEmailAPI", "disableUserAPI"]),
        renderImgAvatar(img) {
            if (img)
                return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
        },

        userMaxRole(array) {
            let filtered = array.map((res) => res.role_id);
            return array.find((res) => res.role_id == Math.min(...filtered)).name;
        },

        renderTicketsList() {
            if (!this.loadinPage) {
                this.loadinPage = true;
                this.$store.dispatch("getTicketsList", this.params)
                    .then(resp => {
                        //console.log(resp);
                        this.returnTicketsList = resp?.[0] ? resp?.[0] : resp
                        //console.log(resp);
                        this.loadinPage = false;
                    })
                    .catch(error => {
                        this.loadinPage = false;
                    });
            }
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();

            this.$refs.disableUser.validate().then((success) => {
                if (success) {
                    this.handleSubmit();
                }
            });
        },

        handleSubmit() {
            const data = {
                id: this.usernameToDisable._id,
                status:
                    this.usernameToDisable.status == "active" ? "inactive" : "active",
                description: this.reasonDisable,
            };
            this.disableUserAPI(data)
                .then((response) => {
                    this.$bvModal.hide("modal-disable-user");
                    this.renderTicketsList();
                    /*        this.$toast({
                               component: ToastificationContent,
                               position: "top-right",
                               props: {
                                   icon: "CoffeeIcon",
                                   variant:
                                       this.usernameToDisable.status == "active"
                                           ? "danger"
                                           : "success",
                                   title:
                                       this.usernameToDisable.status == "active"
                                           ? this.$i18n.t("Usuário desativado!")
                                           : this.$i18n.t("Usuário ativado!"),
                               },
                           }); */
                    //console.log('aqui seria alerta');
                    this.reasonDisable = "";
                })
                .catch((err) => {
                    /** */
                });
        },

    },
    setup() {
        format;

        const isAddNewUserSidebarActive = ref(false);

        const resolveUserRoleVariant = (role) => {
            if (role === "customer") return "primary";
            if (role === "employee_store") return "warning";
            if (role === "seller") return "success";
            if (role === "employee_adm") return "info";
            if (role === "administrator") return "danger";
            return "primary";
        };

        const resolveUserRoleIcon = (role) => {
            if (role === "customer") return "UserIcon";
            if (role === "employee_store") return "SettingsIcon";
            if (role === "seller") return "DatabaseIcon";
            if (role === "employee_adm") return "Edit2Icon";
            if (role === "administrator") return "ServerIcon";
            return "UserIcon";
        };

        const resolveUserStatusVariant = (status) => {

            if (status === "Open") return "warning";
            if (status === "active") return "success";
            if (status === "Closed") return "secondary";
            return "primary";
        };

        return {
            // Sidebar
            isAddNewUserSidebarActive,

            // Utilis
            format,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
  
<style lang="scss">
.limitString {
    /* Sem restrições específicas para desktop */
}

/* Estilo para dispositivos móveis */
@media (max-width: 768px) {
    .limitString {
        white-space: nowrap;
        max-width: 16rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>