<template>
    <div>
        <!--       <Breadcrumbs :title="{
            name: 'Home',
        }" /> -->
        <div class="pt-4 pt-md-0">

            <div v-if="licensesCloseToExpiration > 0"
                class="alert alert-warning d-flex align-items-center justify-content-center p-1 mb-1 text-center" role="alert">
                <span class="fs-6">
                    <div class="whatsnew-btn font-size-13">
                        {{ $t("Aviso: Existe") }} {{ licensesCloseToExpiration }}
                        {{ $t("licença(s) expirando em menos de 6 dias.") }}
                        <router-link :to="{ name: 'Licenses' }" class="btn btn-outline-white font-size-13">
                            {{ $t('Licenças') }}
                        </router-link>
                    </div>
                </span>
            </div>
            <div v-if="licenseListExpired > 0"
                class="alert alert-danger d-flex align-items-center justify-content-center p-1 mt-0" role="alert">
                <span class="fs-6">
                    <div class="whatsnew-btn font-size-13">
                        {{ $t("Aviso: Existe") }} {{ licenseListExpired }} {{ $t("licença(s) expirada!") }}
                        <router-link :to="{ name: 'Licenses' }" class="btn btn-outline-white font-size-13">
                            {{ $t('Licenças') }}
                        </router-link>
                    </div>
                </span>
            </div>
        </div>
        <div v-if="loadingPage" class="text-center mt-5">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="!loadingPage" class="container-fluid">

            <div class="row widget-grid">
                <WelcomeCard />
                <PurchaseSaleCard :dashboardMyResults="dashboardMyResults"
                    @getDashboardMyResults="getDashboardMyResults()" />
                <div class="mb-3 text-center">
                    <div>
                        <div class="p-1">
                            <div class="card-header mb-1">
                                <div>
                                    <h4 class="card-title">{{ $t("Convide um amigo") }}</h4>
                                </div>
                            </div>
                            <div class="">
                                <div class="meetup-header d-flex align-items-center justify-content-center">
                                    <div class="d-flex my-auto">
                                        <div v-if="referenceLink" class="text-center">
                                            <p class="fs-6 mb-0 text-theme" style="margin-top: 0.5rem; font-size: 1.1rem;">
                                                {{ referenceLink }}
                                            </p>
                                            <textarea ref="copyTextarea" hidden></textarea>
                                            <span class="d-flex align-items-center justify-content-center">
                                                <button type="button" @click="copyReferenceLink()"
                                                    class=" d-flex d-lg-none btn btn-success">
                                                    <span style="color: white;"> Copiar meu link de convite</span>
                                                </button>
                                            </span>

                                        </div>
                                        <div @click="copyReferenceLink()" class="d-none d-lg-flex ml-2 pointer mt-0"
                                            data-bs-toggle="tooltip" data-bs-title="Copiar"
                                            style="margin-left: 14px; width: 40px; height: 40px;"><img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUBSURBVHgBxVlNbBtVEJ633gok7MQttKkSpDqtQ5OSUCPRH9FDNj8FTuSn4kTaBrjQChFQRVpBUQOFKm1VEQQULqA0pNeEcoGUxtkcQEpBwqkjApKNjZRUDRXBtcOp9j5mnmPLaXa9a9d2Punprd/O7n47Mzszb8wgT7gbmhoZlzwcQGEMPLjkXB6ECOcQxhkH9wHjasA/MQl5gOUi7HIpTtku9TDG3sggYxE8rGmg4ugLzXr/snqVJYKCmEM6xUAQu29oGh+0StRmJlBT39Jje0D6BskpUCCgBTySBO3rN26J/Hs7PA35EnQ3NH+EN+vDwweh4GBOxqT2DRurnYu3Q2OGUnqLSV+zjaLzK1AC4Afli8uJprBPjdx7TtK7AMlNlIocgaKAHLeN6p1bZeJls7ZDiYEkXXrmXkHwsceVw8CkflgjoGL2Prxpa2Tx79BUei11UFuruBLrpAlcckGO2P3UTmhtfhrqtrt1z49cGYPRb6+CFWDgj8RtieqUP6Z9MGGTTuVD7rUjh2D4qwtwoO05MtOqUV5mh7Mf9IL3u2GoqqwwvR9e4pTvEpf075T2bCHIEfTAie8vo4auwofnLkIstqQr19n2LLzTewTuRJfg4CvHYP7mgum979oS60mLQoNJ7eWO7q4DEEVSn3w+ZEiOQCbuevmY0ObXX16wpEk5LvXQnDSxZD2k0M1bmvZBB2qFfC8W+w9279opjrNh9o9gmuRnA++bPyiZ74G5G/Y3MtBUM3ki1n+6F/bsMiYyN38LBodHYOjyiKEMvcwwapFc4tKwsRyBg6bIwBOK8GYTkGnKyxzCnNe8P64yaVXVZjj8YiecPH4Uyhx2+PSLId37XP95WvggadyMIJVzMs4eMEHH88/Ao0ig7YVX0VQBXZk5fOgUPpy0/PrRQ2LNiOTczVtQji9hBo7ZTMYqxWUmuL95n/AhI3KZOPHuOTGbkbQCBhw1iCnGTNCBjh2LLoFVZJK8/su0GPmAY8VDX3GOlbE1nFmOi62o/XxBQVuCIoHi49z8AuzYvg3uB0QwAkVCdMm6W+iB8rLEikjQCGV2O9yJmZNnnIUljeO2sIRoxSxUV7sNxid+MpXljIcpzKh4XLQClSJAKg3S3H2wUwRqys/mDLlP5kzyYaqDYqEOPxIqx1KgkHP85HmLV2uTcsD/w2RNQwv5oWG4oRhIqSwfzP4ehDPnL4rj3/A4FrP84YQDM6oqwgznfCCbJJmEigWHhfSUAsnvQZNOocYoBdLIgRzZVKVZEIzL2sfZhMlfqADoP/2WpVrO4XhI5GSCWUFgSFDmfTSnyxixm8vS2qDi9G2sigmkDWNydiwsKsQLWSmpdMkBDAb94y+tIOjyKM51CVH2G/piVSWWVF2dsKPWODtE0V+psiFi81i15IFwXOZK6Ndk32ZFIVjzREsPhu8BWENoDLqDN8YvpX6v2BcvLoSmNlRUO9HUe2ENwIEPBP3es5lruqW0u76lpK0PAvVnAjPjT967rlvNYCOnA1+nlClQpeaR3omsmxGzL7sQILMG/N43jc5n7Q9ij2Tskc1bwwDpHnQhEUF2JwIz3veyCZl2WP9ZCE1vqtxyhXOJCJpusKyAtBa3aR1/3jBvrOfURK/1YIskYaMuhILDBbkhQimVspZeoxIKQTAT7vpWBaNWo4T9Zk4bLy4Ip/+GwChAJvThOZVrbDowc02FPPA/eBXjLFpYMJYAAAAASUVORK5CYII="
                                                alt="robot" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OverallBalanceCard :dashboardMyResults="dashboardMyResults" />
                <RecentOrdersCard :dashboardMyResults="dashboardMyResults" />

            </div>
        </div>

    </div>
</template>

<script>
import WelcomeCard from "./default/WelcomeCard.vue";
import PurchaseSaleCard from "./default/PurchaseSaleCard.vue";
import OverallBalanceCard from "./default/OverallBalanceCard.vue";
import RecentOrdersCard from "./default/RecentOrdersCard.vue";

import copy from "copy-text-to-clipboard";
import { mapGetters } from "vuex";
const { differenceInDays, parseISO } = require('date-fns');

export default {
    components: {
        WelcomeCard,
        PurchaseSaleCard,
        OverallBalanceCard,
        RecentOrdersCard,
    },

    data() {
        return {
            copyTextarea: null,
            referenceLink: "",
            dashboardMyResults: null,
            loadingPage: true,
            licenseListExpired: 0,
            licensesCloseToExpiration: 0,
        }
    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    mounted() {

        this.referenceLink =
            process.env.VUE_APP_FRONT_URL + "/ref/" + this.returnUser?.username;
        this.getDashboardMyResults();


    },
    methods: {
        copyReferenceLink() {
            try {
                let copyText = this.referenceLink;
                copy(copyText);

                //const toast = new Toast(document.getElementById("copyLink"));
                //toast.show();
                this.$toast.show(this.$t('Link copiado!'), {
                    theme: 'outline', position: 'top-right', type: 'info', duration: 2000
                });
            } catch (error) {
                console.error('Falha ao copiar o link:', error);
            }
        },
        getDashboardMyResults() {
            this.$store.dispatch("dashboardMyResults")
                .then(async (response) => {
                    this.dashboardMyResults = response?.myResults;
                    this.loadingPage = false;
                    this.licenseTestExpired(response)

                }).catch((error) => {
                    this.loadingPage = false;
                });
        },
        licenseTestExpired(licensesList) {
            if (licensesList?.myResults?.arrayRobots) {

                const currentDate = new Date(licensesList?.myResults.currentDateServer);

                for (const license of licensesList?.myResults?.arrayRobots) {
                    const endDate = new Date(license.endDate);
                    const daysUntilExpiration = differenceInDays(endDate, currentDate);
                    console.log(daysUntilExpiration);
                    if (daysUntilExpiration <= 6 && daysUntilExpiration > 0) {//6 days
                        this.licensesCloseToExpiration += 1;
                        console.log(`Aviso: A licença expira em menos de 6 dias (${daysUntilExpiration} dias restantes)`);
                        // Faça o que precisa ser feito quando estiver próximo de expirar
                    }
                    if (daysUntilExpiration <= 0) {
                        this.licenseListExpired += 1;
                        console.log(`Aviso: A licença expirou (${daysUntilExpiration} dias restantes)`);
                        // Faça o que precisa ser feito quando expirar
                    }

                }
            }
        }
    }
};
</script>
