<template>
    <div>
        <div class="page-wrapper">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="login-card">
                            <div>
                                <div>
                                    <a class=" d-flex justify-content-center text-center">
                                        <img class="for-light" src="../../assets/images/logo/logo.png" alt="looginpage"
                                            style="width: 300px !important;" />
                                        <img class="for-dark" src="../../assets/images/logo/logo_dark.png" alt="looginpage"
                                            style="width: 300px !important;" />
                                    </a>
                                </div>
                                <div class="login-main bg-dark">
                                    <form v-on:submit.prevent="recoveryPassword()" name="login_form" class="form-align">
                                        <h4>{{ $t("Recupere seu acesso") }}</h4>
                                        <div class="form-group">
                                            <label class="col-form-label">{{ $t("Endereço de email") }}</label>
                                            <input class="form-control" type="email" required="" placeholder=""
                                                v-model="email" :disabled="isLoading">
                                            <div v-if="emailError || apiErrors.email" class="validate-error text-danger">
                                                {{ emailError || apiErrors.email[0] }}
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col-12 col-md-4 text-center">
                                                <router-link class="" tag="a" :to="{ name: 'login' }">
                                                    {{ $t("Voltar") }}
                                                </router-link>
                                            </div>
                                            <div class="col-12 col-md-8 text-end p-2 p-md-0">
                                                <button type="submit" class="btn btn-primary btn-block w-100"
                                                    :disabled="isLoading">
                                                    <span v-if="isLoading">{{ $t("Aguarde...") }}</span>
                                                    <span v-else>{{ $t("Enviar a solicitação") }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div v-if="emailSent" class="alert alert-success mt-4 text-center" role="alert">
                                            <span class="mb-1 fs-6">
                                                {{ apiResponseForgotPassword }}</span>
                                            <p class="card-text mb-2 text-center fs-6"> 
                                                {{ $t("Confira em sua caixa entrada.") }}
                                            </p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: 'reset_password',
    data() {
        return {
            active: true,
            email: '',
            emailError: '',
            apiErrors: {},
            isLoading: false,
            emailSent: false,
            apiResponseForgotPassword: "",
        }
    },
    methods: {
        ...mapActions(["forgotPassword"]),
        show() {
            this.active = !this.active
        },
        validEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        },
        recoveryPassword() {
            this.isLoading = true;
            this.apiErrors = {}

            const cleanedEmail = this.email.trim();

            if (!this.validEmail(cleanedEmail)) {
                this.emailError = this.$t('Por favor, insira um e-mail válido.');
                this.isLoading = false;
                return;
            } else {
                this.emailError = '';
            }

            let credentials = {
                email: cleanedEmail.toLowerCase(),
            }
            this.forgotPassword(credentials)
                .then((response) => {
                    this.isLoading = false;
                    this.emailSent = true;
                    this.apiResponseForgotPassword = response.message;
                    setTimeout(() => {
                        //this.emailSent = false;
                    }, 5000);
                })
                .catch((error) => {

                    if (error?.openModal2fa) {
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        if (error?.errors) {
                            this.apiErrors = error?.errors
                        } else if (error?.message) {
                            this.apiErrors.message = error?.message
                        } else {
                            this.apiErrors.message = this.$t('Ocorreu um erro ao tentar recuperar sua senha.')
                        }

                    }
                });

        }
    },

}
</script>
