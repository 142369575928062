<script>
import Numeral from "numeral";
import { Toast } from 'bootstrap';
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            cryptoSelected: "USDT.bep20",
            cryptoOptions: [
                { text: "BNB", value: "BNB.BSC" },
                { text: "USDT", value: "USDT.bep20" },
            ],
            showAlert: false,
            textAlert: "",
            colorAlert: "alert-warning",
            createDeposit: {},
            depositValue: 0,
            depositCrypto: "",
            loadingDepositGen: false
        };
    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    methods: {
        async generateDeposit() {

            const data = {
                value: this.depositValue,
                crypto: this.cryptoSelected,
            };
            this.loadingDepositGen = true;

            this.$store
                .dispatch("userCreateDeposit", data)
                .then((resp) => {
                    this.createDeposit = resp.createDeposit;
                    this.depositValue = resp.value;
                    this.depositCrypto = resp.crypto;
                    this.loadingDepositGen = false;
                    //$emit("refreshListDeposits");
                })
                .catch((error) => {
                    this.loadingDepositGen = false;
                    this.showAlert = true;
                    this.colorAlert = "alert-warning";
                    this.textAlert = error.message;
                    setTimeout(() => {
                        this.showAlert = false;
                        this.textAlert = "";
                    }, 5000);
                });
        },
        async copyCryptoValue(cryptoValue) {
            await navigator.clipboard.writeText(cryptoValue);

            const toast = new Toast(document.getElementById("copyCryptoValue"));
            toast.show();
        },
        async copyCryptoAddress(cryptoAddress) {
            await navigator.clipboard.writeText(cryptoAddress);
            const toast = new Toast(document.getElementById("copyCodeCrypto"));
            toast.show();
        },
    }
};
</script>
<template>
    <div>

        <div v-if="returnUser" class="row mt-5">

            <div class="col-sm-6">

                <div> {{ $t("Digite o valor em dólar do depósito") }}: </div>
                <input id="allocation-value" name="allocation-value" type="number" placeholder="$0.00"
                    class="mt-2 form-control" v-model="depositValue" :class="{
                        'is-invalid': (depositValue < returnUser.config.limitMinCPDeposit && depositValue > 0) ||
                            (depositValue > returnUser.config.limitMaxCPDeposit && depositValue > 0)
                    }">
                <div class="mb-1">
                    <span v-if="depositValue < returnUser.config.limitMinCPDeposit &&
                        depositValue > 0
                        " class="text-danger">{{ $t("O valor mínimo para depósito é de") }} {{
        returnUser.config.limitMinCPDeposit
    }}
                        USD</span>
                    <span v-if="depositValue > returnUser.config.limitMaxCPDeposit &&
                            depositValue > 0
                            " class="text-danger">{{ $t("O valor máximo para depósito é de") }} {{
            returnUser.config.limitMaxCPDeposit
        }}
                        USD</span>
                </div>

                <div class="my-2">
                    {{ $t(`Você receberá de saldo`) }} <span class="text-primary"
                        style="font-size: 1.1rem; font-weight: bold;">{{
                            moneyFormatUSDT(depositValue) }}</span> <br>
                </div>
                <fieldset class="form-group">
                    <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
                        {{ $t("Selecione a criptomoeda") }}:
                    </legend>
                    <div class="form-group mb-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" name="default_radio" type="radio" id="inlineRadio1" value="BNB.BSC"
                                v-model="cryptoSelected">
                            <label class=" form-check-label" for="inlineRadio1">BNB.BSC </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" name="default_radio" type="radio" id="inlineRadio2"
                                value="USDT.bep20" v-model="cryptoSelected">
                            <label class="form-check-label" for="inlineRadio2">USDT (bep20)</label>
                        </div>
                    </div>

                </fieldset>
                <div class="d-flex justify-content-center mt-2">
                    <button type="button" class="purchase-btn btn btn-primary btn-hover-effect f-w-500"
                        @click="generateDeposit" :disabled="depositValue < returnUser.config.limitMinCPDeposit ||
                            depositValue > returnUser.config.limitMaxCPDeposit || loadingDepositGen">
                        <span role="status" v-show="loadingDepositGen" class="spinner-grow spinner-grow-sm">
                            <span class="sr-only">Small Spinner</span>
                        </span>
                        {{ $t(`Gerar endereço para depósito`) }}</button>
                </div>
                <div class="mt-2" v-show="showAlert">
                    <div class="demo-spacing-0">
                        <div class="alert" :class="colorAlert">
                            <span>{{ textAlert }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-left border-dashed col-sm-6">
                <div v-if="Object.keys(createDeposit).length !== 0" class="text-center mt-3 mt-sm-0">
                    <h4 class="text-theme">
                        <b>{{ $t("QRCode gerado com sucesso!") }}</b>
                    </h4>
                    <div>{{ $t("Envie exatamente este valor") }}:</div>
                    <div class="d-flex flex-column my-1 cursor-pointer">
                        <strong style="font-size: 1.3rem">
                            {{ parseFloat(createDeposit.amount).toFixed((depositCrypto == 'BTC' || depositCrypto ==
                                'ETH') ? 8
                                : 2) }} {{ depositCrypto }}
                        </strong>
                        <!-- <small class="text-muted">
                            {{ $t("clique para copiar o valor") }}
                        </small> -->
                    </div>
                    <img :src="createDeposit.qrcode_url" alt="QRCODE" class="" style="width: 200px; height: 200px;">

                    <div class="d-flex flex-column my-1 cursor-pointer">
                        <small>
                            <strong>
                                {{ createDeposit.address }}
                            </strong>
                        </small>
                        <button type="button" @click="copyCryptoAddress(createDeposit.address)"
                            class="mt-3 purchase-btn btn btn-primary btn-hover-effect f-w-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-copy">
                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                            </svg>
                            {{ $t("Copiar endereço da carteira") }}
                        </button>
                    </div>

                </div>
                <div v-else class="d-flex justify-content-center mt-3 mt-sm-0">
                    <img src="@/assets/img/operation-img.png" alt="Login V2" class="" style="width: 14rem;">
                </div>
                <div class="toasts-container">
                    <div class="toast fade hide mb-3 bg-success" data-autohide="false" id="copyCodeCrypto">

                        <div class="text-white toast-body">
                            {{
                                createDeposit ? createDeposit.address : ''
                            }}
                            {{ $t("esse endereço foi copiado!") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div></template>