<template>
<div class="people-list">
    <div class="search">
        <form class="theme-form">
            <div class="form-group">
                <input class="form-control" type="text" placeholder="Write Status..." /><i class="fa fa-pencil"></i>
            </div>
        </form>
    </div>
</div>
<div class="status">
    <p class="font-primary f-w-600">Active</p>
    <hr />
    <p>
        Established fact that a reader will be distracted
        <i class="icofont icofont-emo-heart-eyes font-danger f-20"></i><i class="icofont icofont-emo-heart-eyes font-danger f-20 m-l-5"></i>
    </p>
    <hr />
    <p>
        Dolore magna aliqua
        <i class="icofont icofont-emo-rolling-eyes font-success f-20"></i>
    </p>
</div>
</template>
