<template>
    <div>
        <div class="page-wrapper">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="login-card">
                            <div>
                                <div>
                                    <a class=" d-flex justify-content-center text-center">
                                        <img class="for-light" src="../../assets/images/logo/logo.png" alt="looginpage"
                                            style="width: 300px !important;" />
                                        <img class="for-dark" src="../../assets/images/logo/logo_dark.png" alt="looginpage"
                                            style="width: 300px !important;" />
                                    </a>
                                </div>
                                <div class="login-main bg-dark">
                                    <div v-if="invalidSignature == false && !passwordChanged">
                                        <form @submit.prevent="changePassword" name="login_form" class="form-align">
                                            <h4>{{ $t("Redefinir sua senha") }}</h4>
                                            <h6 class="mt-4">{{ $t("Crie sua senha") }}</h6>
                                            <div class="form-group">
                                                <label class="col-form-label">{{ $t("Nova Senha") }}</label>
                                                <div class="form-input position-relative">
                                                    <input class="form-control" :type="active ? 'password' : 'text'"
                                                        v-model.trim="password" placeholder="*********"
                                                        :disabled="isLoading" />
                                                    <div class="show-hide">
                                                        <span :class="active ? 'show' : 'hide'"
                                                            @click.prevent="show"></span>
                                                    </div>
                                                </div>
                                                <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-form-label">{{ $t("Repita a senha") }}</label>
                                                <input class="form-control" type="password" v-model.trim="cPassword"
                                                    placeholder="*********" :disabled="isLoading" />
                                                <div v-if="errors.cPassword" class="text-danger">{{ errors.cPassword }}
                                                </div>
                                            </div>
                                            <div class="row mt-4">
                                                <div class="col-12 text-end p-2 p-md-0">
                                                    <button type="submit" class="btn btn-primary btn-block w-100"
                                                        :disabled="isLoading">
                                                        <span v-if="isLoading">{{ $t("Aguarde...") }}</span>
                                                        <span v-else>{{ $t("Salvar nova senha") }}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div v-if="invalidSignature == true && !passwordChanged" class="mt-4 text-center">
                                        <h4 class="card-title mb-1 text-danger">
                                            {{ $t("Link para alteração de senha inválido!") }}
                                        </h4>
                                        <p class="card-text mb-2"> {{ $t("Esse link para a alteração da sua senha não é ")
                                        }}
                                            {{ $t("mais válido, confira em sua caixa de entrada se não recebeu ") }}
                                            {{ $t("um link atualizado ou entre em contato com o suporte.") }}

                                        </p>
                                    </div>
                                    <div v-if="passwordChanged" class="alert alert-success mt-4 text-center" role="alert">
                                        <p class="card-text mb-2 text-center fs-5"> {{ $t("Senha alterada com sucesso!") }}
                                        </p>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-12 col-md-4 text-center p-1">
                                        </div>
                                        <div class="col-12 col-md-8 text-end p-2 p-md-0">
                                            <router-link class="" tag="a" :to="{ name: 'login' }">
                                                {{ $t("Voltar para o login") }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import * as yup from 'yup';

export default {
    data() {
        return {
            active: true,
            isLoading: false,
            cPassword: '',
            password: '',
            errors: {
                password: '',
                cPassword: '',
            },
            invalidSignature: true,
            passwordChanged: false,
        }
    },
    mounted() {

        this.$store
            .dispatch("verifySignature", this.$route?.query?.token)
            .then((response) => {
                this.invalidSignature = false;
            })
            .catch((err) => {
                this.invalidSignature = true;
            });
    },
    watch: {
        password(newValue) {
            if (newValue) {
                this.errors.password = '';
            }
        },
        cPassword(newValue) {
            if (newValue) {
                this.errors.cPassword = '';
            }
        }
    },
    methods: {
        ...mapActions(["redefinePassword"]),
        show() {
            this.active = !this.active;
        },
        changePassword() {
            const schema = yup.object().shape({
                password: yup
                    .string()
                    .min(6, this.$t("A senha deve ter no mínimo 6 caracteres"))
                    .max(100, this.$t("A senha deve ter no máximo 100 caracteres"))
                    .required(this.$t("Senha é obrigatória")),
                cPassword: yup
                    .string()
                    .required(this.$t("Confirmação de senha é obrigatória"))
                    .oneOf([yup.ref("password")], this.$t("As senhas devem ser iguais")),
            });

            schema
                .validate({ password: this.password, cPassword: this.cPassword }, { abortEarly: false })
                .then(() => {
                    this.isLoading = true;

                    this.errors.password = '';
                    this.errors.cPassword = '';

                    const data = {
                        password: this.password,
                        password_confirmation: this.cPassword,
                        token: this.$route?.query?.token,
                    };

                    this.redefinePassword(data)
                        .then((response) => {
                            this.passwordChanged = true;
                            this.isLoading = false;
                        })
                        .catch((error) => {

                            this.isLoading = false;
                            this.errors.password = '';
                            this.errors.cPassword = '';
                            error.inner.forEach((e) => {
                                this.errors[e.path] = e.message;
                            });
                        });
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.errors.password = '';
                    this.errors.cPassword = '';
                    if (error.inner) {
                        error.inner.forEach((e) => {
                            this.errors[e.path] = e.message;
                        });
                    }
                });
        },
    },
};
</script>
