import api from "@/libs/axios";

const dashboard = {
    state: {
        openModalWithdrawRequestInstance: null,
    },
    getters: {
        returnModalWithdrawRequestInstance: state => state.openModalWithdrawRequestInstance,
    },
    actions: {
        openModalWithdrawRequest({ commit }, instance) {
            //console.log(instance);
            commit("addModalWithdrawRequest", instance);
        },
    },
    mutations: {
        addModalWithdrawRequest: (state, instance) => {
            state.openModalWithdrawRequestInstance = instance
        },
    },
}

export default dashboard;