<template>
    <div>
        <Breadcrumbs :title="$t('Withdrawals List Pix')" :main="$t('Wallet')" />
        <div v-if="loadingPage" class="text-center mt-5">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="!loadingPage">
            <div class="card mb-0 p-2">
                <div class="m-2 mt-3">
                    <MegaTable :tableColumns="tableColumns" :loading="loading" :tableRows="tableRows"
                        @fetch-tablerows="(params) => fetchListGeneral(params)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MegaTable from '@/components/MegaTable.vue';
import Numeral from "numeral";

export default {
    components: {
        MegaTable
    },
    data() {
        return {
            tableColumns: [
                { label: "VALOR USDT", field: "valueUSD" },
                { label: "VALOR BRL", field: "valueBRL" },
                { label: "CONTA PIX", field: "pixAccount" },
                { label: "STATUS", field: "status" },
                { label: "DATA", field: "createdAt" }
            ],
            tableRows: null,
            loading: false,
            loadingPage: true,
        };
    },
    methods: {
        async fetchListGeneral(providedParams) {
            this.loading = true;

            const params = {
                page: providedParams ? providedParams.page || 1 : 1,
                limit: providedParams ? providedParams.limit || 10 : 10,
                search: providedParams ? providedParams.search || '' : ''
            };
            this.$store
                .dispatch("userWithdrawsListPIX", params)
                .then((resp) => {
                    resp.withdrawList.map((item) => {
                        item.valueUSD = this.moneyFormat(item.valueUSD);
                        item.valueBRL = item.valueBRL.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL"
                        });
                        return item;
                    });

                    this.tableRows = {
                        currentPage: resp.currentPage,
                        total: resp.total,
                        referenceList: resp.withdrawList,
                        perPage: resp.perPage
                    };
                    this.loadingPage = false;
                }).catch((error) => {
                    // Trate os erros aqui
                    this.loadingPage = false;
                });

            this.loading = false;
        }
    },
    created() {
        this.fetchListGeneral();
    }
};
</script>
 
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
  
<style lang="scss">
.limitString {
    /* Sem restrições específicas para desktop */
}

/* Estilo para dispositivos móveis */
@media (max-width: 768px) {
    .limitString {
        white-space: nowrap;
        max-width: 16rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>