import api from "@/libs/axios";

const authentication = {
  state: {
    user: [],
    userLocale: localStorage.getItem("currentLanguage") || "es",
  },
  getters: {
    returnUser: (state) => state.user,
    returnLocale: (state) => state.userLocale,
  },
  actions: {
    verifyConnectionServer: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    authenticateUser: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/auth/login", data)
          .then((response) => {
            localStorage.setItem('lifemoneyToken', response?.data.token);

            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    recoverUserData: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/me")
          .then((response) => {
            response?.data.user.roles.map((e) => {
              e.permissions.map((el) => {

                response?.data?.user?.roles.push({
                  action: "read",
                  subject: el.name,
                });
              });
            });
            response?.data?.user?.roles.push({
              action: "read",
              subject: "Public",
            });
            commit("addUser", { dataUser: response?.data?.user });

            const token = localStorage.getItem('lifemoneyToken')
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data?.message);
          });
      });
    },
    userLogout: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/auth/logout")
          .then((response) => {
            localStorage.removeItem('lifemoneyToken');
            commit("logout", []);
            resolve(response);
          })
          .catch((error) => {

            reject(error.response?.data.message);
          });
      });
    },
    changeUserLocale: ({ commit }, locale) => {
      commit("changeLocale", locale);
    },
    userRegister: ({ commit }, data) => {
      const upline = data.upline;
      delete data.upline;
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/auth/register/${upline}`, data)
          .then((response) => {
            localStorage.setItem('lifemoneyToken', response?.data.token);
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    userEditProfile: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        if (data.avatar) {
          let avatar = new FormData();
          avatar.append("avatar", data.avatar);
          await api.post("/me/upload-image", avatar);
        }
        const dataArray = data;
        if (dataArray.avatar) {
          delete dataArray.avatar;
        }
        if (dataArray) {
          await api
            .patch("/me", dataArray)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response?.data);
            });
        }
      });
    },
    forgotPassword: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/forgot-password", data)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data);
          });
      });
    },
    redefinePassword: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/redefine-password", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    newPassword: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(data.routerRedefinePsw, {
            password: data.password,
            password_confirmation: data.password_confirmation,
          })
          .then((response) => {
            localStorage.setItem('lifemoneyToken', response?.data.token);
            response?.data.users.roles.map((e) => {
              e.permissions.map((el) => {
                response?.data.users.roles.push({
                  action: "read",
                  subject: el.name,
                });
              });
            });
            response?.data.users.roles.push({
              action: "read",
              subject: "Public",
            });
            commit("addUser", { dataUser: response?.data.users });
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    verifySignature: ({ commit }, token) => {
      return new Promise(async (resolve, reject) => {
        //const token = path.split("reset-password/")[1];

        await api
          .get(`/verifySignature/${token}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    confirmEmail: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/email/verification-notification")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    confirmEmailAPI: ({ commit }, routerConfirmeEmailAPI) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(routerConfirmeEmailAPI)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    oauth: ({ commit }, token) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/oauth/callback/token/${token}`)
          .then((response) => {
            localStorage.setItem('lifemoneyToken', response?.data.token);
            response?.data.users.roles.map((e) => {
              e.permissions.map((el) => {
                response?.data.users.roles.push({
                  action: "read",
                  subject: el.name,
                });
              });
            });
            response?.data.users.roles.push({
              action: "read",
              subject: "Public",
            });
            commit("addUser", { dataUser: response?.data.users });
            resolve(response);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
    changeBalance: ({ commit }, value) => {
      commit("changeBalance", value);
    },
    verifyUpline: ({ commit }, upline) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/affiliates/verify-upline/${upline}`)
          .then((response) => {
            resolve(response?.data);
          })
          .catch((error) => {
            reject(error.response?.data.message);
          });
      });
    },
  },
  mutations: {
    addUser: (state, user) => {
      state.user = user.dataUser;
    },
    logout: (state, user) => {
      state.user = user;
    },
    changeLocale: (state, locale) => {
      state.userLocale = locale;
    },
    changeBalance: (state, value) => {
      state.user.balance = state.user.balance + value;
    },
  },
};

export default authentication;
