<template>
    <div>
        <div class="row">
            <div v-if="loadingPagePools" class="text-center mt-5">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-if="!loadingPagePools || loadingPagePoolsFirstEnd" class="col-12">
                <div v-if="poolsList.length > 0" class="row">
                    <div v-for="(poolItem, index) in poolsList" :key="index" class="col-12 col-md-12 col-lg-12 col-xl-6">
                        <div class="card widget-1">
                            <div class="row">
                                <div class="col-12 card-body d-flex align-items-center justify-content-center">
                                    <div class="text-light">
                                        <img :src="polImgCoin(poolItem.protocol.token0)" alt="usdt"
                                            style="width: 32px; margin-left: 10px; margin-top: 1px;">
                                        <img :src="polImgCoin(poolItem.protocol.token1)" alt="usdt"
                                            style="width: 32px; margin-left: 10px; margin-top: 1px;">
                                        <span class="h5" style="margin-left: 10px;">
                                            {{ poolItem.protocol.token0 }}-{{ poolItem.protocol.token1 }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 card-body">
                                    <div class="widget-content">
                                        <div class="widget-round success">
                                            <div class="bg-round"><svg class="svg-fill">
                                                    <use xlink:href="/img/icon-sprite.fa5ef2cd.svg#profit"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-18 mb-0 text-light">{{
                                                moneyFormat(poolItem.protocol.poolLiquidity) }}</p>
                                            <span class="f-light">
                                                {{ $t('Total Liquidez') }}
                                            </span>
                                        </div>
                                        <div class="w-100">
                                            <div class="border px-0 text-light rounded-2 bg-dark mt-0">
                                                <div class="row p-2">
                                                    <div class="col-12 p-1">
                                                        <img :src="polImgCoin(poolItem.protocol.token0)" alt="usdt"
                                                            style="width: 18px; margin-left: 10px; margin-top: 1px;"><span
                                                            class="font-size-8" style="margin-left: 2px;">
                                                            {{ poolItem.protocol.token0 }}
                                                        </span>
                                                        {{ moneyFormatUSDTNoName(poolItem.protocol.token0Value) }}
                                                    </div>
                                                    <hr class="mt-2 w-75 p-0 mb-0" style="margin-left: 19px;">
                                                    <div class="col-12 p-1"><img :src="polImgCoin(poolItem.protocol.token1)"
                                                            alt="bnb"
                                                            style="width: 18px; margin-left: 10px; margin-top: 1px;"><span
                                                            class="font-size-8"
                                                            style="margin-left: 5px; margin-right: 6px;">
                                                            {{ poolItem.protocol.token1 }}
                                                        </span>
                                                        {{ moneyFormatUSDTNoName(poolItem.protocol.token1Value) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12 col-md-6 card-body border-start border-dark">
                                    <div class="widget-content">
                                        <div class="widget-round warning">
                                            <div class="bg-round">
                                                <svg class="svg-fill">
                                                    <use xlink:href="/img/icon-sprite.fa5ef2cd.svg#course-1"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="w-75">
                                            <p class="font-size-18 mb-0 text-light">{{
                                                moneyFormat(poolItem.unclaimedFees.feeValue) }}</p>
                                            <span class="f-light">
                                                {{ $t('Taxas geradas') }}
                                            </span>
                                        </div>

                                        <div class="w-100">
                                            <div class="border px-0 text-light rounded-2 bg-dark mt-0">
                                                <div class="row p-2">
                                                    <div class="col-12 p-1"><img
                                                            :src="polImgCoin(poolItem.unclaimedFees.feeToken0)" alt="usdt"
                                                            style="width: 18px; margin-left: 10px; margin-top: 1px;"><span
                                                            class="font-size-8" style="margin-left: 2px;">
                                                            {{ poolItem.unclaimedFees.feeToken0 }}
                                                        </span>
                                                        {{ moneyFormatUSDTNoName(poolItem.unclaimedFees.feeToken0Value) }}
                                                    </div>
                                                    <hr class="mt-2 w-75 p-0 mb-0" style="margin-left: 19px;">
                                                    <div class="col-12 p-1"><img
                                                            :src="polImgCoin(poolItem.unclaimedFees.feeToken1)" alt="bnb"
                                                            style="width: 18px; margin-left: 10px; margin-top: 1px;"><span
                                                            class="font-size-8"
                                                            style="margin-left: 5px; margin-right: 6px;">
                                                            {{ poolItem.unclaimedFees.feeToken1 }}
                                                        </span>
                                                        {{ moneyFormatUSDTNoName(poolItem.unclaimedFees.feeToken1Value) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- <hr class="border-top m-0"> -->
                            <div class="p-1">
                                <div class="border px-0 text-light rounded-2 bg-dark mt-0">
                                    <div class="rounded">
                                        <span class="d-flex align-items-center justify-content-center">
                                            <a :href="poolItem.urlPool" target="_blank" class="text-white ml-1">
                                                <span class="d-md-block d-none p-1">
                                                    {{ poolItem.urlPool }}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-box-arrow-up-right ml-1"
                                                        viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd"
                                                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                                        <path fill-rule="evenodd"
                                                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                                    </svg>
                                                </span>
                                                <span class="d-block d-md-none p-1">
                                                    {{ $t("Ir para Pool") }}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-box-arrow-up-right ml-1"
                                                        viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd"
                                                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5" />
                                                        <path fill-rule="evenodd"
                                                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z" />
                                                    </svg>
                                                </span>

                                            </a>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="poolsList.length == 0" class="col-12 text-center mt-5">

                    <div class="alert alert-warning d-flex align-items-center justify-content-center p-1 mt-0" role="alert">
                        <span class="fs-6">
                            <div class="whatsnew-btn font-size-18 font-weight-bolder">
                                {{ $t("Nenhuma pool criada devido a tendência de queda do preço!") }}

                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    components: {
    },
    data() {
        return {
            poolsList: [],
            loadingPagePools: false,
            loadingPagePoolsFirstEnd: false,
        };
    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    created() {
    },
    mounted() {
        this.dashboardMyResults();
        this.intervalMyResults = setInterval(() => {
            this.dashboardMyResults();
        }, 30000);

    },
    methods: {
        async dashboardMyResults() {
            if (!this.loadingPagePools) {
                this.loadingPagePools = true;
                this.$store.dispatch("dashboardPools")
                    .then(async (response) => {

                        this.poolsList = response.botsActiveList;
                        this.loadingPagePools = false;
                        this.loadingPagePoolsFirstEnd = true;
                    }).catch((error) => {
                        this.loadingPagePools = false;
                        this.loadingPagePoolsFirstEnd = true;
                    });
            }

        },
        polImgCoin(type) {

            switch (type) {
                case 'USDT':
                    return "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png";
                case 'BNB':
                    return "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png";
                case 'USDC':
                    return "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png";
                case 'ETH':
                    return "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png";
                case 'BTC':
                    return "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png";
                case 'XMR':
                    return "https://s2.coinmarketcap.com/static/img/coins/64x64/328.png";
                default:
                    break;
            }
        }

    },
    unmounted() {
        clearTimeout(this.intervalMyResults);
    },

};
</script>
<style>
.pricingtable .price-value {
    width: 90px !important;
    height: 90px !important;
    padding: 26px 0 0 !important;
}

.pricingtable .pricing-content li {
    margin: 0 0 0px !important;
}



.lds-ellipsis {
    display: inline-block;
    position: relative;
    margin-top: -55px;
    width: 80px;
    height: 50px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
</style>