<template>
  <div>
    <div class="container-fluid ">
      <div class="row ">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class=" d-flex justify-content-center text-center">
                  <img class="for-light" src="../../assets/images/logo/logo.png" alt="looginpage"
                    style="width: 300px !important;" />
                  <img class="for-dark" src="../../assets/images/logo/logo_dark.png" alt="looginpage"
                    style="width: 300px !important;" />
                </a>
              </div>
              <div class="login-main bg-dark">
                <form v-on:submit.prevent="formLogin()" method="POST" name="login_form" class="form-align">
                  <h4 class="text-center">{{ $t("Revolucione seus Investimentos na Era DeFi.") }}</h4>
                  <p class="text-center">{{ $t("Eeficiência e segurança na nova era DeFi.") }}
                    {{ $t("Otimize sua Pool de liquidez e maximize retornos com tecnologia blockchain.") }}</p>
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Endereço de email") }}</label>
                    <input class="form-control" type="email" required="" :placeholder="$t('Enter with your email')"
                      v-model="emailLogin">
                    <div v-if="emailError || apiErrors.email" class="validate-error text-danger">
                      {{ emailError || apiErrors.email[0] }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">{{ $t("Senha") }}</label>
                    <div class="input-group position-relative">
                      <input class="form-control" id="login-password" :type="passwordFieldType" name="password"
                        required="" :placeholder="$t('Enter with your password')" v-model="password">
                      <span class="input-group-text pointer" @click="togglePasswordVisibility" id="basic-addon2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none"
                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="cursor-pointer feather feather-eye-off">
                          <path
                            d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24">
                          </path>
                          <line x1="1" y1="1" x2="23" y2="23"></line>
                        </svg>
                      </span>

                      <div v-if="passwordError || apiErrors.password" class="validate-error text-danger">
                        {{ passwordError || apiErrors.password[0] }}
                      </div>

                    </div>
                  </div>
                  <div v-if="apiErrors.message" class="text-danger mt-2 mb-2">{{ apiErrors.message }}</div>
                  <div class="form-group mb-0">
                    <div class="mt-1">
                      <router-link class="link" :to="{ name: 'forgot-password' }">
                        {{ $t("Esqueceu sua senha?") }}
                      </router-link>
                    </div>
                    <div class="text-end mt-3">
                      <button type="submit" class="btn btn-primary btn-block w-100" :disabled="isLoading"><span
                          v-if="isLoading">{{ $t("Aguarde...") }}</span>
                        <span v-else>{{ $t("Login") }}</span></button>
                    </div>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal etste -->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ $t("Autenticação de 2 Fatores") }}</h5>
            </div>
            <div class="modal-body">
              <p>{{ $t("Por favor, insira o código de segurança enviado ao seu e-mail") }}:</p>
              <!-- <input type="text" v-model="securityCode" class="form-control" maxlength="6" /> -->
              <div class="d-flex justify-content-center">
                <template v-for="(code, index) in codes">
                  <input type="text" class="text-center form-control form-control-2" :ref="`codeInput${index}`"
                    v-model="codes[index]" maxlength="1" :id="`input-${index}`"
                    style="width: 50px; height: 55px; border: 2px solid rgb(10, 65, 218); border-radius: 3px; margin: 0.25rem; font-size: 1.1rem; font-weight: bold; color: rgb(255, 255, 255);"
                    @input="focusNext(index)" @paste="handlePaste">

                </template>

              </div>
              <div v-if="apiErrors.securityCode" class="text-danger mt-1 text-center">
                {{ apiErrors.securityCode[0] }}
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary me-2" @click="closeModal">{{ $t("Fechar") }}</button>
              <button type="button" class="btn btn-outline-primary me-2" :disabled="isLoading" @click="formLogin"><span
                  v-if="isLoading">{{ $t("Aguarde...") }}</span>
                <span v-else>{{ $t("Enviar") }}</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { Modal } from 'bootstrap';
import { togglePasswordVisibility } from "@/libs/mixins/ui/forms";
import { debounce } from "debounce";

export default {
  name: 'login',
  mixins: [togglePasswordVisibility],
  data() {
    return {

      emailLogin: '',
      password: '',
      emailError: '',
      passwordError: '',
      securityCode: '',
      modalInstance: null,
      apiErrors: {},
      isLoading: false,
      modal2fa: null,

      codes: Array(6).fill(''),
      focusedIndex: 0,
    };
  },
  created() {
  },
  computed: {
    ...mapGetters(["returnLocale"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  watch: {
    emailLogin: debounce(function (mail) {
      const emailMinusc = mail.toLowerCase();

      if (this.emailLogin !== emailMinusc) {
        this.emailLogin = emailMinusc;
      }
    }, 500),
  },
  methods: {
    ...mapActions(["authenticateUser", "recoverUserData", "verifyConnectionServer"]),

    async formLogin() {
      this.isLoading = true;
      this.apiErrors = {}

      const cleanedEmail = this.emailLogin.trim();
      const cleanedPassword = this.password.trim();

      if (!this.validEmail(cleanedEmail)) {
        this.emailError = this.$t('Digite um e-mail válido.');
        this.isLoading = false;
        return;
      } else {
        this.emailError = '';
      }

      if (cleanedPassword.length < 6) {
        this.passwordError = this.$t('A senha deve ter no mínimo 6 caracteres.');
        this.isLoading = false;
        return;
      } else {
        this.passwordError = '';
      }

      let credentials = {
        email: cleanedEmail,
        password: cleanedPassword,
      }

      const fullCode = this.codes.join('');
      if (fullCode) {
        credentials.securityCode = fullCode
      }


      this.authenticateUser(credentials)
        .then(() => {
          this.closeModal();
          this.$router.push("/");

        })
        .catch((error) => {

          if (error?.openModal2fa) {
            this.isLoading = false;
            this.openModal()
          } else {
            this.isLoading = false;
            if (error?.errors) {
              this.apiErrors = error?.errors
            } else if (error?.message) {
              this.apiErrors.message = error?.message
            } else {
              this.apiErrors.message = this.$t('Login e senha incorretos.')
            }

          }
        });
    },
    openModal() {
      if (!this.modalInstance) {
        this.modalInstance = new Modal(document.getElementById('exampleModalCenter'), {
          keyboard: false,
        });
        this.modalInstance.show();
      } else {
        this.modalInstance.show();
      }
    },
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    focusNext(index) {
      if (index < 5) {
        this.$refs[`codeInput${index + 1}`][0].focus();
      }
    },
    handlePaste(event) {
      event.preventDefault();

      const pastedData = event.clipboardData.getData('text/plain');

      if (pastedData.length === 6) {
        for (let i = 0; i < 6; i++) {
          this.codes.splice(i, 1, pastedData.charAt(i));
        }
      }
    },
  },
};
</script>
