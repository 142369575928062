<template>
  <div class="pt-5 pt-md-0">
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex" v-if="title">
            <h3>{{ $t(title.name) }}</h3>
          </div>
          <div class="col-6">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{ path: '/' }">
                  <svg class="stroke-icon">
                    <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                  </svg>
                </router-link>
              </li>
              <li class="breadcrumb-item" v-if="main">
                <span v-if="main.name && !main.url">
                  {{ $t(main.name) }}
                </span>
                <router-link v-if="main.name && main.url" :to="{ path: main.url }">
                  {{ $t(main.name) }}
                </router-link>
              </li>
              <li class="breadcrumb-item " v-if="title">
                <span v-if="title.name && !title.url">
                  {{ $t(title.name) }}
                </span>
                <router-link v-if="title.name && title.url" :to="{ path: title.url }">
                  {{ $t(title.name) }}
                </router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    title: {
      default: 'home'
    },
    main: {
      default: ''
    }
  }
};
</script>
