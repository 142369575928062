import api from "@/libs/axios";

const dashboard = {
    state: {
        analyticsData: [],
    },
    getters: {},
    actions: {
        dashboardAnalytics: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get('/dashboard/analytics').then(response => {
                    resolve(response?.data)
                }).catch(error => {
                    reject(error.response?.data.message)
                })
            })
        },
        dashboardMyResults: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get('/dashboard/my-results').then(response => {
                    resolve(response?.data)
                }).catch(error => {
                    reject(error.response?.data.message)
                })
            })
        },
        /* dashboard/pools */
        dashboardPools: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get('/dashboard/pools').then(response => {
                    resolve(response?.data)
                }).catch(error => {
                    reject(error.response?.data.message)
                })
            })
        }

    },
    mutations: {},
}

export default dashboard;