import { createRouter, createWebHistory } from "vue-router";
// *===============================================---*
// *--------- Editados ---- ----------------------------*
// *===============================================---*

/* Dashboards */
import DefaultDashboard from '../views/dashboard/default';

/* Auth */
import login from '../views/auth/login';
import Register from '../views/auth/register';
import ResetPassword from '../views/auth/reset_password';
import ForgetPassword from '../views/auth/forget_password';

/* profile */
import accountSetting from '../views/profile/userProfile'
/* support */
import supportTickets from '../views/support/supportTickets'
import supportTicketNew from '../views/support/supportTicketNew'
import supportTicketView from '../views/support/supportTicketView'
/* affiliates */
import affiliates from '../views/affiliate-network/affiliatesList'
import dashboard_affiliates from '../views/affiliate-network/dashboard-affiliates/Dashboard'

/* wallet */
import wallet_my_deposits from '../views/deposits/DepositsList.vue'
import wallet_my_withdrawals from '../views/withdraw/WithdrawalsList.vue'
import wallet_my_deposits_pix from '../views/deposits/DepositsPixList.vue'
import wallet_my_withdrawals_pix from '../views/withdraw/WithdrawalsPixList.vue'

/* licenses */
import licenses from '../views/licenses/Licenses.vue'

/* pools */
import Pools from '../views/pools/Pools.vue'

/* cursos */
import courses from '../views/courses/Courses.vue'



// *===============================================---*
// *--------- outros ---- ----------------------------*
// *===============================================---*
import Body from '../components/body';
import Cookie from "js-cookie";
import { canNavigate } from "@/libs/acl/routeProtection";
import store from "@/store/index";

/* Dashboards */
import Default from '../pages/dashboard/default';
import Ecommerce from '../pages/dashboard/ecommerce';
import Online from '../pages/dashboard/online'
import Crypto from '../pages/dashboard/crypto'
import Social from '../pages/dashboard/social.vue'
// Widgets
import General from '../pages/widgets/general'
import Chart from '../pages/widgets/chart'
// pageLayout
import indexBoxed from '../pages/pageLayout/boxed/indexBoxed.vue'
import indexRTL from '../pages/pageLayout/RTL/indexRTL.vue'
import indexDarklayout from '../pages/pageLayout/darklayout/indexDarklayout.vue'
import indexFooterdark from '../pages/pageLayout/footerdark/indexFooterdark.vue'
import indexFooterfixed from '../pages/pageLayout/footerfixed/indexFooterfixed.vue'
import indexFooterlight from '../pages/pageLayout/footerlight/indexFooterlight.vue'
import indexHidenavscroll from '../pages/pageLayout/hidenavscroll/indexHidenavscroll.vue'
// project
import createProject from '../pages/project/createproject/createProject.vue'
import indexList from '../pages/project/projectlist/indexList.vue'
/* File Manager  */
import FileManager from '../pages/filemanager';
// kanban
import Kanban from '../pages/kanban/indexKanban.vue'
// ecoomerce
import indexProduct from '../pages/ecommerce/product/indexProduct.vue'
import productPage from '../pages/ecommerce/productpage/productPage.vue'
import productList from '../pages/ecommerce/list/productList.vue'
import paymentDetails from '../pages/ecommerce/payment/details/paymentDetails.vue'
import orderHistory from '../pages/ecommerce/order/orderHistory.vue'
import invoiceView from '../pages/ecommerce/invoice/invoiceView.vue'
import cartView from '../pages/ecommerce/cart/cartView.vue'
import wishlistView from '../pages/ecommerce/wishlist/wishlistView.vue'
import checkoutView from '../pages/ecommerce/checkout/checkoutView.vue'
import pricingView from '../pages/ecommerce/pricing/pricingView.vue'
// mail
import readMail from '../pages/email/readMail/readMail.vue'
import composeView from '../pages/email/compose/composeView.vue'
// chat
import chatApp from '../pages/chat/chatApp/chatApp.vue'
import videoChat from '../pages/chat/videoChat/videoChat.vue'
// user
import userProfile from '../pages/users/profile/userProfile.vue'
import userCards from '../pages/users/cards/userCards.vue'
import userEdit from '../pages/users/edit/userEdit.vue'
// forms
import formValidation from "../pages/forms/formValidetion/formValidation"
import base_Input from "../pages/forms/baseInput/base_Input"
import checkbox_radio from "../pages/forms/Checkbox&Radio/checkbox_radio"
import input_groups from "../pages/forms/InputGroup/input_groups"
import megaOptions from "../pages/forms/megaOptions/megaOptions"

// form widgets
import select2 from "../pages/formWidgets/select2/select2Page.vue"
import switch_From from "../pages/formWidgets/switch/switch_From"
import touchspin_Form from "../pages/formWidgets/touchspin/touchspin_Form"
import typeahead_Form from "../pages/formWidgets/typeahead/typeahead_Form"
import clipboard_Form from "../pages/formWidgets/clipboard/clipboard_Form"
import datepicker from "../pages/formWidgets/datepicker/datePicker.vue"

// form layput
import defaultforms from '../pages/formLayout/default/defaultFotrm.vue'
import form_wizard2 from "../pages/formLayout/formWizrd2/form_wizard.vue"
import form_wizard1 from '../pages/formLayout/formWizard1/form_wizard2.vue'
import form_wizard3 from "../pages/formLayout/formWizard3/form_wizard1.vue"

// uikits
import Scrollable from "../pages/advance/scollable/scrollable_advance.vue";
import Sweetalert from "../pages/advance/sweetalert/sweetAert.vue";
import Tree from "../pages/advance/tree/treeView.vue";
import Rating from "../pages/advance/rating/ratingView.vue";
import Pagination from "../pages/advance/pagination/pagination_advance.vue";
import Ribbons from "../pages/advance/ribbons/ribbons_advance.vue";
import Tour from "../pages/advance/tour/tourView.vue";

import Breadcrumb from "../pages/advance/breadcrumbs/breadCrumb.vue";

import Sticky from "../pages/advance/sticky/stickyView.vue";

import ImageCropper from "../pages/advance/image_cropper/cropper_advance.vue";
import Notify from '../pages/advance/notify/toaster_advance.vue'
import dropzone from '../pages/advance/dropzone/dropzoneView.vue';
import animatedModal from '../pages/advance/animatedModal/animatedModal.vue'
import owlCarousel from '../pages/advance/owlCarousel/owlCarousel.vue'
import Rangeslider from '../pages/advance/range/rangeView.vue'
import BasicCard from '../pages/advance/basicCard/basicCard.vue'
import CreativeCard from '../pages/advance/creativeCard/creative_card.vue'
import DraggableCard from '../pages/advance/draggableCard/draggableCard.vue'
import TabbedCard from '../pages/advance/tabbedCard/tabbed_card.vue'
import timeLine from '../pages/advance/timeLine/timeLine.vue'
/* FAQ */
import Faq from '../pages/faq/faqIndex.vue';
// uikits
import alert from '../pages/uikits/alert';
import Helperclasses from '../pages/uikits/helper_classes';
import Avatars from '../pages/uikits/avatars';
import Grid from '../pages/uikits/grid';
import Tagpills from '../pages/uikits/tag_pills';
import Typography from '../pages/uikits/typography';
import Progressbar from '../pages/uikits/progress_bar';
import Modal from '../pages/uikits/modal';
import Popover from '../pages/uikits/popover';
import Tooltip from '../pages/uikits/tooltip';
import Spinners from '../pages/uikits/loader';
import Dropdown from '../pages/uikits/dropdown';
import Accordion from '../pages/uikits/accordion';
import Boxshadow from '../pages/uikits/box_shadow';
import Lists from '../pages/uikits/lists';
import bootstraptabPage from "../pages/uikits/tabs/bootstraptab/bootstraptabPage.vue";
import linetabPage from "../pages/uikits/tabs/linetab/linetabPage.vue";
// animation
import animate from '../pages/animation/animate/animatePage.vue'
import aos from '../pages/animation/aos/aosPage.vue'
import scroll from '../pages/animation/scroll/scrollPage.vue'
import wow from '../pages/animation/wow/wowPage.vue'
import tilt from '../pages/animation/tilt.vue'
// builder
import form1 from '../pages/builder/formBuilder1.vue'
import form2 from '../pages/builder/formBuilder2.vue'
/* icons */
import Flag from '../pages/icons/flag';
import Ico from '../pages/icons/ico_icon';
import Themify from '../pages/icons/themify_icon';
import Fontawesome from '../pages/icons/font_awesome';
import FeatherIcon from '../pages/icons/feather_icon';
import WhetherIcon from '../pages/icons/weather';
/* Buttons */
// import Default_buttons from '../pages/button/Default/default_button.vue';
// import Flat_buttons from '../pages/button/Flat/flat_button.vue';
// import Edge_buttons from '../pages/button/Edge/edge_button.vue';
// import Raised_buttons from '../pages/button/Raised/raised_button.vue';
// import Group_buttons from '../pages/button/ButtonGroup/button_group.vue';
/*charts*/
// import google_chart from "../pages/charts/googleChart/google_chart"
// import apex_chart from "../pages/charts/ApexChart/apex_chart"
// import chartist_chart from "../pages/charts/chartist/chartist_chart.vue"
// knowledge
// import knowledge from '../pages/Knowledgebase/index.vue'
// import category_knowledge from '../pages/Knowledgebase/categoryKnowledge.vue'
// import detail_knowledge from '../pages/Knowledgebase/detailsKnowledge.vue'
// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';

/* Support Ticket */
import Support from '../pages/support/support.vue';

/* Editor */
// import SimpleEditor from '../pages/editor/simple_editor';
// import CkEditor from '../pages/editor/ckeditor.vue';
// /* Maps */
// import GoogleMaps from '../pages/maps/google_map.vue';
// import VueLeaflet from '../pages/maps/vue_leaflet.vue';
// // learning
// import learning_list from "../pages/learning/learninglist/learning_list"
// import coursedetailed from "../pages/learning/courseDetailed"
// // job Search
// import job_list from "../pages/job/job-list/job_list"
// import job_details from "../pages/job/job_details/job_details"
// import job_apply from "../pages/job/job_apply/job_apply"
// import job_card from "../pages/job/job_card/cardJob.vue"
// // blog
// import blog_detail from "../pages/blog/blog-details/blog_detail"
// import blog_single from "../pages/blog/blog-single/blog_single"
// import blog_add from "../pages/blog/blog-add/addBlog.vue"
// // GALLERY
// import grid_gallery from "../pages/gallery/grid_gallery"
// import grid_desc from "../pages/gallery/grid_desc"
// import hover_gallery from "../pages/gallery/hover-gallery/hover_gallery"
// import masonry_gallery from "../pages/gallery/masonry-gallery/masonry_gallery"
// import masonary_desc from "../pages/gallery/masonary_desc.vue"
// /* Authentication */
// import LoginOne from '../pages/authentication/login_one';
// import LoginTwo from '../pages/authentication/login_two';
// import LoginValidate from '../pages/authentication/login_validation.vue';
// import LoginTooltip from '../pages/authentication/login_tooltip.vue'
// import LoginSweetalert from '../pages/authentication/login_sweetalert.vue'

// import RegisterImage from '../pages/authentication/register_image';
// import RegisterImage2 from '../pages/authentication/register_image2';
// import Unlock from '../pages/authentication/unlock';

// //import ResetPassword from '../pages/authentication/reset_password';
// import Maintenance from '../pages/authentication/maintenance.vue'

// // comingsoon
// import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
// import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';
// import ComingsoonVideo from '../pages/comingsoon/comingsoon_video';
// // SAMPLE PAGE
// import sample_page from "../pages/sample_page"
// /* bootstrap tables */
// import BootstrapBasic from "../pages/tables/bootstrapTable/basicTables/basic_tables.vue";
// import tableComponent from "../pages/tables/bootstrapTable/stylingtable/styling_table.vue"
// import DatatableBasic from "../pages/tables/dataTable/BasicInit/basic_Init.vue"
// // searchPage
// import serchIndex from "../pages/search/serchIndex.vue"
// // bookmark
// import Bookmark from "../pages/bookmark/bookMark.vue"
// // contact 
// import contactView from "../pages/contact/contactView.vue"
// // task
// import Task from "../pages/task/indexPage.vue"
// // calendar
// import Calendar from "../pages/calenderView.vue"
// // social app
// import socialPage from "../pages/socialApp/socialApp.vue"
// // todo
// import Todo from "../pages/todo/index.vue"

const router = createRouter({
  history: createWebHistory(),
  routes: [

    /* auth links */
    {
      path: "/login/:locale?",
      name: "login",
      component: login,
      meta: {
        title: ' login',
        action: "read",
        resource: "Public",
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ResetPassword,
      meta: {
        title: 'ForgetPassword',
        action: "read",
        resource: "Public",
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ForgetPassword,
      meta: {
        title: 'ResetPassword',
        action: "read",
        resource: "Public",
      }
    },
    {
      path: "/ref/:upline/:locale?",
      name: 'register-upline',
      component: Register,
      meta: {
        title: 'register',
        action: "read",
        resource: "Public",
      }
    },



    /*Home page */
    {
      path: '/',
      component: Body,
      children: [
        {
          path: '',
          name: 'DefaultDashboard',
          component: DefaultDashboard,
          meta: {
            title: 'Home',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },

    /* licenses */
    {
      path: '/licenses',
      component: Body,
      children: [
        {
          path: '',
          name: 'Licenses',
          component: licenses,
          meta: {
            title: 'Home',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    /* pools */
    {
      path: '/pools',
      component: Body,
      children: [
        {
          path: '',
          name: 'Pools',
          component: Pools,
          meta: {
            title: 'Home',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },

    /* cursos */
    {
      path: '/courses',
      component: Body,
      children: [
        {
          path: '',
          name: 'Cursos',
          component: courses,
          meta: {
            title: 'Home',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    /* profile */
    {
      path: '/account-setting',
      component: Body,
      children: [
        {
          path: '',
          name: 'accountSetting',
          component: accountSetting,
          meta: {
            title: 'Profile',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },


    {
      path: '/support-tickets',
      component: Body,
      children: [
        {
          path: '',
          name: "supportTickets",
          component: supportTickets,
          meta: {
            title: 'Support Tickets',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/support-tickets/new',
      component: Body,
      children: [
        {
          path: '',
          name: "supportTicketNew",
          component: supportTicketNew,
          meta: {
            title: 'New Support Ticket',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/support-tickets/:idTicket',
      component: Body,
      children: [
        {
          path: '',
          name: "supportTicketView",
          component: supportTicketView,
          meta: {
            title: 'View Support Ticket',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/affiliates/list',
      component: Body,
      children: [
        {
          path: '',
          name: "affiliates",
          component: affiliates,
          meta: {
            title: 'Affiliates',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/affiliate-network/dashboard',
      component: Body,
      children: [
        {
          path: '',
          name: "dashboard_affiliates",
          component: dashboard_affiliates,
          meta: {
            title: 'Affiliates Dashboard',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/wallet/my-deposits',
      component: Body,
      children: [
        {
          path: '',
          name: "wallet_my_deposits",
          component: wallet_my_deposits,
          meta: {
            title: 'Deposits Crypto',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/wallet/my-withdrawals',
      component: Body,
      children: [
        {
          path: '',
          name: "wallet_my_withdrawals",
          component: wallet_my_withdrawals,
          meta: {
            title: 'Deposits Crypto',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/wallet/my-deposits-pix',
      component: Body,
      children: [
        {
          path: '',
          name: "wallet_my_deposits-pix",
          component: wallet_my_deposits_pix,
          meta: {
            title: 'Saques Pix',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },
    {
      path: '/wallet/my-withdrawals-pix',
      component: Body,
      children: [
        {
          path: '',
          name: "wallet_my_withdrawals_pix",
          component: wallet_my_withdrawals_pix,
          meta: {
            title: 'Deposits Pix',
            action: "read",
            resource: "registered-user",
          }
        },
      ]
    },











    /* others links */



    /* errors */

    {
      path: "/not-authorized",
      name: "not-authorized",
      component: Error403,
      meta: {
        title: 'Error403',
        action: "read",
        resource: "Public",
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        title: 'Error404',
        action: "read",
        resource: "Public",
      }
    },

    /* continuação */

    // *===============================================---*
    // *--------- ERROR ---- ----------------------------*
    // *===============================================---*
    {
      path: '/:catchAll(.*)',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach(async (to, _, next) => {
  let isLoggedIn = localStorage.getItem('lifemoneyToken')

  // Set the page pageTitle or a default pageTitle
  if (to.meta?.title) {
    document.title =
      `${process.env.VUE_APP_TITLE} - ${to.meta.title}` ||
      process.env.VUE_APP_TITLE;
  }

  const userData = store.state.authentication.user;
  if (isLoggedIn && userData.length == 0) {
    await store.dispatch("recoverUserData").catch((err) => {
      localStorage.removeItem('lifemoneyToken');
      isLoggedIn = false;
    });
  }

  //setListMenu
  if (store.state?.authentication?.user
    && store.state?.menu?.data_customer
    && store.state?.menu?.data_admin) {
    let listData = [];
    if (store.state.authentication.user?.roles?.find(resp => resp?.subject == 'admin_views2')) {
      listData = [...store.state.menu.data_customer, ...store.state.menu.data_admin];
    } else {
      listData = [...store.state.menu.data_customer];
    }
    await store.dispatch("setListMenu", listData).catch((err) => {
      //console.log('deu erro');
    });
  }

  // If navigating to a public page, do not perform redirection

  if (to.meta.resource === "Public") {
    return next();
  }

  // Ver a lógica com o Gezer, deu ruim!!!
  if (!await canNavigate(to, await store.state.authentication.user)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "login" });

    // If logged in => not authorized
    return next({ name: "not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/");
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router