<template>
    <div>
        <Breadcrumbs :title="{
            name: 'Affiliates'

        }" :main="{
    name: 'Dashboard',
    url: '/affiliate-network/dashboard'
}" />
        <div>
            <div class="card mb-0 p-2">
                <div class="m-2 mt-3">
                    <MegaTable :tableColumns="tableColumns" :loading="loading" :tableRows="tableRows"
                        @fetch-tablerows="(params) => fetchAffiliates(params)" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import MegaTable from "@/components/MegaTable.vue";

import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        MegaTable,
    },
    data() {
        return {
            tableColumns: [
                { label: "Nome", field: "fullname" },
                { label: "E-mail", field: "email" },
                { label: "Diretos", field: "affiliateCount" },
                { label: "Registro", field: "createdAt" },
                { label: "Status", field: "status" },
            ],
            tableRows: null,
            loading: false,
        };
    },
    mounted() {
        this.fetchAffiliates();
    },
    methods: {
        ...mapActions(["userReferencesList"]),
        async fetchAffiliates(providedParams) {
            this.loading = true;

            let params = {
                page: providedParams ? (providedParams.page || 1) : 1,
                limit: providedParams ? (providedParams.limit || 10) : 10,
                search: providedParams ? (providedParams.search || "") : "",
                affiliateId: providedParams ? (providedParams.affiliateId || null) : null,
            };

            const response = this.userReferencesList(params).then(response => {
                this.tableRows = response;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            });


        },
    },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>
  
<style lang="scss">
.limitString {
    /* Sem restrições específicas para desktop */
}

/* Estilo para dispositivos móveis */
@media (max-width: 768px) {
    .limitString {
        white-space: nowrap;
        max-width: 16rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>