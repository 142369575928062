<template>
    <div class="col-xxl-8 col-lg-12 box-col-12">
        <div class="card">
            <div class="mb-0 card-header card-no-border">
                <h5 class="text-light">{{ $t("Minha evolução") }}</h5>
            </div>
            <div class="mt-2 px-2">
                <div class="row m-0 overall-card">
                    <div class="col-xl-9 col-md-12 col-sm-7 px-3">
                        <div class="chart-right">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="p-0 card growth-wrap">
                                        <div class="card-header card-no-border">
                                            <div class="header-top">

                                            </div>
                                        </div>
                                        <div class="card-body pt-0">
                                            <div class="growth-wrapper">
                                                <apexchart height="200" type="line" :options="options6" :series="series6">
                                                </apexchart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-12 col-sm-5 px-2">
                        <div class="row g-sm-4 g-2">
                            <div class="col-xl-12 col-md-4" v-for="item in overallbalance" :key="item">
                                <div class="p-2 light-card balance-card widget-hover">
                                    <div class="svg-box">
                                        <svg class="svg-fill">
                                            <use
                                                :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${item.iconName}`">
                                            </use>
                                        </svg>
                                    </div>
                                    <div>
                                        <span class="f-light">{{ item.title }}</span>
                                        <h6 class="mt-1 mb-0 text-light" :class="item.amountClass">{{ item.amount }}</h6>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        dashboardMyResults: Object,
    },
    data() {
        return {
            options6: {

                chart: {
                    height: 135,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    dropShadow: {
                        enabled: true,
                        enabledOnSeries: undefined,
                        top: 5,
                        left: 0,
                        blur: 4,
                        color: '#00ACC1',
                        opacity: 0.22
                    },
                },
                grid: {
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                },
                colors: ["#5527FF"],
                stroke: {
                    width: 3,
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'category',
                    categories: ['0', '1', '2'],
                    tickAmount: 10,
                    labels: {
                        style: {
                            fontFamily: 'Rubik, sans-serif',
                        },
                    },
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        gradientToColors: ['#5527FF'],
                        shadeIntensity: 1,
                        type: 'horizontal',
                        opacityFrom: 1,
                        opacityTo: 1,
                        colorStops: [{
                            offset: 0,
                            color: "#5527FF",
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: "#E069AE",
                            opacity: 1
                        },
                        ]
                    },
                },
                yaxis: {
                    min: -10,
                    max: 40,
                    labels: {
                        show: false
                    }
                },
                responsive: [{
                    breakpoint: 992,
                    options: {
                        chart: {
                            height: 150,
                        }
                    },
                },
                {
                    breakpoint: 768,
                    options: {
                        chart: {
                            height: 200,
                        }
                    },
                }
                ]
            },
            series6: [{
                name: 'Evolution',
                data: [14, 2, 30]
            }],
            overallbalance: [],
        };
    },
    watch: {
        dashboardMyResults(newValue) {
            if (newValue) {
                this.handleDist(newValue);
            }
        }
    },
    mounted() {
        if (this.dashboardMyResults) {
            this.handleDist(this.dashboardMyResults);
        }
    },
    methods: {
        handleDist(dashboardMyResults) {
            this.overallbalance = [
                {
                    iconName: "income",
                    title: this.$t("Valor atual"),
                    amount: this.moneyFormat(dashboardMyResults.currentValue),
                    amountClass: "font-success"
                },
                {
                    iconName: "doller-return",
                    title: this.$t("Valor investido"),
                    amount: this.moneyFormat(dashboardMyResults.initialValue),
                    amountClass: "font-success"
                },
                {
                    iconName: "expense",
                    title: this.$t("Lucro"),
                    amount: this.moneyFormat(dashboardMyResults.totalProfit),

                    amountClass: "font-danger"
                },
            ];


        }
    }
}
</script>
