<template>
    <div class="p-3">
        <form class="p-4" @submit.prevent="saveChange">
            <div class="row">
                <div class="col-12 col-md-5">
                    <div>
                        <label for="pix-wallet" class="text-light">Minha Conta PIX</label>
                        <span>
                            <input type="text" class="form-control" v-model="pixAccount">
                            <small v-if="errors.pixAccount" class="text-danger">
                                {{ errors.pixAccount }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-7">
                    <div class="row">
                        <div class="col-12">
                            <div show="true" class="card text-white mt-2" style="background-color: #00ACC1c0;">
                                <div class="card-body">
                                    {{ $t("Para sua segurança, os dados da sua conta só poderão") }}
                                    {{ $t("ser modificados mediante a confirmação de um código enviado para seu e-mail.") }}
                                    <div class="d-flex justify-content-end mt-1">
                                        <button type="button"
                                            class="purchase-btn btn btn-primary btn-hover-effect f-w-500 btn-sm"
                                            @click="sendCode">
                                            {{ $t("Enviar o código por e-mail") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-spacing-0 mb-1"></div>
                        </div>
                        <div class="col-12">
                            <fieldset class="form-group">
                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0 text-light">
                                    {{ $t("Código de segurança") }}
                                </legend>
                                <div>
                                    <span>
                                        <input v-model="securityCode" name="code" type="text"
                                            placeholder="Digite o código de segurança" class="form-control"
                                            :disabled="isLoading">
                                        <small v-if="errors.securityCode" class="text-danger">
                                            {{ errors.securityCode }}
                                        </small>
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <button type="submit" class="mt-2 purchase-btn btn btn-primary btn-hover-effect f-w-500 btn-sm">
                            <div v-if="!loading">
                                {{ $t("Salvar alterações") }}
                            </div>
                            <div v-else class="d-flex align-items-center">
                                {{ $t("Carregando...") }}
                                <span class="spinner-grow spinner-grow-sm" role="status">
                                    <span class="sr-only"></span>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div v-if="showAlert" :class="`alert alert-${colorAlert} mt-2`" role="alert">
            <p>{{ textAlert }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as yup from 'yup';

export default {
    name: 'walletPixProfile',
    components: {
    },
    props: {
    },
    data() {
        return {
            pixAccount: "",
            securityCode: "",
            loading: false,

            colorAlert: "warning",
            showAlert: false,
            textAlert: "",
            isLoading: false,

            errors: {
                pixAccount: '',
                securityCode: "",
            },
        };
    },
    computed: {
        ...mapGetters(["returnUser",]),
    },
    mounted() {
        this.loadingDataProfile();
        this.pixAccount = this.returnUser?.pixAccount
    },
    watch: {
        returnUser: function (oldVal, newVal) {
            this.loadingDataProfile();
            this.pixAccount = newVal?.pixAccount
        },
        pixAccount(newValue) {
            if (newValue) {
                this.errors.pixAccount = '';
            }
        },
        securityCode(newValue) {
            if (newValue) {
                this.errors.securityCode = '';
            }
        },
    },
    methods: {
        ...mapActions(["userEditProfile", "recoverUserData", "confirmEmail"]),
        clearForm() {
            this.pixAccount = "";
        },
        sendCode() {
            const data = {
                clientEmail: this.returnUser.email,
                clientName: this.returnUser.fullname,
            };
            this.$store.dispatch("sendSecurityCode", data).then((resp) => {
                this.colorAlert = "success";
                this.showAlert = true;
                this.textAlert = resp.message;
                setTimeout(() => {
                    this.showAlert = false;
                }, 5000);
            });
        },
        loadingDataProfile() {
        },
        saveChange() {
            const schema = yup.object().shape({
                pixAccount: yup
                    .string()
                    .min(6, this.$t("A chave PIX deve ter pelo menos 6 caracteres"))
                    .max(100, this.$t("A chave PIX não pode ter mais de 100 caracteres"))
                    .required(this.$t("A chave PIX é obrigatória")),
                securityCode: yup
                    .string()
                    .min(6, this.$t("O código de segurança deve ter pelo menos 6 caracteres"))
                    .max(6, this.$t("O código de segurança não pode ter mais de 6 caracteres")),
            });

            schema
                .validate({
                    pixAccount: this.pixAccount,
                    securityCode: this.securityCode
                }, { abortEarly: false })
                .then(() => {
                    this.isLoading = true;

                    this.loading = true;
                    const data = {
                        pixAccount: this.pixAccount,
                        securityCode: this.securityCode
                    };

                    this.userEditProfile(data)
                        .then((response) => {
                            this.loading = false;
                            this.securityCode = '';

                            this.colorAlert = "success";
                            this.showAlert = true;
                            this.textAlert = "As informações do seu perfil foram atualizadas.";
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 5000);
                        })
                        .catch((error) => {
                            //console.log(error);
                            this.isLoading = false;
                            this.loading = false;
                            const { errors } = error || {};
                            this.errors.pixAccount = errors?.pixAccount || '';
                            this.errors.securityCode = errors?.securityCode || '';

                            this.colorAlert = "warning";
                            this.showAlert = true;
                            this.textAlert = "Erro ao atualizar as informações.";
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 5000);
                        });
                }).catch((error) => {
                    this.isLoading = false;
                    this.loading = false;
                    this.errors.pixAccount = '';
                    this.errors.securityCode = '';
                    if (error.inner) {
                        error.inner.forEach((e) => {
                            this.errors[e.path] = e.message;
                        });
                    }
                });

        },
    },
};
</script>