<template>
    <Breadcrumbs :title="{
        name: 'User Profile',
    }" />
    <div v-if="loadingPage" class="text-center mt-5">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div v-if="!loadingPage" class="container-fluid">
        <div class="user-profile">
            <div class="row">
                <div class="col-12 col-md-3 p-1">
                    <div class="nav flex-column nav-pills me-3 p-2 rounded bg-dark" id="v-pills-tab" role="tablist"
                        aria-orientation="vertical">
                        <button class="nav-link w-100 d-flex active" id="change-profile-tab" data-bs-toggle="pill"
                            data-bs-target="#change-profile" type="button" role="tab" aria-controls="change-profile"
                            aria-selected="true">
                            <i class="fa fa-edit text-light" style="font-size: 24px;"></i>
                            <span class="font-weight-bold text-light">
                                {{ $t("Informações da conta") }}
                            </span>
                        </button>
                        <button class="nav-link w-100 d-flex" id="change_password-tab" data-bs-toggle="pill"
                            data-bs-target="#change_password" type="button" role="tab" aria-controls="change_password"
                            aria-selected="false">
                            <i class="icofont icofont-ssl-security text-light" style="font-size: 24px;"></i>
                            <span class="font-weight-bold text-light">
                                {{ $t("Alterar a senha") }}
                            </span>
                        </button>
                        <button v-if="returnUser && returnUser.config && returnUser.config.methodCPStatusWithdraw"
                            class="nav-link w-100 d-flex" id="change_key_cp-tab" data-bs-toggle="pill"
                            data-bs-target="#change_key_cp" type="button" role="tab" aria-controls="change_key_cp"
                            aria-selected="false">
                            <i class=" fa fa-bitcoin text-light" style="font-size: 25px; width: 23px;"></i>
                            <span class="font-weight-bold text-light">
                                ${{ $t("Carteiras crypto") }}
                            </span>
                        </button>
                        <button v-if="returnUser && returnUser.config && (returnUser.config.methodPixStatusWithdraw)"
                            class="nav-link w-100 d-flex" id="change_key_pix-tab" data-bs-toggle="pill"
                            data-bs-target="#change_key_pix" type="button" role="tab" aria-controls="change_key_pix"
                            aria-selected="false">
                            <i class="icon-wallet text-light" style="font-size: 23px;"></i>
                            <span class="font-weight-bold text-light">
                                {{ $t("Conta pix") }}
                            </span>
                        </button>
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <div class="tab-content w-100" style="height: 100%;" id="v-pills-tabContent">
                        <div class="tab-pane fade show active card" id="change-profile" role="tabpanel"
                            aria-labelledby="change-profile-tab">
                            <generalProfile />
                        </div>
                        <div class="tab-pane fade card" id="change_password" role="tabpanel"
                            aria-labelledby="change_password-tab">

                            <passwordProfile />
                        </div>
                        <div class="tab-pane fade card" id="change_key_cp" role="tabpanel"
                            aria-labelledby="change_key_cp-tab">
                            <walletCPProfile />
                        </div>
                        <div class="tab-pane fade card" id="change_key_pix" role="tabpanel"
                            aria-labelledby="change_key_pix-tab">
                            <walletPixProfile />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import generalProfile from "./generalProfile.vue";
import passwordProfile from "./passwordProfile.vue";
import walletCPProfile from "./walletCPProfile.vue";
import walletPixProfile from "./walletPixProfile.vue";
import { mapGetters } from "vuex";

export default {
    name: 'userProfile',

    components: {
        generalProfile,
        passwordProfile,
        walletCPProfile,
        walletPixProfile,
    },
    data() {
        return {
            loadingPage: true,
        }
    },
    computed: {
        ...mapGetters(["returnUser",])
    },
    mounted() {
        setTimeout(() => {
            this.loadingPage = false;
        }, 500);
    }

}
</script>
