<script>
import Numeral from "numeral";
import { format } from "date-fns";
import { mapGetters } from 'vuex'


export default {
    props: {
        robotInfo: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loadingHandleSubmitNenewLicence: false,
            isLoadingContribution: false,
            laodingRemoveInitialValue: false,
            laodingRemoveFeesValue: false,
            whitdrawValue: 0,
            countdown: '',
            handleWithdrawAvailable: false,
            messageWithdrawAvailableRemove: '',
            messageWithdrawAvailableRemoveError: false,

            handleAporte: false,
            messageAporteRemove: '',
            messageAporteRemoveError: false,

            upgradeValue: 0,
            handleUpgrade: false,
            messageUpgradeRemove: '',
            messageUpgradeRemoveError: false,

            contributionValue: 0,
            handleContribution: false,
            messageContributionRemove: '',
            messageContributionRemoveError: false,

            messageRenewLicense: '',
            messageRenewLicenseError: false,

            format: format,

            endDate: new Date(this.robotInfo.endDate), // Substitua com a sua data de vencimento
            currentTime: new Date(),
            timeDifference: (this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) - this.currentTime : 0),
        };
    },

    computed: {
        ...mapGetters(["returnUser"]),
        expired() {
            this.endDate = this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) : '';
            return this.endDate <= this.currentTime;
        },

        daysRemaining() {
            this.timeDifference = this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) - this.currentTime : 0;
            const days = Math.floor(this.timeDifference / (1000 * 60 * 60 * 24));
            return days > 9 ? days : `0${days}`;
        },
        hoursRemaining() {
            this.timeDifference = this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) - this.currentTime : 0;
            const hours = Math.floor((this.timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            return hours > 9 ? hours : `0${hours}`;
        },
        minutesRemaining() {
            this.timeDifference = this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) - this.currentTime : 0;
            const minutes = Math.floor((this.timeDifference % (1000 * 60 * 60)) / (1000 * 60))
            return minutes > 9 ? minutes : `0${minutes}`;
        },
        secondsRemaining() {
            this.timeDifference = this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) - this.currentTime : 0;
            const second = Math.floor((this.timeDifference % (1000 * 60)) / 1000)
            return second > 9 ? second : `0${second}`;
        },

        isButtonDisabled() {
            const now = this.currentTime;
            const comparisonDate = this.robotInfo.initialValueDate ? new Date(this.robotInfo.initialValueDate) : new Date(this.robotInfo.startDate);
            comparisonDate.setDate(comparisonDate.getDate() + (this.returnUser?.licenseConfig?.withdrawTaxdayslimit || 0));
            return now < comparisonDate;
        },

    },
    mounted() {
        this.updateCountdown();
        setInterval(() => {
            this.currentTime = new Date();
            //this.updateCountdown();
        }, 1000);
    },
    methods: {
        // timeDifference() {
        //     return this.robotInfo?.endDate ? new Date(this.robotInfo.endDate) - this.currentTime : 0;
        // },
        compareDates(inputDate) {
            const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
            const currentDate = new Date();
            const dateToCompare = new Date(inputDate);
            const differenceInDays = Math.floor((currentDate - dateToCompare) / ONE_DAY_IN_MS);


            let withdrawTax = this.returnUser.licenseConfig.withdrawTax;
            //console.log(withdrawTax);
            for (const key in withdrawTax) {

                if (withdrawTax[key].dayStart == 0) {

                    if (differenceInDays < withdrawTax[key].dayEnd) {
                        //console.log('Test1Tax', 'diffDays: ', differenceInDays, 'dayStart: ', withdrawTax[key].dayStart, 'dayEnd: ', withdrawTax[key].dayEnd);
                        return withdrawTax[key].percentTax;
                    }
                }

                if (withdrawTax[key].dayStart > 0 && withdrawTax[key].dayEnd > 0) {

                    if (differenceInDays >= withdrawTax[key].dayStart &&
                        differenceInDays <= withdrawTax[key].dayEnd) {
                        //console.log('Test2Tax', 'diffDays: ', differenceInDays, 'dayStart: ', withdrawTax[key].dayStart, 'dayEnd: ', withdrawTax[key].dayEnd);
                        return withdrawTax[key].percentTax;
                    }
                }

                if (withdrawTax[key].dayEnd == 0) {
                    if (differenceInDays >= withdrawTax[key].dayStart) {
                        //console.log('Test3Tax', 'diffDays: ', differenceInDays, 'dayStart: ', withdrawTax[key].dayStart, 'dayEnd: ', withdrawTax[key].dayEnd);
                        return withdrawTax[key].percentTax;
                    }
                }
                // return 0;
            }
            return 0;
        },
        differenceDate(date) {
            const dataFornecida = new Date(date);
            const dataAtual = new Date();
            const diferencaEmMilissegundos = dataAtual - dataFornecida;
            const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
            const umHoraEmMilissegundos = 1000 * 60 * 60;
            const umMinutoEmMilissegundos = 1000 * 60;
            const dias = Math.floor(diferencaEmMilissegundos / umDiaEmMilissegundos);
            const horas = Math.floor((diferencaEmMilissegundos % umDiaEmMilissegundos) / umHoraEmMilissegundos);
            const minutos = Math.floor((diferencaEmMilissegundos % umHoraEmMilissegundos) / umMinutoEmMilissegundos);
            const horasComZero = horas < 10 ? `0${horas}` : `${horas}`;
            const minutosComZero = minutos < 10 ? `0${minutos}` : `${minutos}`;
            return `${dias} dias, ${horasComZero}h:${minutosComZero}m`;
        },
        dayExecution(date) {
            const dataFornecida = new Date(date);
            const dataAtual = new Date();
            const diferencaEmMilissegundos = dataAtual - dataFornecida;
            const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
            const umHoraEmMilissegundos = 1000 * 60 * 60;
            const umMinutoEmMilissegundos = 1000 * 60;
            let dias = Math.floor(diferencaEmMilissegundos / umDiaEmMilissegundos);
            const horas = Math.floor((diferencaEmMilissegundos % umDiaEmMilissegundos) / umHoraEmMilissegundos);
            const minutos = Math.floor((diferencaEmMilissegundos % umHoraEmMilissegundos) / umMinutoEmMilissegundos);
            const horasComZero = horas < 10 ? `0${horas}` : `${horas}`;
            const minutosComZero = minutos < 10 ? `0${minutos}` : `${minutos}`;
            if (dataFornecida == 'Invalid Date') {
                dias = 0;
            }
            return dias;
        },
        valueLicence(name) {
            return parseFloat(name.match(/licence:([\d.]+)-/)[1]);
        },
        updateCountdown() {
            const now = new Date();
            const comparisonDate = this.robotInfo.initialValueDate ? new Date(this.robotInfo.initialValueDate) : new Date(this.robotInfo.startDate);
            comparisonDate.setDate(comparisonDate.getDate() + (this.returnUser?.licenseConfig?.withdrawTaxdayslimit || 0));
            const diffInSeconds = Math.max((comparisonDate.getTime() - now.getTime()) / 1000, 0);
            const days = Math.floor(diffInSeconds / 86400);
            const hours = Math.floor((diffInSeconds % 86400) / 3600);
            const minutes = Math.floor((diffInSeconds % 3600) / 60);
            this.countdown = `${days} dias, ${hours} horas e ${minutes} minutos`;
        },
        beforeUnmountHandler() {
            clearInterval(this.interval);
        },
        async handleSubmitWithdrawAmount() {
            this.whitdrawValue = parseFloat(this.robotInfo.balance);
            const data = {
                robotId: this.robotInfo._id,
                value: this.whitdrawValue,
            };

            if (this.robotInfo.market_sumary?.valueInFeesBnb > 0 || this.robotInfo.market_sumary?.valueInFeesUsdt > 0 ||
                this.robotInfo.market_sumary?.valueInFeesTotalUsd > 0) {
                this.laodingRemoveFeesValue = true;
                this.$store.dispatch("withdrawValueTrading", data)
                    .then((resp) => {
                        this.messageWithdrawAvailableRemoveError = false;
                        this.messageWithdrawAvailableRemove = resp.message;
                        //this.$store.dispatch("changeBalance", this.whitdrawValue);
                        this.$store.dispatch("recoverUserData");
                        this.$emit('onDashboardMyResults');
                        this.$store.dispatch("recoverUserData").then((respUser) => {
                            this.laodingRemoveFeesValue = false;
                        });

                        setTimeout(() => {
                            this.messageWithdrawAvailableRemove = '';
                        }, 5000);
                    }).catch((error) => {
                        this.messageWithdrawAvailableRemove = error.message;
                        this.messageWithdrawAvailableRemoveError = true;
                        this.laodingRemoveFeesValue = false;
                        setTimeout(() => {
                            this.messageWithdrawAvailableRemove = '';
                        }, 5000);
                    });
            } else {
                this.messageWithdrawAvailableRemove = this.$t("Não há saldo disponível para resgate!");
                this.messageWithdrawAvailableRemoveError = true;
                setTimeout(() => {
                    this.messageWithdrawAvailableRemove = '';
                }, 5000);
            }
        },
        async handleSubmitNenewLicence() {
            this.loadingHandleSubmitNenewLicence = true;
            const data = {
                licenceId: this.robotInfo._id,
            };

            this.$store.dispatch("renewalLicence", data)
                .then((resp) => {
                    this.messageRenewLicenseError = false;
                    this.messageRenewLicense = this.$t("Licença renovada com sucesso.");
                    this.$store.dispatch("recoverUserData");
                    this.loadingHandleSubmitNenewLicence = false;
                    setTimeout(() => {
                        this.messageRenewLicense = '';
                    }, 5000);
                    this.$emit('onDashboardMyResults');
                }).catch((error) => {
                    this.messageRenewLicense = error.message;
                    this.messageRenewLicenseError = true;
                    this.loadingHandleSubmitNenewLicence = false;
                    setTimeout(() => {
                        this.messageRenewLicense = '';
                    }, 5000);
                });

        },
        async yesRemoveInitialValue() {
            if (this.handleAporte) {
                this.laodingRemoveInitialValue = true;

                this.$store.dispatch("initialAmountWithdrawn", this.robotInfo._id).then((resp) => {
                    this.messageAporteRemoveError = false;
                    this.messageAporteRemove = resp.message;

                    this.$emit('onDashboardMyResults');
                    this.$store.dispatch("recoverUserData").then((respUser) => {
                        this.laodingRemoveInitialValue = false;
                    });
                    setTimeout(() => {
                        this.messageAporteRemove = '';
                    }, 2000);

                }).catch((error) => {
                    //console.log(error);
                    this.messageAporteRemove = error.message;
                    this.messageAporteRemoveError = true;
                    this.laodingRemoveInitialValue = false;
                    setTimeout(() => {
                        this.messageAporteRemove = '';
                    }, 5000);
                });
            }
        },
        async handleSubmitUpgrade() {
            if (this.upgradeValue > 0) {
                this.$store.dispatch("upgradeLicence", {
                    licenceId: this.robotInfo._id,
                    newValue: parseFloat(this.upgradeValue),
                }).then((resp) => {
                    this.messageUpgradeRemoveError = false;
                    this.messageUpgradeRemove = 'Upgrade da licença realizado com sucesso.';
                    //this.$store.dispatch("changeBalance", -this.upgradeValue);
                    this.$store.dispatch("recoverUserData");
                    setTimeout(() => {
                        this.messageUpgradeRemove = '';
                    }, 5000);
                    this.upgradeValue = 0;
                    this.$emit('onDashboardMyResults');
                }).catch((error) => {
                    this.messageUpgradeRemove = error.message;
                    this.messageUpgradeRemoveError = true;
                    setTimeout(() => {
                        this.messageUpgradeRemove = '';
                    }, 5000);
                });
            } else {
                this.messageUpgradeRemove = 'Valor para upgrade inválido.';
                this.messageUpgradeRemoveError = true;
                setTimeout(() => {
                    this.messageUpgradeRemove = '';
                }, 5000);
            }
        },
        async handleSubmitContribution() {
            if (this.contributionValue > 0) {
                this.isLoadingContribution = true;
                this.$store.dispatch("contributionValueTrading", {
                    idLicence: this.robotInfo._id,
                    value: this.contributionValue,
                }).then((resp) => {
                    this.messageContributionRemoveError = false;
                    this.messageContributionRemove = resp.message;
                    //this.$store.dispatch("changeBalance", -this.contributionValue);
                    this.$store.dispatch("recoverUserData");

                    this.$emit('onDashboardMyResults');
                    this.contributionValue = 0;
                    this.isLoadingContribution = false;
                    setTimeout(() => {
                        this.messageContributionRemove = '';
                        const closeButton = document.getElementById(`close-modal-licence-contri-${this.robotInfo._id}`);
                        if (closeButton) {
                            closeButton.click();
                        }
                    }, 2000);
                }).catch((error) => {
                    this.messageContributionRemove = error.message;
                    this.messageContributionRemoveError = true;
                    this.isLoadingContribution = false;
                    setTimeout(() => {
                        this.messageContributionRemove = '';
                    }, 5000);
                });
            } else {
                this.messageContributionRemove = 'Valor para liquidez inválido.';
                this.messageContributionRemoveError = true;
                setTimeout(() => {
                    this.messageContributionRemove = '';
                }, 5000);
            }
        },
        progressLicence(item) {
            if (item.totalProfit == 0 && item.initialValue == 0) {
                return '0';
            } else {
                return parseFloat((item.totalProfit / item.initialValue) * 100).toFixed(2);
            }
        },
        typeIntervalDay(withdrawTax) {
            if (withdrawTax.dayStart == 0 && withdrawTax.dayEnd > 0) {
                return `Menos de ${withdrawTax.dayEnd} dias`
            }
            if (withdrawTax.dayStart > 0 && withdrawTax.dayEnd > 0) {
                return `De ${withdrawTax.dayStart} a ${withdrawTax.dayEnd} dias`
            }
            if (withdrawTax.dayStart > 0 && withdrawTax.dayEnd == 0) {
                return `${withdrawTax.dayStart} dias ou mais`
            }
            return '--';
        },
    }
};
</script>
<template>
    <div>
        <div class="card course-box widget-course px-1">

            <div class="card-body">
                <div class="row">
                    <!-- saldo alocado -->
                    <div class="col-6 border-end custom-border-color">
                        <div class=" small-widget p-2">
                            <div class="course-widget">
                                <div class="course-icon success">
                                    <svg class="fill-icon">
                                        <use href="@/assets/svg/icon-sprite.svg#course-1"></use>
                                    </svg>
                                </div>
                                <div class="text-center">
                                    <span class="f-light">{{ $t("Valor em liquidez") }}</span>
                                    <h4 class="mb-0 text-light" v-html="moneyFormatSummary(robotInfo.initialValue ? robotInfo.initialValue : 0).value +
                                        moneyFormatSummary(robotInfo.initialValue ? robotInfo.initialValue :
                                            0).currency"></h4>

                                    <div v-if="false" class="border px-1 text-light rounded-2 bg-dark mt-2">
                                        <div class="row p-2">
                                            <div class="col-12 col-sm-5 col-md-12 col-lg-5 text-center">
                                                <img src="@/assets/icons/usdt.png" alt="usdt" class="" style="width: 18px;">
                                                USDT
                                            </div>
                                            <div class="col-12 col-sm-7 col-md-12 col-lg-7 text-center">
                                                {{ robotInfo.market_sumary ? robotInfo.market_sumary.initialValueUsdt :
                                                    '0.00'
                                                }}
                                            </div>
                                            <hr class="mt-2 w-75 p-0 mb-0" style="margin-left: 18px;">
                                            <div class="col-12 col-sm-5 col-md-12 col-lg-5 mt-1 text-center">
                                                <img src="@/assets/icons/bnb.png" alt="bnb" class="" style="width: 18px;">
                                                BNB
                                            </div>
                                            <div class="col-12 col-sm-7 col-md-12 col-lg-7 mt-1 text-center p-1">
                                                {{ robotInfo.market_sumary ? robotInfo.market_sumary.initialValueBnb :
                                                    '0.00000'
                                                }}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- saldo disponivel -->
                    <div class="col-6">
                        <div class=" small-widget p-2">
                            <div class="course-widget">
                                <div class="course-icon warning">
                                    <svg class="fill-icon">
                                        <use href="@/assets/svg/icon-sprite.svg#profit"></use>
                                    </svg>
                                </div>
                                <div class="text-center">
                                    <span class="f-light">{{ $t("Taxas não resgatadas") }}</span>
                                    <h4 class="mb-0 text-light"
                                        v-html="moneyFormatSummary(robotInfo.market_sumary.valueInFeesTotalUsd ? robotInfo.market_sumary.valueInFeesTotalUsd : 0).value + moneyFormatSummary(robotInfo.market_sumary.valueInFeesTotalUsd ? robotInfo.market_sumary.valueInFeesTotalUsd : 0).currency">
                                    </h4>


                                    <div v-if="false && dayExecution(new Date(robotInfo.initialValueDate)) >= 1 && robotInfo.initialValue > 0"
                                        class="border px-1 text-light rounded-2 bg-dark mt-2">
                                        <div class="row p-2">
                                            <div class="col-12 col-sm-5 col-md-12 col-lg-5 text-center">
                                                <img src="@/assets/icons/usdt.png" alt="usdT" class="" style="width: 18px;">
                                                USDT
                                            </div>
                                            <div class="col-12 col-sm-7 col-md-12 col-lg-7 text-center">
                                                {{ robotInfo.market_sumary ? robotInfo.market_sumary.valueInFeesUsdt :
                                                    '0.00000'
                                                }}
                                            </div>
                                            <hr class="mt-2 w-75 p-0 mb-0" style="margin-left: 18px;">
                                            <div class="col-12 col-sm-5 col-md-12 col-lg-5 mt-1 text-center">
                                                <img src="@/assets/icons/bnb.png" alt="bnb" class="" style="width: 18px;">
                                                BNB
                                            </div>
                                            <div class="col-12 col-sm-7 col-md-12 col-lg-7 mt-1 text-center p-1">

                                                {{ robotInfo.market_sumary ? robotInfo.market_sumary.valueInFeesBnb :
                                                    '0.00000'
                                                }}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style="height: 60px;">
                        <div v-if="dayExecution(new Date(robotInfo.initialValueDate)) < 1 && robotInfo.initialValue > 0"
                            class="mt-1">
                            <div class="text-center text-warning fs-6 text">

                                <!-- prettier-ignore -->
                                {{ $t("Atenção: Lembre-se de que as recompensas começarão a ser geradas após")
                                }}
                                {{ $t("24horas.") }}

                            </div>
                        </div>
                        <div v-if="(dayExecution(new Date(robotInfo.initialValueDate)) < 1 || dayExecution(new Date(robotInfo.initialValueDate)) > 1) && robotInfo.initialValue == 0"
                            class="mt-1">
                            <div class="text-center text-warning fs-6 text">
                                {{ $t("Atenção: Após a aquisição, a geração de taxas será ativada mediante a")
                                }}
                                {{ $t("adição de liquidez.") }}

                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row mt-0 mb-0">
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-6 text-center">
                                <div class="text-secondary">{{ $t("ID da licença") }}</div>
                                <p class="font-primary  font-size-14">{{ robotInfo._id }}</p>
                            </div>
                            <div class="col-6 text-center"><span class="text-secondary">{{ $t("Tempo em operação") }}</span>
                                <p class="font-primary font-size-14">
                                    {{ differenceDate(new Date(robotInfo.startDate)) }}
                                </p>
                            </div>
                        </div>
                        <!--  <div class="project-status mt-2">
                            <div class=" d-flex">
                                <p class="mb-0 p-0"> {{ progressLicence(robotInfo) }}%</p>

                            </div>
                            <div class="progress" style="height: 8px;">
                                <div class="progress-bar-animated progress-bar-striped badge-primary" role="progressbar"
                                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"
                                    :style="`width: ${progressLicence(robotInfo)}%`">
                                </div>
                            </div>
                        </div> -->
                    </div>

                    <div>
                        <div class=" mb-0">
                            <div class="d-flex align-items-center justify-content-center mb-2">
                                <div class="px-2">
                                    <button data-bs-toggle="modal" :disabled="expired"
                                        :data-bs-target="`#withdrawLicenseModal-${robotInfo._id}`" type="button"
                                        class="w-100 btn btn-outline-warning btn-hover-effect f-w-500 btn-sm">
                                        {{ $t("Coletar / Remover liquidez") }}</button>

                                </div>

                                <!-- <div class="px-2">
                                    <button v-if="robotInfo.maxTrade / 5 < 6000" :disabled="expired" data-bs-toggle="modal"
                                        :data-bs-target="`#upgradeLicenseModal-${robotInfo._id}`" type="button"
                                        class="w-100 font-primary btn btn-outline-success btn-hover-effect f-w-500 btn-sm">
                                        Fazer upgrade</button>
                                </div> -->

                                <div v-if="!expired" class="px-2">
                                    <button v-if="robotInfo.initialValue < robotInfo.maxTrade"
                                        :disabled="expired || !returnUser.licenseConfig.licenseContributionStatus"
                                        data-bs-toggle="modal"
                                        :data-bs-target="`#contributionLicenseModal-${robotInfo._id}`" type="button"
                                        class="w-100 btn btn-outline-primary btn-hover-effect f-w-500 btn-sm">
                                        {{ $t("Fornecer liquidez") }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 mb-3">
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Aquisição da licença") }}</div>
                                    <div class="font-primary"><b>
                                            {{ format(new Date(robotInfo.startDate), "dd/MM/yyyy HH:mm") }}
                                        </b></div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Renovação da licença") }}</div>
                                    <div class="font-primary"><b>
                                            {{ robotInfo.renewalDate ? format(new Date(robotInfo.renewalDate),
                                                "dd/MM/yyyy HH:mm") : "---" }}
                                        </b></div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Custo da licença") }}</div>
                                    <div class="font-primary"><b>
                                            {{ moneyFormat(valueLicence(robotInfo.name)) }}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Número total de operações") }}</div>
                                    <div class="font-primary"><b>
                                            {{ robotInfo.trade }}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Retorno total acumulado") }}</div>
                                    <div class="font-primary"><b>
                                            {{ moneyFormat(robotInfo.totalProfit) }}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Valor adquirido com taxas") }} </div>
                                    <div class="font-primary"><b>
                                            {{ moneyFormat(robotInfo.totalProfit - robotInfo.bonus) }}
                                        </b></div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Comissão recebidos") }}</div>
                                    <div class="font-primary">
                                        <b>
                                            {{ moneyFormat(robotInfo.bonus) }}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex justify-content-between px-1 undefined">
                                    <div class="text-secondary">{{ $t("Total sacado") }}</div>
                                    <div class="font-primary">
                                        <b>
                                            {{ moneyFormat(robotInfo.totalWhitdraw) }}
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-1">
                        <p v-if="!expired" class="p-0">
                        <ul class="schedule-list">
                            <!-- border px-1 text-light rounded-2 bg-dark mt-2 -->
                            <li class="px-3 py-1 primary bg-dark">
                                <div class="d-flex align-items-center justify-content-center">
                                    <strong class="mb-0 font-size-15">{{ $t("Licença expira em") }}:</strong>
                                    <ul>
                                        <li class="f-light px-2">
                                            <svg class="fill-icon f-light">
                                                <use href="@/assets/svg/icon-sprite.svg#bag"></use>
                                            </svg>
                                            <span class="font-size-15"> {{ daysRemaining }} {{ $t("Dias") }}</span>
                                        </li>
                                        <li class="f-light">
                                            <svg class="fill-icon f-success">
                                                <use href="@/assets/svg/icon-sprite.svg#clock"></use>
                                            </svg>
                                            <span class="font-size-15">
                                                {{ hoursRemaining }}:{{ minutesRemaining }}:{{ secondsRemaining
                                                }}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        </p>

                        <div v-else class="text-center">

                            <ul class="schedule-list">
                                <li class="px-3 py-0 primary">
                                    <div class="row w-100">
                                        <div class="col-6 d-flex align-items-center justify-content-center">
                                            <strong class="font-size-14 text-warning">{{ $t("Licença expirada") }}</strong>
                                        </div>
                                        <div class="col-6 d-flex align-items-center justify-content-center">
                                            <button data-bs-toggle="modal"
                                                :data-bs-target="`#openModalRenewalLicence-${robotInfo._id}`" type="button"
                                                class="btn btn-sm p-2 purchase-btn btn-primary btn-hover-effect">
                                                {{ $t("Renovar licença") }}</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>
            <ul class="square-group">
                <li class="square-1 warning"></li>
                <li class="square-1 primary"></li>
                <li class="square-2 warning1"></li>
                <li class="square-3 danger"></li>
                <li class="square-4 light"></li>
                <li class="square-5 warning"></li>
                <li class="square-6 success"></li>
                <li class="square-7 success"></li>
            </ul>
        </div>
        <!-- modal retirar valor -->
        <div class="modal fade" :id="`withdrawLicenseModal-${robotInfo._id}`">

            <div class="modal-dialog modal-lg mt-5">
                <div class="modal-content">
                    <div class="modal-header">

                        <h6 class="modal-title">{{ $t("Licença ID") }}: {{ robotInfo._id }}</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="mt-1">

                            <div id="modal-my-results___BV_modal_body_" class="modal-body">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="p-1">
                                            <!-- saque liquidez-->
                                            <div class="d-flex justify-content-between">{{ $t("Valor em liquidez") }}:
                                                <span class="text-warning">
                                                    {{ moneyFormat(robotInfo.initialValue ? robotInfo.initialValue : 0) }}
                                                </span>
                                            </div>
                                            <!--  <div class="d-flex justify-content-between pt-1"> USDT: <span
                                                    class="text-success">
                                                    {{ robotInfo.market_sumary ? robotInfo.market_sumary.initialValueUsdt :
                                                        '0.00'
                                                    }}
                                                </span>
                                            </div>
                                            <div class="d-flex justify-content-between pt-1"> BNB:
                                                <span class="text-success">
                                                    {{ robotInfo.market_sumary ? robotInfo.market_sumary.initialValueBnb :
                                                        '0.00000'
                                                    }}
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class=" p-1">
                                            <div class="d-flex justify-content-between"> {{ $t("Total") }} Usd:
                                                <span class="text-info">
                                                    {{ moneyFormat(robotInfo.market_sumary.valueInFeesTotalUsd) }}
                                                </span>
                                            </div>
                                            <!--            <div class="d-flex justify-content-between pt-1">
                                                {{ $t("Saldo") }} USDT:
                                                <span class="text-info">
                                                    {{ robotInfo.market_sumary ? robotInfo.market_sumary.valueInFeesUsdt :
                                                        '0.00'
                                                    }}
                                                </span>
                                            </div> -->
                                            <!--    <div class="d-flex justify-content-between pt-1">
                                                {{ $t("Saldo") }} BNB:
                                                <span class="text-info">
                                                    {{ robotInfo.market_sumary ? robotInfo.market_sumary.valueInFeesBnb :
                                                        '0.00000'
                                                    }}
                                                </span>
                                            </div> -->


                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-6 text-center">
                                        <button type="button" :disabled="isButtonDisabled || !(robotInfo.totalProfit < robotInfo.initialValue * (returnUser.licenseConfig.maximumReturnLicense || 3) &&
                                            returnUser.licenseConfig.withdrawTaxStatus)"
                                            @click="() => handleAporte = true"
                                            class="btn btn-warning btn-hover-effect f-w-500">
                                            {{ $t("Coletar liquidez") }}
                                        </button>

                                    </div>
                                    <div class="col-6 text-center">
                                        <button @click="handleSubmitWithdrawAmount()"
                                            :disabled="laodingRemoveFeesValue || laodingRemoveInitialValue" type="button"
                                            class="btn btn-success btn-hover-effect f-w-500">
                                            <div v-if="laodingRemoveFeesValue">
                                                {{ $t("Coletando..") }}
                                                <span class="spinner-grow spinner-grow-sm px-1" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </span>
                                            </div>
                                            <div v-if="!laodingRemoveFeesValue">
                                                {{ $t("Coletar taxas") }}
                                            </div>
                                        </button>
                                    </div>

                                </div>
                                <hr />
                                <div class="mt-1"
                                    v-if="robotInfo.totalProfit < robotInfo.initialValue * (returnUser.licenseConfig.maximumReturnLicense || 3) && returnUser.licenseConfig.withdrawTaxStatus">
                                    <small v-if="isButtonDisabled">
                                        <span class="text-white">
                                            {{ $t("Liquidez liberado para saque em") }}
                                        </span>
                                        <br>
                                        <span class="text-warning">
                                            {{ countdown }}
                                        </span>
                                    </small>
                                </div>
                                <!-- Retirar liquidez alert -->

                                <div v-if="handleAporte" class="modal-body">
                                    <hr>

                                    <div class="mb-1 text-info">
                                        <div class="h6 mb-1 text-white">{{ $t("Valor em liquidez") }}:
                                            <span class="text-nowrap">
                                                {{ moneyFormat(robotInfo.initialValue) }}
                                            </span>
                                        </div>
                                        {{ $t("Tem certeza que deseja coletar sua liquidez?") }}

                                        <!-- <div class="mt-1">
                                            {{ $t("Você receberá em USDT") }} <span class="text-info">{{
                                                robotInfo.market_sumary.initialValueUsdt }}</span>
                                        </div>
                                        <div class="mt-1">
                                            {{ $t("Você receberá em BNB") }} <span class="text-info">{{
                                                robotInfo.market_sumary.initialValueBnb }}</span>
                                        </div> -->


                                        <div class="h6 mb-1 text-white mt-2">
                                            {{ $t("Taxas") }}:
                                            <span class="text-nowrap">
                                                {{ moneyFormat(robotInfo.market_sumary.valueInFeesTotalUsd) }}

                                            </span>
                                        </div>
                                        <div v-if="false" class="mt-1">
                                            {{ $t("Você receberá em USDT") }} <span class="text-info">{{
                                                robotInfo.market_sumary.valueInFeesUsdt }}</span>
                                        </div>
                                        <div v-if="false" class="mt-1">
                                            {{ $t("Você receberá em BNB") }} <span class="text-info">{{
                                                robotInfo.market_sumary.valueInFeesBnb }}</span>
                                        </div>

                                        <div class="h6 mb-1 text-white mt-2">
                                            {{ $t("Total a ser sacado") }}:
                                            <span class="text-nowrap">
                                                {{
                                                    moneyFormat(robotInfo.market_sumary.valueInFeesTotalUsd +
                                                        robotInfo.initialValue)
                                                }}

                                            </span>
                                        </div>
                                        <div v-if="false" class="mt-1">
                                            {{ $t("Você receberá em USDT") }} <span class="text-info">{{
                                                (parseFloat(robotInfo.market_sumary.valueInFeesUsdt)
                                                    + parseFloat(robotInfo.market_sumary.initialValueUsdt)).toFixed(2) }}</span>
                                        </div>
                                        <div v-if="false" class="mt-1">
                                            {{ $t("Você receberá em BNB") }} <span class="text-info">
                                                {{
                                                    parseFloat(robotInfo.market_sumary.initialValueBnb) +
                                                    parseFloat(robotInfo.market_sumary.valueInFeesBnb)
                                                }}</span>
                                        </div>
                                        <div class="mt-2">
                                            <span class="text-danger text-center">
                                                {{ $t("Ao coletar a liquidez autamaticamente será coletado as taxas") }}
                                            </span>
                                        </div>
                                        <!--  <div class="mt-1">
                                            <h6>Taxas de Retirada</h6>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Período desde a abertura da pool (em dias)</th>
                                                        <th>Taxa de Retirada (%)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(withdrawTax, index) in returnUser.licenseConfig.withdrawTax"
                                                        :key="index">
                                                        <td>{{ typeIntervalDay(withdrawTax) }}</td>
                                                        <td>{{ withdrawTax.percentTax || 0 }}%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> -->
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <button @click="yesRemoveInitialValue()"
                                            :disabled="laodingRemoveInitialValue || laodingRemoveFeesValue" type="button"
                                            class="btn btn-success">

                                            <div v-if="laodingRemoveInitialValue">
                                                {{ $t("Removendo..") }}
                                                <span class="spinner-grow spinner-grow-sm px-1" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </span>
                                            </div>
                                            <div v-if="!laodingRemoveInitialValue">
                                                {{ $t("Sim, vou remover!") }}
                                            </div>
                                        </button>
                                        <button @click="() => handleAporte = false" type="button" class="btn btn-danger">{{
                                            $t("Não") }}!</button>
                                    </div>

                                </div>
                                <!-- liquidez alert -->
                                <div v-if="messageAporteRemove" class="alert mt-3 text-center font-white" :class="{
                                    'alert-danger': messageAporteRemoveError,
                                    'alert-success': !messageAporteRemoveError
                                }" role="alert">
                                    {{ messageAporteRemove }}
                                </div>

                                <!-- valor disponível alerta -->
                                <div v-if="messageWithdrawAvailableRemove" class="alert mt-3 text-center font-white" :class="{
                                    'alert-danger': messageWithdrawAvailableRemoveError,
                                    'alert-success': !messageWithdrawAvailableRemoveError
                                }" role="alert">
                                    {{ messageWithdrawAvailableRemove }}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- modal fazer upgrade -->
        <div class="modal fade" :id="`upgradeLicenseModal-${robotInfo._id}`">

            <div class="modal-dialog mt-5">
                <div class="modal-content">
                    <div class="modal-header">

                        <h6 class="modal-title">{{ $t("Licença") }} ID: {{ robotInfo._id }}</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">

                        <div>
                            <div class="mb-1"> {{ $t("Em quanto você deseja aumentar esta licença?") }} </div>
                            <div>
                                <div class="d-flex align-items-center">
                                    <input id="allocation-value" name="allocation-value" type="text" v-model="upgradeValue"
                                        placeholder="$0.00" class="form-control">
                                    <span class="ml-1 mt-1">
                                        USDT
                                    </span>
                                </div>
                            </div>
                        </div>
                        <footer id="" class="modal-footer">
                            <button @click="handleSubmitUpgrade()" type="button" class="btn btn-warning">
                                {{ $t("Fazer upgrade") }}
                            </button>
                        </footer>

                        <!-- valor disponível alerta -->
                        <div v-if="messageUpgradeRemove" class="alert mt-3 text-center font-white" :class="{
                            'alert-danger': messageUpgradeRemoveError,
                            'alert-success': !messageUpgradeRemoveError
                        }" role="alert">
                            {{ messageUpgradeRemove }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- fazer liquidez -->
        <div class="modal fade" :id="`contributionLicenseModal-${robotInfo._id}`">
            <div class="modal-dialog mt-5">
                <div class="modal-content">
                    <div class="modal-header">

                        <h6 class="modal-title">{{ $t("Licença") }} ID: {{ robotInfo._id }}</h6>
                        <button type="button" class="btn-close" :id="`close-modal-licence-contri-${robotInfo._id}`"
                            data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">

                        <div>
                            <div class="mb-1">
                                {{ $t("Quanto você deseja fornecer de liquidez?") }}
                            </div>
                            <div>
                                <div class="d-flex align-items-center">
                                    <input id="allocation-value" name="allocation-value" v-model="contributionValue"
                                        type="text" placeholder="$0.00" class="form-control">
                                    <span class="ml-1 mt-1">
                                        USDT
                                    </span>
                                </div>
                            </div>
                            <div class="text-center mt-1"> {{ $t("Valor máximo para liquidez nesta licença") }}:
                                <span class="text-warning">
                                    {{
                                        moneyFormat(robotInfo.maxTrade)
                                    }}
                                </span>
                            </div>
                            <div class="text-center mt-1"> {{ $t("Valor minimo para liquidez nesta licença") }}:
                                <span class="text-warning">
                                    {{
                                        moneyFormat(robotInfo.minTrade)
                                    }}
                                </span>
                            </div>
                            <!--     <div class="text-center mt-1"> Retorno máximo para essa licença:
                                <span class="text-info">
                                    {{
                                        moneyFormat(robotInfo.maxTrade * (returnUser.licenseConfig.maximumReturnLicense || 3))
                                    }}
                                </span>
                            </div> -->
                            <div class="text-center mt-1"> {{ $t("Liquidez atual") }}:
                                <span class="font-primary">
                                    {{
                                        moneyFormat(robotInfo.initialValue)
                                    }}
                                </span>
                            </div>
                            <!--  <div class="text-center mt-1"> Retorno máximo atual para essa licença: <span
                                    class="font-primary">{{
                                        moneyFormat(robotInfo.initialValue * (returnUser.licenseConfig.maximumReturnLicense ||
                                            3))
                                    }}</span>
                            </div> -->
                        </div>
                        <footer id="" class="modal-footer mb-0">

                            <button @click="handleSubmitContribution()" :disabled="isLoadingContribution" type="button"
                                class="purchase-btn btn btn-primary btn-hover-effect f-w-500">
                                <span v-if="isLoadingContribution">
                                    {{ $t("Adicionando...") }}
                                    <span class="spinner-grow spinner-grow-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                </span>
                                <span v-if="!isLoadingContribution">
                                    {{ $t("Fornecer liquidez") }}
                                </span>

                            </button>
                        </footer>

                        <!-- valor disponível alerta -->
                        <div v-if="messageContributionRemove" class="alert mt-3 text-center font-white" :class="{
                            'alert-danger': messageContributionRemoveError,
                            'alert-success': !messageContributionRemoveError
                        }" role="alert">
                            {{ messageContributionRemove }}
                        </div>

                    </div>
                </div>

            </div>
        </div>

        <!-- renovar licença -->

        <!-- `openModalRenewalLicence-${robotInfo._id}` -->
        <div class="modal fade" :id="`openModalRenewalLicence-${robotInfo._id}`">

            <div class="modal-dialog modal-md mt-5">
                <div class="modal-content">
                    <div class="modal-header">

                        <h6 class="modal-title">{{ $t("Licença") }} ID: {{ robotInfo._id }}</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mt-1">

                            <div class="mb-1 font-size-18 text-center">
                                {{ $t("Para renovar esta licença será cobrado o valor de") }}
                                <strong class="text-info">
                                    {{
                                        moneyFormatUSDT(robotInfo.licenseCost)
                                    }}
                                </strong>
                                {{ $t("do seu saldo.") }}
                            </div>
                        </div>
                        <footer class="modal-footer p-0 mt-2">
                            <button @click="handleSubmitNenewLicence()" :disabled="loadingHandleSubmitNenewLicence"
                                type="button" class="purchase-btn btn btn-primary btn-hover-effect f-w-500">


                                <div v-if="laodingRemoveFeesValue">
                                    {{ $t("Renovando...") }}
                                    <span class="spinner-grow spinner-grow-sm px-1" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                </div>
                                <div v-if="!laodingRemoveFeesValue">
                                    {{ $t("Renovar licença") }}
                                </div>
                            </button>
                        </footer>

                        <!-- valor disponível alerta -->
                        <div v-if="messageRenewLicense" class="alert mt-3 text-center font-white" :class="{
                            'alert-danger': messageRenewLicenseError,
                            'alert-success': !messageRenewLicenseError
                        }" role="alert">
                            {{ messageRenewLicense }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-border-color {
    border-right-color: #2C323F !important
        /* Substitua 'red' pela cor que deseja */
}
</style>