<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class=" d-flex justify-content-center text-center">
                  <img class="for-light" src="../../assets/images/logo/logo.png" alt="looginpage"
                    style="width: 300px !important;" />
                  <img class="for-dark" src="../../assets/images/logo/logo_dark.png" alt="looginpage"
                    style="width: 300px !important;" />
                </a>
              </div>
              <div v-if="isLoadingPage" class="text-center mt-5">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div v-if="!isLoadingPage" class="login-main bg-dark">
                <div v-if="invalidSignature == false && !createaccount">
                  <form @submit.prevent="submitCreateAccount" name="login_form" class="form-align">
                    <h4>{{ $t("Criação da conta") }}</h4>
                    <p>{{ $t("Precisamos das informações abaixo para deixar sua conta mais segura") }}</p>
                    <div class="form-group">
                      <label class="col-form-label">{{ $t("Nome completo") }}</label>
                      <input class="form-control" type="text" v-model.trim="fullname" placeholder=""
                        :disabled="isLoading" />
                      <div v-if="errors.fullname" class="text-danger">
                        {{ errors.fullname }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">E-mail</label>
                      <input class="form-control" type="text" v-model.trim="email" placeholder="" :disabled="isLoading" />
                      <div v-if="errors.email" class="text-danger">
                        {{ errors.email }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">{{ $t("Nova Senha") }}</label>
                      <div class="form-input position-relative">
                        <input class="form-control" :type="active ? 'password' : 'text'" v-model.trim="password"
                          placeholder="*********" :disabled="isLoading" />
                        <div class="show-hide">
                          <span :class="active ? 'show' : 'hide'" @click.prevent="show"></span>
                        </div>
                      </div>
                      <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">{{ $t("Repita a senha") }}</label>
                      <input class="form-control" type="password" v-model.trim="cPassword" placeholder="*********"
                        :disabled="isLoading" />
                      <div v-if="errors.cPassword" class="text-danger">{{ errors.cPassword }}
                      </div>
                    </div>

                    <div class="form-group mb-0">
                      <div class="checkbox-wrapper-1 p-2">
                        <input id="checkbox1" class="substituted" v-model="policyPrivacy" type="checkbox"
                          aria-hidden="true" />
                        <label for="checkbox1">
                          {{ $t("Concordo com") }}
                          <router-link class="ms-2" tag="a" to="#">
                            {{ $t("política de privacidade") }}
                          </router-link>
                        </label>
                        <div v-if="errors.policyPrivacy" class="text-danger">{{ errors.policyPrivacy }}
                        </div>
                      </div>

             
                      <div class="row mt-4">
                        <div class="col-12 text-end p-2 p-md-0">
                          <button type="submit" class="btn btn-primary btn-block w-100" :disabled="isLoading">
                            <span v-if="isLoading">{{ $t("Aguarde...") }}</span>
                            <span v-else>{{ $t("Criar conta") }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div v-if="invalidSignature == true && !createaccount" class="mt-4 text-center">
                  <h4 class="card-title mb-1 text-danger">
                    {{ $t("Link inválido!") }}
                  </h4>
                  <p class="card-text mb-2">
                    {{ $t("Esse link para a criação de cadastro não é mais") }}
                    {{ $t("válido, confira o link se esta correto ou") }}
                    {{ $t("entre em contato com o suporte.") }}

                  </p>
                </div>
                <div v-if="createaccount" class="alert alert-success mt-4 text-center" role="alert">
                  <p class="card-text mb-2 text-center fs-5"> {{ $t("Senha alterada com sucesso!") }}</p>
                </div>
                <div class="row mt-4">
                  <div class="col-12 col-md-4 text-center p-1">
                  </div>
                  <div class="col-12 col-md-8 text-end p-2 p-md-0">
                    {{ $t("já tem uma conta?") }}
                    <router-link class="ms-2" tag="a" :to="{ name: 'login' }">
                      Login
                    </router-link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { th } from "date-fns/locale";
import { mapActions } from "vuex";
import * as yup from 'yup';

export default {
  data() {
    return {
      active: true,
      isLoading: false,
      isLoadingPage: true,
      fullname: '',
      email: '',
      cPassword: '',
      password: '',
      policyPrivacy: '',
      errors: {
        password: '',
        cPassword: '',
        email: '',
        fullname: '',
        policyPrivacy: '',
      },
      invalidSignature: true,
      createaccount: false,
    }
  },
  mounted() {

    this.$store
      .dispatch("verifyUpline", this.$route.params?.upline)
      .then((response) => {
        this.invalidSignature = false;
        this.isLoadingPage = false;
      })
      .catch((err) => {
        this.invalidSignature = true;
        this.isLoadingPage = false;
      });
  },
  watch: {
    password(newValue) {
      if (newValue) {
        this.errors.password = '';
      }
    },
    cPassword(newValue) {
      if (newValue) {
        this.errors.cPassword = '';
      }
    },
    fullname(newValue) {
      if (newValue) {
        this.errors.fullname = '';
      }
    },
    email(newValue) {
      if (newValue) {
        this.errors.email = '';
      }
    },
    policyPrivacy(newValue) {
      if (newValue) {
        this.errors.policyPrivacy = '';
      }
    }
  },
  methods: {
    ...mapActions(["userRegister"]),
    show() {
      this.active = !this.active;
    },
    submitCreateAccount() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const schema = yup.object().shape({
        fullname: yup
          .string()
          .min(6, this.$t("O nome completo deve ter pelo menos 6 caracteres"))
          .max(100, this.$t("O nome completo não pode ter mais de 100 caracteres"))
          .required(this.$t("Nome completo é obrigatório")),
        email: yup
          .string()
          .matches(emailRegex, this.$t("O email deve ser válido"))
          .min(6, this.$t("O email deve ter pelo menos 6 caracteres"))
          .max(100, this.$t("O email não pode ter mais de 100 caracteres"))
          .required(this.$t("Email é obrigatório")),
        password: yup
          .string()
          .min(6, this.$t("A senha deve ter pelo menos 6 caracteres"))
          .max(100, this.$t("A senha não pode ter mais de 100 caracteres"))
          .required(this.$t("Senha é obrigatória")),
        cPassword: yup
          .string()
          .required(this.$t("Confirmação de senha é obrigatória"))
          .oneOf([yup.ref("password")], this.$t("As senhas devem ser iguais")),
        policyPrivacy: yup
          .boolean()
          .oneOf([true], this.$t("Você deve concordar com a política de privacidade"))
          .required(this.$t("Você deve concordar com a política de privacidade")),
      });

      schema
        .validate({
          password: this.password,
          cPassword: this.cPassword,
          fullname: this.fullname,
          email: this.email,
          policyPrivacy: this.policyPrivacy
        }, { abortEarly: false })
        .then(() => {
          this.isLoading = true;

          this.errors.password = '';
          this.errors.cPassword = '';

          const data = {
            fullname: this.fullname,
            email: this.email.toLowerCase(),
            password: this.password,
            passwordConfirm: this.cPassword,
            upline: this.$route.params?.upline,
          };

          this.userRegister(data)
            .then((response) => {
              this.createaccount = true;
              this.isLoading = false;
              this.$router.push("/");
            })
            .catch((error) => {
              this.isLoading = false;
              const { errors } = error || {};
              this.errors.password = errors?.password?.[0] || '';
              this.errors.cPassword = errors?.passwordConfirm?.[0] || '';
              this.errors.fullname = errors?.fullname?.[0] || '';
              this.errors.email = errors?.email?.[0] || '';

            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.errors.password = '';
          this.errors.cPassword = '';
          this.errors.fullname = '';
          this.errors.email = '';
          if (error.inner) {
            error.inner.forEach((e) => {
              this.errors[e.path] = e.message;
            });
          }
        });
    },
  },
};
</script>

<style>
.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}

.checkbox-wrapper-1 [type=checkbox].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.checkbox-wrapper-1 [type=checkbox].substituted+label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}

.checkbox-wrapper-1 [type=checkbox].substituted:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:enabled+label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}

.checkbox-wrapper-1 [type=checkbox].substituted:checked+label:before {
  background-color: #3B99FC;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}

.checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled+label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper-1 [type=checkbox].substituted:focus+label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}

.checkbox-wrapper-1 [type=checkbox].substituted:focus:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted:focus+label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
}

.checkbox-wrapper-1 [type=checkbox].substituted:disabled+label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark+label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled+label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked+label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}

.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled:active+label:before,
.checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled+label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}
</style>
