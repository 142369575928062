<script setup>
import { defineProps, defineEmits, reactive, computed, ref, defineExpose } from 'vue';

const search = ref('');
const affiliateId = ref('');
const affiliate = ref([]);

const emits = defineEmits(['fetch-tablerows']);

const props = defineProps({
    tableColumns: {
        type: Array,
        required: true,
        default: () => [
            { label: "Nome", field: "fullname" },
            { label: "E-mail", field: "email" },
            { label: "Diretos", field: "affiliateCount" },
            { label: "Registro", field: "createdAt" },
            { label: "Status", field: "status" },
        ]
    },
    loading: {
        type: Boolean,
        required: true,
        default: false
    },
    searshDisabled: {
        type: Boolean,
        default: false
    },
    perPageDisabled: {
        type: Boolean,
        default: false
    },
    tableRows: {
        type: Object,
        required: true,
        default: () => ({
            referenceList: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            lastPage: 1
        })
    }
});

const pagination = reactive({
    total: 0,
    perPage: 10,
    currentPage: 1,
    lastPage: 1
});

const rows = computed(() => {
    if (props.tableRows) {
        pagination.total = props.tableRows.total;
        pagination.perPage = props.tableRows.perPage;
        pagination.currentPage = props.tableRows.currentPage;
        pagination.lastPage = Math.ceil(props.tableRows.total / props.tableRows.perPage);

        return props.tableRows.referenceList;
    }
    return {};
});

const formatDate = (dateString) => {
    const options = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit'
    };
    return new Intl.DateTimeFormat('pt-BR', options).format(new Date(dateString));
};

const getStatusDetails = (status) => {
    if (status === 'active') {
        return { label: "Ativo", color: '#3AC199' };
    } else if (status === 'approved') {
        return { label: "Aprovado", color: '#3AC199' };
    } else if (status === 'inactive') {
        return { label: "Inativo", color: '#FF6060' };
    } else if (status === 'rejected') {
        return { label: "Rejeitado", color: '#FF6060' };
    }
    else if (status === 'Waiting for buyer funds...' || status === 'pending approval') {
        return { label: "Pendente de aprovação", color: '#ff9f43' };
    }
    return { label: status, color: 'gray' };
};

const changePage = (page) => {
    pagination.currentPage = page;
    emits('fetch-tablerows', {
        page: pagination.currentPage,
        limit: pagination.perPage,
        search: search.value,
        affiliateId: affiliateId.value
    });
};

const viewAffiliateNetwork = (id, username) => {
    if (id === null) {
        affiliate.value = [];
        affiliateId.value = '';
        pagination.currentPage = 1;
    } else {
        affiliate.value.push({ id, username });
        affiliateId.value = id;
        pagination.currentPage = 1;
    }
    emits('fetch-tablerows', {
        page: pagination.currentPage,
        limit: pagination.perPage,
        search: search.value,
        affiliateId: affiliateId.value
    });
};

const goUpOneLevel = () => {
    if (affiliate.value.length > 0) {
        affiliate.value.pop();
        if (affiliate.value.length === 0) {
            affiliateId.value = '';
        } else {
            affiliateId.value = affiliate.value[affiliate.value.length - 1].id;
        }
        pagination.currentPage = 1;
        emits('fetch-tablerows', {
            page: pagination.currentPage,
            limit: pagination.perPage,
            search: search.value,
            affiliateId: affiliateId.value
        });
    }
};

const updatePerPage = () => {
    emits('fetch-tablerows', {
        page: pagination.currentPage,
        limit: pagination.perPage,
        search: search.value,
        affiliateId: affiliateId.value
    });
};

const emitSearch = debounce(() => {
    emits('fetch-tablerows', {
        page: pagination.currentPage,
        limit: pagination.perPage,
        search: search.value,
        affiliateId: affiliateId.value
    });
}, 500);

function debounce(fn, delay) {
    let timeoutId;
    return function (...args) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

const surroundingPages = 2;

const visiblePages = computed(() => {
    let pages = [];

    for (let i = 1; i <= pagination.lastPage; i++) {
        if (i == 1 || i == pagination.lastPage || (i >= pagination.currentPage - surroundingPages && i <= pagination.currentPage + surroundingPages)) {
            pages.push(i);
        }
    }

    return pages;
});
</script>

<template>
    <div>

        <div class="">
            <div class="row">
                <div v-if="!perPageDisabled && !loading" class="col-12 col-md-2">
                    <label for="perPage" class="text-light">{{ $t("Linhas por página") }}:</label>
                    <select id="perPage" class="form-select" v-model="pagination.perPage" @change="updatePerPage">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>
                <div v-if="!searshDisabled" class="col-12 col-md-4">
                    <label class="text-light">{{ $t("Buscar") }}:</label>
                    <input v-model="search" @input="emitSearch" class="form-control" />
                </div>

                <div class="col-12 col-md-6">
                    <div v-if="!loading" class="d-flex justify-content-center justify-content-md-end p-3 mt-2">
                        <button type="button" class="btn btn-outline-info" v-if="affiliate.length > 0"
                            style="margin-right: 5px;" @click="() => viewAffiliateNetwork(null)">
                            {{ $t("Meus diretos") }}</button>
                        <button type="button" class="btn btn-outline-info" v-if="affiliate.length > 0"
                            @click="goUpOneLevel">
                            {{ $t("Subir um nível") }}
                        </button>
                    </div>
                </div>

            </div>

        </div>

        <div class="my-3 text-light" v-if="!loading && affiliate.length > 0">
            {{ $t("Você está visualizando os integrantes diretos da rede de") }}
            <span class="text-info">
                {{ affiliate.length === 0 ? user.username : affiliate[affiliate.length - 1].username }}
            </span>.
            {{ $t("Estes integrantes fazem parte da sua rede de") }}
            <span class="text-info">
                {{ affiliate.length === 0 ? '1º' : (affiliate.length + 1) + 'º' }} {{ $t("nível") }}</span>.
            <div class="my-3">
                {{ $t("Mostrando") }} <span class="text-info">{{ rows && rows.length }}</span> {{ $t("de") }} <span
                    class="text-theme">{{
                        pagination.total
                    }}</span> {{ $t("registros") }}.
            </div>
        </div>

        <div v-if="loading" class="text-center mt-5">
            <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="table-responsive mt-3" v-else>
            <table class="table table-striped " v-if="rows && rows.length">
                <thead>
                    <tr>
                        <th scope="col" class="bg-dark" v-for="columns in tableColumns">
                            {{ $t(columns.label) }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in rows">
                        <th v-for="columns in tableColumns" class="bg-dark">
                            <div v-if="columns.field === 'affiliateCount'">
                                <button type="button" class="btn btn-outline-info btn-sm"
                                    :disabled="affiliate.length + 1 >= 5 || row[columns.field] === 0"
                                    @click="() => viewAffiliateNetwork(row['_id'], row['username'])">
                                    {{ row[columns.field] }} - {{ $t("Visualizar") }}
                                </button>
                            </div>
                            <div v-else-if="columns.field === 'createdAt'">
                                {{ formatDate(row[columns.field]) }}
                            </div>
                            <div v-else-if="columns.field === 'status'">
                                <span :style="{ color: getStatusDetails(row[columns.field]).color }">
                                    {{ $t(getStatusDetails(row[columns.field]).label) }}
                                </span>
                            </div>
                            <div v-else>{{ row[columns.field] }}</div>
                        </th>
                    </tr>
                </tbody>
            </table>
            <div v-if="!rows || !rows.length" class="text-center my-4 text-light">
                {{ $t("Nenhum registro encontrado") }}.
            </div>
        </div>

        <div class="d-flex justify-content-center mt-2" v-if="!loading">
            <ul class="pagination mb-0">
                <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1 }">
                    <a class="page-link" @click.prevent="changePage(pagination.currentPage - 1)">{{ '<' }}</a>
                </li>

                <li class="page-item" v-for="(page, index) in visiblePages" :key="page"
                    :class="{ 'active': page === pagination.currentPage }">
                    <!-- Se for a primeira página e houver um salto no próximo número, mostre ... -->
                    <template v-if="index === 0 && visiblePages[1] - visiblePages[0] > 1">
                        <div class="d-flex">
                            <a class="page-link" @click.prevent="page !== pagination.currentPage && changePage(page)">{{
                                page
                            }}</a>
                            <a class="page-link">...</a>
                        </div>
                    </template>

                    <!-- Se não for nem a primeira nem a última página e houver um salto no próximo número, mostre ... -->
                    <template
                        v-else-if="index !== 0 && index !== visiblePages.length - 1 && visiblePages[index + 1] - visiblePages[index] > 1">
                        <div class="d-flex">
                            <a class="page-link" @click.prevent="page !== pagination.currentPage && changePage(page)">{{
                                page
                            }}</a>
                            <a class="page-link">...</a>
                        </div>
                    </template>

                    <!-- Para todas as outras situações, apenas mostre o número da página -->
                    <template v-else>
                        <a class="page-link" @click.prevent="page !== pagination.currentPage && changePage(page)">{{ page
                        }}</a>
                    </template>
                </li>

                <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage }">
                    <a class="page-link" @click.prevent="changePage(pagination.currentPage + 1)">{{ '>' }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.page-item.disabled .page-link {
    cursor: default !important;
}

.page-item:not(.disabled) .page-link {
    cursor: pointer !important;
}

.form-control::placeholder {
    color: #ccc;
    /* Cor clara para o texto do placeholder */
    opacity: 1;
    /* Opacidade total */
}
</style>