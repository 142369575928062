<script>
import Numeral from "numeral";
import { Toast } from 'bootstrap';
import { debounce } from "debounce";
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            showAlert: false,
            textAlert: "",
            colorAlert: "alert-warning",
            createDeposit: {},
            depositValuePIX: 0,
            depositValueInBRL: 0,
            dynamicPixData: null,
            qrcodeUrl: '',
            urlImage: null,
            profileFile: null,
            loading: false
        };
    },
    created() {

    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    watch: {
        depositValuePIX: debounce(function (newVal) {
            this.updadteValueInBRL(newVal);
        }, 500),
    },
    methods: {
        generateQrCodeUrl(text) {
            const encodedText = encodeURIComponent(text);
            return `https://api.qrserver.com/v1/create-qr-code/?data=${encodedText}&size=250x250`;
        },
        async confirmDeposit() {
            if (!this.loading) {
                this.loading = true;
                const data = {
                    value: this.depositValuePIX,
                };
                this.$store
                    .dispatch("userCreateDepositPIX", data)
                    .then((resp) => {
                        this.profileFile = null;
                        this.urlImage = null;
                        this.showAlert = true;
                        this.textAlert = resp.message;
                        this.colorAlert = "alert-primary";
                        this.dynamicPixData = resp.returnData;
                        this.qrcodeUrl = this.generateQrCodeUrl(resp.returnData.pixCopiaECola);
                        this.loading = false;

                        setTimeout(() => {
                            this.showAlert = false;
                            this.textAlert = "";
                        }, 5000);
                    })
                    .catch((err) => {
                        this.showAlert = true;
                        this.textAlert = err?.message;
                        this.colorAlert = "alert-warning";
                        this.loading = false;
                        setTimeout(() => {
                            this.showAlert = false;
                            this.textAlert = "";
                        }, 5000);
                    });
            }
        },
        async pixCopyPaste(codepix) {
            await navigator.clipboard.writeText(codepix);

            const toast = new Toast(document.getElementById("copyCodepix"));
            toast.show();
        },
        updadteValueInBRL(newVal) {
            if (newVal > 0) {
                this.$store
                    .dispatch("convertUSDtoURL", newVal)
                    .then((resp) => {
                        this.depositValueInBRL = this.depositValuePIX * resp.ask;
                    })
                    .catch((err) => {
                        // Trate o erro, se necessário
                    });
            } else {
                this.depositValueInBRL = 0;
            }
        }
    }
};
</script>

<template>
    <div>
        <div v-if="returnUser" class="row mt-5">
            <div class="col-sm-6">
                <div>{{ $t("Digite o valor em USDC que você pretende depositar") }}:</div>
                <input id="allocation-value" v-model="depositValuePIX" name="allocation-value" type="number"
                    placeholder="$0.00" class="mt-2 form-control" :class="{
                        'is-invalid': (depositValuePIX < returnUser.config.limitMinPixDeposit && depositValuePIX > 0) ||
                            depositValuePIX > returnUser.config.limitMaxPixDeposit && depositValuePIX > 0
                    }">
                <small class="text-danger"
                    v-if="depositValuePIX < returnUser.config.limitMinPixDeposit && depositValuePIX != 0">
                    {{ $t("O valor de depósito mínimo é de") }}
                    {{ returnUser.config.limitMinPixDeposit }} USDC
                </small>
                <small class="text-danger"
                    v-if="depositValuePIX > returnUser.config.limitMaxPixDeposit && depositValuePIX != 0">                     
                    {{ $t("O valor de depósito máximo é de") }}
                    {{ returnUser.config.limitMaxPixDeposit }} USDC
                </small>
                <div class="mt-1">
                    {{ $t(`Você receberá de saldo`) }}
                    <span class="text-info">{{
                        moneyFormat(depositValuePIX)
                    }}</span>

                </div>
                <fieldset class="form-group">
                    <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
                        {{ $t("O valor que você precisa transferir via PIX é de") }}:
                    </legend>
                    <h4 class="text-theme text-center mt-1">
                        {{
                            depositValueInBRL.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })
                        }}
                    </h4>
                </fieldset>
                <div class="d-flex justify-content-center mt-2">
                    <button type="button" class="purchase-btn btn btn-primary btn-hover-effect f-w-500"
                        @click="confirmDeposit" :disabled="depositValuePIX < returnUser.config.limitMinPixDeposit ||
                                depositValuePIX > returnUser.config.limitMaxPixDeposit || loading
                                ">
                        <span role="status" v-show="loading" class="spinner-grow spinner-grow-sm">
                            <span class="sr-only"></span>
                        </span>
                        {{ $t(`Mostrar PIX para depósito`) }}
                    </button>

                </div>
                <div class="mt-3" v-show="showAlert">
                    <div class="demo-spacing-0">
                        <div class="alert" :class="colorAlert">
                            <span>{{ textAlert }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-left border-dashed col-sm-6">
                <div v-if="dynamicPixData">
                    <div class="d-flex justify-content-center my-1">
                        <img :src="qrcodeUrl" alt="QR Code" style="width: 200px; height: 200px;" />
                    </div>
                    <div class="d-flex justify-content-center mt-1">

                        <button type="button" @click="pixCopyPaste(dynamicPixData.pixCopiaECola)"
                            class="mt-3 purchase-btn btn btn-primary btn-hover-effect f-w-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-copy">
                                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                            </svg>
                            PIX Copia e Cola
                        </button>
                    </div>

                    <div class="d-flex justify-content-center text-center my-1">
                        Cole no seu banco, na função <br />
                        PIX Copia e Cola
                    </div>
                    <div>
                        <hr class="my-1" />
                        <div class="d-flex justify-content-center mt-1">
                            <button type="button" @click="() => { dynamicPixData = null; loading = false }"
                                class="mt-3 purchase-btn btn btn-sm btn-warning btn-hover-effect f-w-500">
                                {{
                                    $t(`Clique para gerar outra
                                intensão de depósito`)
                                }}
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex justify-content-center mt-3 mt-sm-0">
                    <img src="@/assets/img/operation-img.png" alt="Login V2" class="" style="width: 14rem;">
                </div>
                <div class="toasts-container mt-2">
                    <div class="toast fade hide mb-3 bg-success" data-autohide="false" id="copyCodepix">

                        <div class="text-white toast-body">
                            {{
                                dynamicPixData ? dynamicPixData.pixCopiaECola : ''
                            }}
                            Codigo pix copiado para área de transferência
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>