<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Courses',
    components: {

    },
    data() {
        return {
            loadingPage: true,
        }

    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
            this.loadingPage = false
        }, 1000);
    },
    methods: {
    },
    unmounted() {
    },
};
</script>
<template>
    <!-- BEGIN row -->
    <div>
        <div class="row">
            <div v-if="loadingPage" class="text-center mt-5">
                <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div v-if="!loadingPage" class="col-12 col-md-12 col-lg-12">
                <!-- BEGIN card -->
                <div class="py-2 px-2 px-md-0 mt-4 mt-md-0">

                    <div class="card crypto-main-card h-100">
                        <div class="card-body text-center">
                            <h3>{{ $t("Em Breve - Novo Curso") }} DeFi!</h3>
                            <p class="fs-3 p-0 m-0">{{ $t("Caros usuários,") }}</p>

                            <p class="fs-6  p-0 m-0">
                                {{ $t("Estamos animados em compartilhar que estamos atualmente trabalhando duro para ") }}
                                {{ $t("criar um curso abrangente sobre finanças descentralizadas (DeFi).") }}
                                {{ $t("Este curso foi cuidadosamente elaborado para fornecer conhecimentos valiosos") }}
                                {{ $t("e práticos sobre o mundo emocionante e dinâmico das finanças descentralizadas.") }}

                            </p>

                            <h4>{{ $t("O que esperar do nosso curso") }} DeFi:</h4>
                            <ul class="fs-6  p-0 m-0">
                                <li>
                                    {{ $t("Módulos especializados para iniciantes e avançados.") }}
                                </li>
                                <li>
                                    {{ $t("Conteúdo atualizado com as últimas tendências e tecnologias DeFi.") }}
                                </li>
                                <li>
                                    {{ $t("Instrutores especializados com vasta experiência no campo.") }}
                                </li>
                            </ul>

                            <h4 class="p-0 mt-2">{{ $t("Status Atual:") }}</h4>
                            <p class="fs-6  p-0 m-0">
                                {{ $t("Estamos nos estágios de desenvolvimento e ajuste do conteúdo para garantir a melhor")
                                }}
                                {{ $t("experiência de aprendizado possível. Agradecemos sua paciência enquanto trabalhamos")
                                }}
                                {{ $t("arduamente para criar algo excepcional.") }}</p>

                            <h4 class="p-0 mt-2">
                                {{ $t("Lançamento do Curso DeFi:") }}
                            </h4>
                            <p class="fs-6  p-0 m-0">
                                {{ $t("Estamos mirando lançar o curso em breve! Fique atento às nossas") }}
                                {{ $t("atualizações nas redes") }}
                                {{ $t("sociais e em nosso site para obter informações exclusivas sobre o lançamento.") }}
                            </p>

                            <p class="fs-6  p-0 m-0">
                                {{ $t("Agradecemos muito pelo seu interesse e entusiasmo em relação ao nosso") }}
                                {{ $t("curso DeFi. Estamos") }}
                                {{ $t("comprometidos em fornecer uma experiência educacional excepcional que agregará ")
                                }}
                                {{ $t("valor ao seu conhecimento sobre finanças descentralizadas.") }}
                            </p>

                            <p class="fs-6  p-0 m-0">
                                {{ $t("Fique ligado para mais atualizações emocionantes!") }}
                            </p>

                            <p class="fs-6  p-0 m-0">
                                {{ $t("Atenciosamente,") }}
                                <br>LifeIsMoney
                            </p>
                        </div>
                    </div>
                </div>
                <!-- END card -->
            </div>
        </div>
    </div>
</template>

<style>
.pricingtable .price-value {
    width: 90px !important;
    height: 90px !important;
    padding: 26px 0 0 !important;
}

.pricingtable .pricing-content li {
    margin: 0 0 0px !important;
}



.lds-ellipsis {
    display: inline-block;
    position: relative;
    margin-top: -55px;
    width: 80px;
    height: 50px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
</style>