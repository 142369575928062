<template>
    <div>
        <form class="p-4" @submit.prevent="saveChange">
            <div class="row">
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-12">
                            <div role="group" class="form-group"><label for="account-old-password"
                                    class="d-block text-light">
                                    {{ $t("Senha atual") }}</label>
                                <div><span>
                                        <div role="group" class="input-group input-group-merge">
                                            <input id="account-old-password" name="old-password" :type="passwordFieldType"
                                                :placeholder="`${$t('Senha atual')}`" v-model="currentPassword"
                                                class="form-control" :disabled="isLoading">
                                            <span class="input-group-text pointer" @click="togglePasswordVisibility"
                                                id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="14px"
                                                    height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="cursor-pointer feather feather-eye-off">
                                                    <path
                                                        d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24">
                                                    </path>
                                                    <line x1="1" y1="1" x2="23" y2="23"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <small v-if="errors.currentPassword" class="text-danger">
                                            {{ errors.currentPassword }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div role="group" class="form-group">
                                <label for="account-new-password" class="d-block text-light">{{ $t("Nova senha") }}</label>
                                <div>
                                    <span>
                                        <div role="group" class="input-group input-group-merge">
                                            <input id="account-new-password" name="new-password" v-model="newPassword"
                                                :type="passwordFieldType" :placeholder="`${$t('Nova senha')}`"
                                                class="form-control" :disabled="isLoading">
                                            <span class="input-group-text pointer" @click="togglePasswordVisibility"
                                                id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="14px"
                                                    height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="cursor-pointer feather feather-eye-off">
                                                    <path
                                                        d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24">
                                                    </path>
                                                    <line x1="1" y1="1" x2="23" y2="23"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <small v-if="errors.newPassword" class="text-danger">
                                            {{ errors.newPassword }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div role="group" class="form-group">
                                <label for="account-retype-new-password" class="d-block text-light">
                                    {{ $t("Digite novamente a nova senha") }}
                                </label>
                                <div>
                                    <span>
                                        <div role="group" class="input-group input-group-merge">
                                            <input id="account-retype-new-password" v-model="retypePassword"
                                                name="retype-password" :type="passwordFieldType"
                                                placeholder="Digite novamente a nova senha" class="form-control"
                                                :disabled="isLoading">
                                            <span class="input-group-text pointer" @click="togglePasswordVisibility"
                                                id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="14px"
                                                    height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="cursor-pointer feather feather-eye-off">
                                                    <path
                                                        d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24">
                                                    </path>
                                                    <line x1="1" y1="1" x2="23" y2="23"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <small v-if="errors.retypePassword" class="text-danger">
                                            {{ errors.retypePassword }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <div class="col-12">
                            <div show="true" class="card text-white mt-2 bg-primary">
                                <div class="card-body text-light">
                                    {{ $t("Para sua segurança, os dados da sua conta só poderão") }}
                                    {{ $t("ser modificados mediante a confirmação de um código enviado para seu e-mail.") }}
                                    <div class="d-flex justify-content-end mt-1">
                                        <button type="button"
                                            class="purchase-btn btn btn-primary btn-hover-effect f-w-500 btn-sm"
                                            @click="sendCode">
                                            {{ $t("Enviar o código por e-mail") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-spacing-0 mb-1"></div>
                        </div>
                        <div class="col-12">
                            <fieldset class="form-group">
                                <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0 text-light">
                                    {{ $t("Código de segurança") }}
                                </legend>
                                <div>
                                    <span>
                                        <input v-model="securityCode" name="code" type="text"
                                            :placeholder="`${$t('Digite o código de segurança')}`" class="form-control"
                                            :disabled="isLoading">
                                        <small v-if="errors.securityCode" class="text-danger">
                                            {{ errors.securityCode }}
                                        </small>
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <button type="submit"
                            class="mt-2 purchase-btn btn btn-primary btn-hover-effect f-w-500 btn-sm text-light">
                            <div v-if="!loading">
                                {{ $t("Salvar alterações") }}
                            </div>
                            <div v-else class="d-flex align-items-center">
                                {{ $t("Carregando...") }}
                                <span class="spinner-grow spinner-grow-sm" role="status">
                                    <span class="sr-only"></span>
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <div v-if="showAlert" :class="`alert alert-${colorAlert} mt-2`" role="alert">
            <p>{{ textAlert }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as yup from 'yup';
import { togglePasswordVisibility } from "@/libs/mixins/ui/forms";
import { th } from "date-fns/locale";

export default {
    name: 'passwordProfile',
    mixins: [togglePasswordVisibility],
    components: {
    },
    props: {
    },
    data() {
        return {

            currentPassword: "",
            newPassword: "",
            retypePassword: "",
            securityCode: "",
            loading: false,

            colorAlert: "warning",
            showAlert: false,
            textAlert: "",

            isLoading: false,

            errors: {
                newPassword: '',
                retypePassword: '',
                currentPassword: "",
                securityCode: "",
            },
        };
    },
    computed: {
        ...mapGetters(["returnUser",]),
    },
    mounted() {
        this.loadingDataProfile();
    },
    watch: {
        returnUser: function (oldVal, newVal) {
            this.loadingDataProfile();
        },
        newPassword(newValue) {
            if (newValue) {
                this.errors.newPassword = '';
            }
        },
        retypePassword(newValue) {
            if (newValue) {
                this.errors.retypePassword = '';
            }
        },
        currentPassword(newValue) {
            if (newValue) {
                this.errors.currentPassword = '';
            }
        },
        securityCode(newValue) {
            if (newValue) {
                this.errors.securityCode = '';
            }
        },
    },
    methods: {
        ...mapActions(["userEditProfile", "recoverUserData", "confirmEmail"]),
        clearForm() {
            this.currentPassword = "";
            this.newPassword = "";
            this.newPassword = "";
        },
        sendCode() {
            const data = {
                clientEmail: this.returnUser.email,
                clientName: this.returnUser.fullname,
            };
            this.$store.dispatch("sendSecurityCode", data).then((resp) => {
                this.colorAlert = "success";
                this.showAlert = true;
                this.textAlert = resp.message;
                setTimeout(() => {
                    this.showAlert = false;
                }, 5000);
            });
        },
        loadingDataProfile() {
        },
        saveChange() {
            const schema = yup.object().shape({
                currentPassword: yup
                    .string()
                    .min(6, this.$t("A senha deve ter pelo menos 6 caracteres"))
                    .max(100, this.$t("A senha não pode ter mais de 100 caracteres"))
                    .required(this.$t("Senha atual é obrigatória")),
                newPassword: yup
                    .string()
                    .min(6, this.$t("A senha deve ter pelo menos 6 caracteres"))
                    .max(100, this.$t("A senha não pode ter mais de 100 caracteres"))
                    .required(this.$t("Nova senha é obrigatória")),
                retypePassword: yup
                    .string()
                    .required(this.$t("Digite novamente a nova senha"))
                    .oneOf([yup.ref("newPassword")], this.$t("As senhas não conferem")),
                securityCode: yup
                    .string()
                    .min(6, this.$t("O código de segurança deve ter pelo menos 6 caracteres"))
                    .max(6, this.$t("O código de segurança não pode ter mais de 6 caracteres")),
            });

            schema
                .validate({
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                    retypePassword: this.retypePassword,
                    securityCode: this.securityCode
                }, { abortEarly: false })
                .then(() => {
                    this.isLoading = true;

                    this.loading = true;
                    const data = {
                        currentPassword: this.currentPassword,
                        newPassword: this.newPassword,
                        securityCode: this.securityCode
                    };

                    this.userEditProfile(data)
                        .then((response) => {
                            this.loading = false;
                            this.currentPassword = '';
                            this.newPassword = '';
                            this.retypePassword = '';
                            this.securityCode = '';

                            this.colorAlert = "success";
                            this.showAlert = true;
                            this.textAlert = this.$t("Senha alterada com sucesso.");
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 5000);
                        })
                        .catch((error) => {
                            //console.log(error);
                            this.isLoading = false;
                            this.loading = false;
                            const { errors } = error || {};
                            this.errors.currentPassword = errors?.currentPassword || '';
                            this.errors.newPassword = errors?.newPassword || '';
                            this.errors.securityCode = errors?.securityCode || '';

                            this.colorAlert = "warning";
                            this.showAlert = true;
                            this.textAlert = this.$t("Não foi possível alterar a senha.");
                            setTimeout(() => {
                                this.showAlert = false;
                            }, 5000);
                        });
                }).catch((error) => {
                    this.isLoading = false;
                    this.loading = false;
                    this.errors.currentPassword = '';
                    this.errors.newPassword = '';
                    this.errors.retypePassword = '';
                    this.errors.securityCode = '';
                    if (error.inner) {
                        error.inner.forEach((e) => {
                            this.errors[e.path] = e.message;
                        });
                    }
                });

        },
    },
};
</script>