<script>
import DepositCrypto from './DepositCrypto.vue'
import DepositPix from "./DepositPix.vue";
import { mapGetters } from 'vuex'
export default {
    components: {
        DepositCrypto,
        DepositPix
    },
    data() {
        return {
            selectedDeposit: 'crypto'
        };
    },
    computed: {
        ...mapGetters(["returnUser"]),
    },
    methods: {
        selectTypeDeposit(type) {
            this.selectedDeposit = type;
        },
        selectTypeDepositClean() {
            this.selectedDeposit = 'crypto';
        }
    }
};
</script>
<template>
    <!-- modal-lg -->
    <div class="modal fade" id="modalDeposits">
        <div class="modal-dialog mt-5 modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Fazer depósito") }}</h5>
                    <button type="button" @click="selectTypeDepositClean()" class="btn-close" data-bs-dismiss="modal">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <!-- <span>Qual método de pagamento você deseja utilizar?</span> -->
                        <div v-if="returnUser && returnUser.config">
                            <div class="row">
                                <!-- <div v-if="returnUser.config.methodCPStatusDeposit"
                                    :class="{ 'col-6': returnUser.config.methodPixStatusDeposit, 'col-12': !returnUser.config.methodPixStatusDeposit }">
                                    <button type="button" @click="selectTypeDeposit('crypto')"
                                        class="btn mt-1 mr-2 text-sucess btn-outline-theme2 w-75"
                                        :class="selectedDeposit == 'crypto' ? 'btn-outline-theme2-grad' : ''">
                                        <div class="text-center p-2">
                                            <img src="@/assets/img/icon_cript.png" alt="pix" width="30" class="img-fluid">
                                            <span class="px-1 text-light">{{ $t('CRYPTO') }}</span>
                                        </div>
                                    </button>
                                </div> -->
                                <!-- <div v-if="returnUser.config.methodPixStatusDeposit"
                                    :class="{ 'col-6': returnUser.config.methodCPStatusDeposit, 'col-12': !returnUser.config.methodCPStatusDeposit }">
                                    <button type="button" @click="selectTypeDeposit('pix')"
                                        class="btn mt-1 mr-2 text-sucess btn-outline-theme2 w-75"
                                        :class="selectedDeposit == 'pix' ? 'btn-outline-theme2-grad' : ''">
                                        <div class="text-center p-2">
                                            <img src="@/assets/img/icon_pix.png" alt="pix" width="30" class="img-fluid">
                                            <span class="px-1 text-light">PIX</span>
                                        </div>
                                    </button>
                                </div> -->
                            </div>
                            <div class="my-2">
                                <DepositCrypto v-if="selectedDeposit == 'crypto'" />
                                <!-- <DepositPix v-if="selectedDeposit == 'pix'" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
