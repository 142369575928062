export const category=[
{
    title: "category",
    children:[
        {
            name: "Man Shirt",
            id: "edo-ani5"
        },
        {
            name: "Man Jeans",
            id: "edo-ani6"
        },
        {
            name: "Woman Top",
            id: "edo-ani7"
        },
        {
            name: "Women Jeans",
            id: "edo-ani8"
        },
        {
            name: "Man T-Shirt",
            id: "edo-ani9"
        },
        {
            name: "Man's Jacket",
            id: "edo-ani10"
        }
    ],
},
{
 
    title: "Brand",
    children:[
        {
            name: "Levi's",
            id: "edo-ani"
        },
        {
            name: "Diesel",
            id: "edo-ani1"
        },
        {
            name: "Lee",
            id: "edo-ani2"
        },
        {
            name: "Hudson",
            id: "edo-ani3"
        },
        {
            name: "Denizen",
            id: "edo-ani4"
        },
        {
            name: "Spykar",
            id: "edo-ani5"
        },
    ],   
}

]