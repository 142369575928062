<template>
  <div class="header-wrapper row m-0 p-0">
    <SearchBar />
    <Logo />
    <div class="nav-right col-xxl-8 col-xl-6 col-md-8 col-9 pull-right right-header p-2 ms-auto">
      <ul class="nav-menus" style="width: 100%;">
        <li class="language-nav">
          <Language />
        </li>
        <li class="p-0 mt-0 px-1">

          <span data-bs-toggle="tooltip" data-bs-title="Saque">
            <button type="button" class="btn btn-success btn-pers-green px-2" @click="openModalWithdrawRequest()">
              <img src="@/assets/img/withdrawals.png" width="20" alt="" class="d-flex d-md-none">
              <div class="d-none d-md-flex gap-2">
                <img src="@/assets/img/withdrawals.png" width="20" alt="">
                {{ $t("Sacar") }}
              </div>
            </button>
          </span>

        </li>
        <li class="p-0 mt-0 mb-0">

          <span data-bs-toggle="tooltip" data-bs-title="Depósito">
            <button type="button" class="btn btn-info btn-pers px-2" @click="openModalDepositsRequest()">
              <img src="@/assets/img/deposits.png" width="21" alt="" class="d-flex d-md-none">
              <div class="d-none d-md-flex gap-2">
                <img src="@/assets/img/deposits.png" width="21" alt="">
                {{ $t("Depositar") }}
              </div>
            </button>
          </span>

        </li>
        <li class="text-center m-0">
          <div style="width: 120px !important;">
            <!-- <strong class="font-size-18 mb-0">Saldo</strong> -->
            <div class="mt-0">
              <strong class="font-size-15">
                {{
                  returnUser ? moneyFormatUSDT(returnUser.balance) : 'Carregando...'
                }}
              </strong>
            </div>

          </div>

        </li>
        <li>
          <!-- <div class="mode">
            <Mode />
          </div> -->
        </li>
        <!-- <Notifications /> -->

        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import Bookmark from '../bookmark';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Language from './language';
import Notifications from './notifications.vue';
import Mode from './mode';
import Cart from './cart';
import Profile from './profile';
import Logo from './logo.vue';
import SearchBar from './search';
import { Modal } from 'bootstrap';
export default {
  components: {
    Bookmark, Language, Notifications, Mode, Cart, Profile, Logo, Swiper, SwiperSlide, SearchBar,
  },
  data() {
    return {
      bookmark: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
    ...mapGetters(["returnUser", "returnModalWithdrawRequestInstance"])
  },

  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
    openModalWithdrawRequest() {
      if (!this.returnModalWithdrawRequestInstance) {
        let modalInstance = new Modal(document.getElementById('modalWithdrawRequestaaa'), {
          backdrop: 'static',
          keyboard: false,
        });
        modalInstance.show();
        this.$store.dispatch('openModalWithdrawRequest', modalInstance);
      } else {
        this.returnModalWithdrawRequestInstance.show();
      }
    },
    openModalDepositsRequest() {
      let modalInstanceDeposit = new Modal(document.getElementById('modalDeposits'), {
        backdrop: 'static',
        keyboard: false,
      });
      modalInstanceDeposit.show();
    }
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style>
.btn-pers {
  background: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-deafult) 79.68%);
  color: #fff;
}

.btn-pers:hover {
  background: linear-gradient(103.75deg, #0a8fd1 -13.9%, var(--theme-deafult) 79.68%);
  color: #fff;
}

.btn-pers-green {
  background: linear-gradient(103.75deg, #28a745 -13.9%, var(--theme-deafult-green) 79.68%);
  color: #fff;
}

.btn-pers-green:hover {
  background: linear-gradient(103.75deg, #198b34 -13.9%, var(--theme-deafult-green) 79.68%);
  color: #fff;
}
</style>